define("lh4/routes/authenticated/reporting/reports", ["exports", "jquery", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/utils/set-reports-default-location", "lh4/enums/launch-darkly-feature-flags", "lh4/enums/products"], function (_exports, _jquery, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin, _setReportsDefaultLocation, _launchDarklyFeatureFlags, _products) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    queryParams: {
      reportType: {
        replace: true
      },
      selectedLocations: {
        replace: true
      },
      enabledProd: {
        replace: true
      },
      reportPeriodSelected: {
        replace: true
      },
      generate: {
        replace: true
      },
      'dates.startsAt': {
        replace: true
      },
      'dates.startsAtTime': {
        replace: true
      },
      'dates.endsAt': {
        replace: true
      },
      'dates.endsAtTime': {
        replace: true
      }
    },
    requiredPermissions: 'view reports in general',
    tokenData: Ember.inject.service(),
    location: Ember.inject.service(),
    reportsApi: Ember.inject.service('reports/report-api'),
    ajax: Ember.inject.service(),
    intradayPeriod: Ember.inject.service('intraday-period'),
    permissions: Ember.inject.service(),
    can: Ember.inject.service(),
    locationSettings: Ember.inject.service('echo-pro/settings'),
    businessSettings: Ember.inject.service('business-settings'),
    launchDarkly: Ember.inject.service('launch-darkly'),
    redirect: function redirect(model, transition) {
      if (!this.location.isUS && transition.to.queryParams.enabledProd === _products.default.PROCESSING) {
        this.transitionTo('authenticated.reporting.reports');
      }
    },
    model: function model() {
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        locations: this.modelFor('authenticated').locations,
        newSubscription: this.store.createRecord('reports/subscription'),
        preferences: this.store.findRecord('preferences', this.get('tokenData.data.sub')),
        reportTypes: this.store.queryRecord('report', {
          locationIds: [this.location.model.id]
        }),
        finaroProcessingReportTypes: this.reportsApi.fetchFinaroProcessingReportTypes(this.location.model.id),
        intradayPeriodGroups: this.intradayPeriod.getDayPartsGroups(location),
        eodSettings: this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.EOD_RECONCILIATION] ? this.businessSettings.getLocationsGatewayEodTime(this.modelFor('authenticated').locations.reduce(function (acc, loc) {
          if (loc.merchantId !== null) {
            acc.push(loc.id);
          }

          return acc;
        }, [])) : this.locationSettings.getEoDSettings(this.get('permissions.reportLocations')),
        primaryLocation: this.location.model.id,
        reportsStartOfWeek: this.store.queryRecord('echo-pro/setting', {
          location: location,
          type: ['business']
        }).then(function (record) {
          return record.business.get('reportsStartOfWeek');
        }),
        startOfWeekSetting: this.store.queryRecord('echo-pro/setting', {
          location: location,
          type: ['business']
        }).then(function (record) {
          return record.business.get('startOfWeek');
        })
      });
    },
    setupController: function setupController(controller) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [controller].concat(args));

      (0, _setReportsDefaultLocation.default)(controller, this.location.model.id);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('currentReport', null);
      }
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('body').addClass('reports-route');
    },
    deactivate: function deactivate() {
      (0, _jquery.default)('body').removeClass('reports-route');
    },
    actions: {
      willTransition: function willTransition() {
        this.get('currentModel.newSubscription').deleteRecord();
      }
    }
  });

  _exports.default = _default;
});