define("lh4/constants/launch-control/gift-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GIFT_CARDS_STEP_NAMES = _exports.GIFT_CARDS_MODULE_NAME = void 0;
  var GIFT_CARDS_MODULE_NAME = 'mod-gift-cards-v1';
  _exports.GIFT_CARDS_MODULE_NAME = GIFT_CARDS_MODULE_NAME;
  var GIFT_CARDS_STEP_NAMES = Object.freeze(['iframe']);
  _exports.GIFT_CARDS_STEP_NAMES = GIFT_CARDS_STEP_NAMES;
});