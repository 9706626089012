define("lh4/templates/authenticated/launch-control/account-declined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z9VF+Tbk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"s4-account-declined\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"message\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"img\",true],[10,\"class\",\"icon unselectable\"],[10,\"src\",\"/img/launch-control/error.svg\"],[10,\"alt\",\"Account declined\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[7,\"h1\",true],[8],[0,\"Error\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"Your account is currently declined, please contact your sales partner for further questions.\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"phone-number\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"img\",true],[10,\"class\",\"icon unselectable\"],[10,\"src\",\"/img/launch-control/support_agent.svg\"],[10,\"alt\",\"Support agent icon\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"phone\"],[8],[1,[23,0,[\"supportPhoneNumber\"]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/launch-control/account-declined.hbs"
    }
  });

  _exports.default = _default;
});