define("lh4/controllers/authenticated/epro/menu/modifier-sets", ["exports", "lh4/controllers/general-list-controller", "lh4/mixins/list-status-options"], function (_exports, _generalListController, _listStatusOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend(_listStatusOptions.default, {
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    headers: [{
      field: 'name',
      name: 'Name',
      width: 'six',
      sortable: true
    }, {
      field: 'kitchenPrintName',
      name: 'Print name',
      width: 'six',
      sortable: true
    }, {
      field: 'isActive',
      name: 'Status',
      width: 'four',
      sortable: true,
      type: 'active'
    }],
    includeList: [],
    selectionCount: 0,
    init: function init() {
      this._super.apply(this, arguments);

      this.locationChanged();
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      var _this$location, _this$location$model;

      this._super(); // reset all bulk selections when location is changed


      if ((_this$location = this.location) !== null && _this$location !== void 0 && (_this$location$model = _this$location.model) !== null && _this$location$model !== void 0 && _this$location$model.id && this.location.model.id !== this.bulkSelectLocationId) {
        this.setProperties({
          includeList: [],
          selectionCount: 0,
          bulkSelectLocationId: this.location.model.id
        });
      }
    }),
    isMultipleItemsSelected: Ember.computed('selectionCount', function getter() {
      return this.selectionCount > 1;
    }),
    actions: {
      handleTermUpdated: function handleTermUpdated(searchName) {
        this.setProperties({
          searchName: searchName,
          offset: 0
        });
      },
      handleBulkSelectionChanged: function handleBulkSelectionChanged(includeList, selectionCount) {
        this.setProperties({
          includeList: includeList,
          selectionCount: selectionCount
        });
      },
      handleBulkEditClicked: function handleBulkEditClicked() {
        if (this.isMultipleItemsSelected && this.can.can('bulk-edit in echo-pro/menu')) {
          this.transitionToRoute('authenticated.epro.menu.modifier-sets.bulk-edit');
        }
      }
    }
  });

  _exports.default = _default;
});