define("lh4/templates/components/tables/responsive-table/mobile-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hkjvdCR1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"headerName\"]],[28,\"not\",[[24,[\"mobileHeaderHidden\"]]],null]],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"dt\",true],[8],[1,[22,\"headerName\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"cellClass\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"dd\",true],[11,\"class\",[29,[[22,\"cellClass\"]]]],[8],[14,1],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"dd\",true],[8],[14,1],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/tables/responsive-table/mobile-cell.hbs"
    }
  });

  _exports.default = _default;
});