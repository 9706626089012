define("lh4/templates/authenticated/epro/menu/price-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hiqEMblD",
    "block": "{\"symbols\":[\"table\",\"record\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"list-controls\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"corporation\",\"isMenuManagedByCorporate\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui primary button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"create\"]],[8],[0,\"Add Price Group\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"tables/responsive-table\",null,[[\"excludeNew\",\"fixed\",\"headers\",\"limit\",\"offset\",\"onFilterChange\",\"rows\",\"onRowClick\",\"sortAsc\",\"sortBy\",\"totalCount\"],[true,true,[24,[\"headers\"]],[24,[\"limit\"]],[24,[\"offset\"]],[28,\"action\",[[23,0,[]],\"filterChanged\"],null],[24,[\"model\",\"corporateGroups\"]],[28,\"action\",[[23,0,[]],\"onRowClick\"],null],[24,[\"sortAsc\"]],[24,[\"order\"]],[24,[\"model\",\"corporateGroups\",\"meta\",\"count\"]]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"cell\"]],\"expected `table.cell` to be a contextual component but found a string. Did you mean `(component table.cell)`? ('lh4/templates/authenticated/epro/menu/price-groups.hbs' @ L22:C4) \"],null]],[[\"headerName\"],[[24,[\"headers\",\"0\",\"name\"]]]],{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ellipsis-container\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[23,2,[\"name\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/epro/menu/price-groups.hbs"
    }
  });

  _exports.default = _default;
});