define("lh4/components/ui/s4/dates/single-date-picker", ["exports", "lh4/utils/no-op", "lh4/enums/key-code", "lh4/mixins/components/ui/dates/date-picker-mixin", "moment", "lh4/enums/date-time-format"], function (_exports, _noOp, _keyCode, _datePickerMixin, _moment, _dateTimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="s4-single-date-picker-container" data-test-s4-single-date-picker={{@data-test-attribute}}>
  	<div class="main {{if this.isActive 'active'}} {{if @error ' error'}}" onclick={{action 'togglePicker'}} {{action "onFocus"}}>
  		<div class="date-input">
  			<span>{{this.label}}</span>
  			<input
  				type="text"
  				class="date-picker-input search"
  				disabled={{disabled}}
  				onblur={{action "onBlur"}}
  				onfocus={{action "onFocus"}}
  				onkeydown={{action "onKeyDown"}}
  				placeholder={{placeholder}}
  			>
  		</div>
  		<Ui::Icons::S4::Chevron @class="s4-icon-chevron" />
  	</div>
  	{{#if @assistiveText}}
  		<div class="assistive-text {{if @error 'error'}}">{{@assistiveText}}</div>
  	{{/if}}
  </div>
  
  */
  {
    id: "DyZdyQUy",
    block: "{\"symbols\":[\"@error\",\"@assistiveText\",\"@data-test-attribute\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"s4-single-date-picker-container\"],[11,\"data-test-s4-single-date-picker\",[23,3,[]]],[8],[0,\"\\n\\t\"],[7,\"div\",false],[12,\"class\",[29,[\"main \",[28,\"if\",[[23,0,[\"isActive\"]],\"active\"],null],\" \",[28,\"if\",[[23,1,[]],\" error\"],null]]]],[12,\"onclick\",[28,\"action\",[[23,0,[]],\"togglePicker\"],null]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"onFocus\"]],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"date-input\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[8],[1,[23,0,[\"label\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"input\",true],[10,\"class\",\"date-picker-input search\"],[11,\"disabled\",[22,\"disabled\"]],[11,\"onblur\",[28,\"action\",[[23,0,[]],\"onBlur\"],null]],[11,\"onfocus\",[28,\"action\",[[23,0,[]],\"onFocus\"],null]],[11,\"onkeydown\",[28,\"action\",[[23,0,[]],\"onKeyDown\"],null]],[11,\"placeholder\",[22,\"placeholder\"]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[5,\"ui/icons/s4/chevron\",[],[[\"@class\"],[\"s4-icon-chevron\"]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"assistive-text \",[28,\"if\",[[23,1,[]],\"error\"],null]]]],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/dates/single-date-picker.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend(_datePickerMixin.default, {
    classNames: ['s4-single-date-picker', 'ui', 'dropdown', 'selection', 'floating'],
    date: null,
    arrowOn: 'left',
    maxDate: null,
    minDate: null,
    _previousMinDate: null,
    _previousMaxDate: null,
    onafterselect: _noOp.default,
    onBlur: _noOp.default,
    onFocus: _noOp.default,
    picker: null,
    showButtons: true,
    style: true,
    isActive: false,
    label: 'Date',
    disableDays: _noOp.default,
    init: function init() {
      this._super.apply(this, arguments);

      this.convertStringAttributeToMoment('minDate');
      this.convertStringAttributeToMoment('maxDate');
      this.onbeforeshow = this.onPickerBeforeShow.bind(this);
      this.onbeforehide = this.onPickerBeforeHide.bind(this);
    },
    convertStringAttributeToMoment: function convertStringAttributeToMoment(key) {
      var currentValue = this.get(key);

      if (currentValue && (typeof currentValue === 'string' || currentValue instanceof String)) {
        this.set(key, (0, _moment.default)(currentValue));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setupPicker();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var date = this.date,
          picker = this.picker;

      if (!date && picker) {
        picker.clearInput();
      }

      this.updateCalentimConfigValues();
    },
    updateCalentimConfigValues: function updateCalentimConfigValues() {
      var _this = this;

      ['minDate', 'maxDate'].forEach(function (key) {
        var previousKey = "_previous".concat(key.capitalize());

        var currentValue = _this.get(key);

        var previousValue = _this.get(previousKey);

        if (currentValue !== previousValue) {
          _this.picker.config[key] = (0, _moment.default)(currentValue);

          _this.picker.reDrawCalendars();

          _this.set(previousKey, currentValue);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.picker) {
        this.picker.destroy();
      }
    },
    getOptions: function getOptions() {
      var options = this.getProperties('arrowOn', 'maxDate', 'minDate', 'style', 'onbeforeshow', 'onbeforehide', 'showButtons', 'onafterselect', 'disableDays');
      return _objectSpread(_objectSpread({}, options), {}, {
        calendarCount: 1,
        enableKeyboard: false,
        format: _dateTimeFormat.default.LOCALIZED_DATE_ONLY,
        oneCalendarWidth: 312,
        showFooter: false,
        showHeader: false,
        singleDate: true,
        startEmpty: !this.date,
        showonMonday: true,
        showTimePickers: false
      });
    },
    setupPicker: function setupPicker() {
      this.$('.date-picker-input').calentim(this.getOptions());
      var instance = this.$('.date-picker-input').data('calentim');
      instance.setStart(this.date ? (0, _moment.default)(this.date, _moment.default.ISO_8601) : null);
      instance.setEnd(this.date ? (0, _moment.default)(this.date, _moment.default.ISO_8601) : null);
      this.set('picker', instance);
    },
    _showPicker: function _showPicker(shouldShow) {
      if (shouldShow) {
        this.picker.showDropdown();
      } else {
        this.picker.hideDropdown({});
      }
    },
    onPickerBeforeHide: function onPickerBeforeHide() {
      this.set('isActive', false);
    },
    onPickerBeforeShow: function onPickerBeforeShow() {
      this.set('isActive', true);
    },
    actions: {
      onFocus: function onFocus() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var event = args[0];
        var element = this.picker.elem.parentElement.parentElement.children[1];

        if (element.classList.contains('up')) {
          this.set('isActive', false);
          element.classList.replace('up', 'down');
          this.picker.hideDropdown({});
        } else {
          this.set('isActive', true);
          element.classList.replace('down', 'up');
          Array.from(document.getElementsByClassName('s4cal-container')).forEach(function (item) {
            item.classList.add('s4-date-picker');
          });
          this.picker.showDropdown(event);

          if (this.onFocus) {
            this.onFocus.apply(this, args);
          }
        }
      },
      onKeyDown: function onKeyDown(event) {
        if (event.keyCode === _keyCode.default.ESC) {
          this.picker.hideDropdown(event);
        }
      },
      onBlur: function onBlur(event) {
        var _this2 = this;

        Ember.run.later(function () {
          if (_this2.onBlur) {
            _this2.onBlur(event, _this2.picker);
          }
        }, 100);
      },
      togglePicker: function togglePicker(event) {
        this._showPicker(!this.isActive || event.target === this.inputElement);
      }
    }
  }));

  _exports.default = _default;
});