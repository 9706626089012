define("lh4/controllers/authenticated/epro/menu/items/create", ["exports", "lh4/controllers/general-form-controller", "jquery", "lh4/mixins/controllers/authenticated/epro/menu/stylesheet-saving"], function (_exports, _generalFormController, _jquery, _stylesheetSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend(_stylesheetSaving.default, {
    metrics: Ember.inject.service(),
    locale: Ember.inject.service(),
    location: Ember.inject.service(),
    fullBlob: null,
    thumbBlob: null,
    ajax: Ember.inject.service(),
    validateAndSaveChangeset: function validateAndSaveChangeset(changesets, key) {
      var _this = this;

      return changesets.map(function (record) {
        return record.validate().then(function () {
          if (!record.isValid) {
            return Ember.RSVP.reject(record.get('errors'));
          }

          record.set('item', _this.model.item); // record.save was throwing because models don't have a save endpoint

          record.execute();
          record.rollback();

          _this.model.item.get(key).pushObject(record.data);

          return true;
        }).catch(function () {
          _this.set('areChildrenInvalid', true);

          return false;
        });
      });
    },
    removeRecordsFromModel: function removeRecordsFromModel(model, changeset) {
      model.filter(function (_ref) {
        var id = _ref.id;
        return !changeset.findBy('id', id);
      }).forEach(function (record) {
        return model.removeObject(record);
      });
    },
    saveImages: function saveImages(_ref2) {
      var itemId = _ref2.itemId;
      var formData = new FormData();
      formData.append('full', this.fullBlob, "".concat(itemId, "-full.png"));
      formData.append('thumb', this.thumbBlob, "".concat(itemId, "-thumb.png"));
      return this.ajax.request("/api/v1/echo-pro/items/".concat(itemId, "/image"), {
        method: 'POST',
        data: formData,
        contentType: false,
        processData: false
      }).catch(function (error) {
        var errorMessage = 'Something went wrong, image could not be saved';

        if (error.payload && error.payload.error && error.payload.error.message) {
          errorMessage = "".concat(errorMessage, ": ").concat(error.payload.error.message);
        }

        return Ember.RSVP.reject(errorMessage);
      });
    },
    // Remove maps that have no priceValue set - that's the only property that can indicate us
    // that this is being deleted
    removeDeletedPriceScheduleMaps: function removeDeletedPriceScheduleMaps() {
      this.changeset.priceScheduleMapChangesets = this.changeset.priceScheduleMapChangesets.reduce(function (result, option) {
        if (option.get('priceValue')) {
          result.push(option);
        }

        return result;
      }, []);
    },
    // To handle both old "Pricing Schedules" and new "Dynamic Pricing"
    savePricing: function savePricing() {
      var _this2 = this;

      // Begin, old "Pricing Schedules" functionality
      this.removeDeletedPriceScheduleMaps(); // Logic to warn user that with Flat Discount in price schedules item can be prized at 0 (or lower)

      var priceScheduleZeroRecords = this.changeset.priceScheduleMapChangesets.filter(function (record) {
        return record.get('priceType') === 'Flat Discount';
      }).filter(function (record) {
        var priceValue = parseFloat(record.get('priceValue'));
        return priceValue >= parseFloat(_this2.changeset.get('price'));
      });

      if (priceScheduleZeroRecords.length > 0) {
        var confirmed = window.confirm("One of the prices affected by Scheduled Pricing rules will result to ".concat(this.locale.currencySymbol, "0. Would you like to proceed?"));

        if (!confirmed) {
          this.set('isSaving', false);
          return Promise.reject(new Error('Please confirm'));
        }
      }

      var itemPriceScheduleMapPromises = this.validateAndSaveChangeset(this.changeset.priceScheduleMapChangesets, 'itemPriceScheduleMaps'); // End, old "Pricing Schedules" functionality
      // Begin, new "Dynamic Pricing" functionality

      if (this.changeset.dynamicPricingChangesets.some(function (c) {
        return c.get('hasZeroPrice') === true;
      })) {
        var _confirmed = window.confirm("The item price when affected by Dynamic Pricing rules will result to ".concat(this.locale.currencySymbol, "0. Would you like to proceed?"));

        if (!_confirmed) {
          this.set('isSaving', false);
          return Promise.reject(new Error('Please confirm'));
        }
      }

      var dynamicPricingChangesets = this.validateAndSaveChangeset(this.changeset.dynamicPricingChangesets, 'dynamicPricing'); // End, new "Dynamic Pricing" functionality

      return Ember.RSVP.all([itemPriceScheduleMapPromises, dynamicPricingChangesets]);
    },
    // Waits till modal closes and setups new item form with predefined values
    duplicateItem: function duplicateItem(itemId) {
      var _this3 = this;

      Ember.run.later(this, function () {
        _this3.transitionToRoute('authenticated.epro.menu.items.create', {
          queryParams: {
            clone: itemId
          }
        });

        (0, _jquery.default)('.fullscreen-modal > .content').animate({
          scrollTop: 0
        }, 'fast');
      }, 2500);
    },
    trackUpdateCompleted: function trackUpdateCompleted() {
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Completed',
        'Entry Type': 'New',
        'Entry ID': this.changeset.get('id'),
        'Number of Updates': Object.keys(this.changeset.change).length,
        'Entries Updated': Object.keys(this.changeset.change),
        'Menu Component': 'Item'
      });
    },
    actions: {
      setImageBlobs: function setImageBlobs(_ref3) {
        var fullBlob = _ref3.fullBlob,
            thumbBlob = _ref3.thumbBlob;

        if (!(fullBlob instanceof Blob) || !(thumbBlob instanceof Blob)) {
          throw new Error('fullBlob & thumbBlob must be of type Blob');
        } // ensure change will be detected and discard dialog will be shown
        // value doesn't matter as this will be updated after image upload


        this.changeset.set('imageUrl', 'any');
        this.setProperties({
          fullBlob: fullBlob,
          thumbBlob: thumbBlob
        });
      },
      create: function create() {
        var _this4 = this;

        var cloning = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError(); // we have to check for pristine before we validate and save

        var isPristine = this.isModelAndChangesetPristine() && this.areChildrenPristine(this.model.item.itemPriceScheduleMaps, this.changeset.priceScheduleMapChangesets);
        this.createStposUiStylesheetObjectLink({
          locationId: this.changeset.get('locationId'),
          isActive: true
        });
        this.savePricing().then(function () {
          _this4.changeset.validate().then(function () {
            if (!_this4.changeset.get('isValid') || _this4.areChildrenInvalid) {
              return Ember.RSVP.reject(_this4.changeset.get('errors'));
            }

            _this4.trackUpdateCompleted();

            if (isPristine) {
              _this4.closeTray();

              return Ember.RSVP.resolve();
            }

            _this4.removeRecordsFromModel(_this4.model.item.itemPriceScheduleMaps, _this4.changeset.priceScheduleMapChangesets);

            return _this4.changeset.save().then(function (data) {
              if (!(_this4.fullBlob && _this4.thumbBlob)) {
                if (cloning) {
                  _this4.duplicateItem(data.id);
                } else {
                  _this4.closeModal();
                }
              }

              var itemId = data.id;
              return _this4.saveImages({
                itemId: itemId
              }).then(function () {
                if (cloning) {
                  _this4.duplicateItem(data.id);
                } else {
                  _this4.closeModal();
                }
              }).catch(function (error) {
                // TODO normalize how we handle these error messages
                var createError = 'Something went wrong, image could not be saved';
                var errorMessage = error || createError;

                _this4.set('serverError', errorMessage);

                _this4.set('serverErrorTimeoutId', Ember.run.later(_this4, function () {
                  _this4.set('serverError', null);
                }, _this4.serverErrorTimeoutDelay));
              });
            }).catch(function (_ref4) {
              var errors = _ref4.errors;

              if (!(errors && errors[0])) {
                return;
              } // TODO normalize how we handle these error messages: LH-8461


              var createError = "Something went wrong, ".concat(_this4.instanceName, " could not be created.");
              errors.forEach(function (error) {
                var errorMessage = error.details && error.details[0] || error.message || createError;

                _this4.set('serverError', errorMessage);

                _this4.set('serverErrorTimeoutId', Ember.run.later(_this4, function () {
                  _this4.set('serverError', null);
                }, _this4.serverErrorTimeoutDelay));
              });
            });
          }).catch(function (errors) {
            // We currently don't have a field component for checkboxes
            // Because of this there is no standard way of displaying
            // error messages for boolean fields
            // https://jira.shift4payments.com/browse/LH-8853
            if (errors.length) {
              var booleanError = errors.find(function (error) {
                return typeof error.value === 'boolean';
              });

              if (booleanError) {
                _this4.set('booleanError', booleanError.validation);

                _this4.set('serverErrorTimeoutId', Ember.run.later(_this4, function () {
                  _this4.set('booleanError', null);
                }, _this4.serverErrorTimeoutDelay));
              }
            }
          }).finally(function () {
            Ember.run.later(_this4, function () {
              _this4.set('isSaving', false);
            }, 2000);
          });
        });
      },
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      },
      selectedPriceGroupChanged: function selectedPriceGroupChanged(data) {
        return this.selectedPriceGroupChanged(data);
      },
      createAndDuplicate: function createAndDuplicate() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.send.apply(this, ['create', true].concat(args));
      }
    }
  });

  _exports.default = _default;
});