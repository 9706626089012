define("lh4/helpers/render-pricing-schedules", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.renderPricingSchedules = renderPricingSchedules;
  _exports.default = void 0;
  var AMOUNT_OF_SCHEDULES_TO_SHOW = 2;
  var MAX_SCHEDULE_NAME_LENGTH = 30;
  /**
   * Returns a formatted hour string from milliseconds.
   *
   * @param {number} milliseconds value
   * @returns {string} formatted hours
   */

  function getHoursString(milliseconds) {
    return _moment.default.utc(milliseconds).format('hh:mm A');
  }
  /**
   * Returns a string listing the ranges of schedule's active days of week.
   *
   * Under the hood:
   * The function iterates through a collection of all days and records labels of
   * all active consecutive and singular days in arrays as ranges.
   * During the output the ranges are joined into single strings and concatenated.
   *
   * Example 1:
   * - Active days: Mon, Tue, Wed, Fri, Sun
   * - Expected output: "Mon - Wed, Fri, Sun"
   *
   * Example 2:
   * - Active days: Tue, Wed, Fri, Sat
   * - Expected output: "Tue - Wed, Fri - Sat"
   *
   * Example 3:
   * - Active days: Tue, Sat
   * - Expected output: "Tue, Sat"
   *
   * Example 4:
   * - Active days: Mon, Tue, Wed, Thu, Fri, Sat, Sun
   * - Expected output: "Every Day"
   *
   * @param {object} schedule pricing schedule object
   * @returns {string} string of active day ranges
   */


  function getDaysOfWeekRangeString(schedule) {
    var weekdayOptions = [{
      key: 'isOnMonday',
      label: 'Mon'
    }, {
      key: 'isOnTuesday',
      label: 'Tue'
    }, {
      key: 'isOnWednesday',
      label: 'Wed'
    }, {
      key: 'isOnThursday',
      label: 'Thu'
    }, {
      key: 'isOnFriday',
      label: 'Fri'
    }, {
      key: 'isOnSaturday',
      label: 'Sat'
    }, {
      key: 'isOnSunday',
      label: 'Sun'
    }];
    var ranges = weekdayOptions.reduce(function (accumulator, currentDay, index) {
      var isCurrentDayActive = schedule[currentDay.key];
      var previousDay = weekdayOptions[index - 1];
      var isPreviousDayActive = previousDay ? schedule[previousDay.key] : false;

      if (!isCurrentDayActive) {
        return accumulator;
      }

      if (isPreviousDayActive) {
        accumulator[accumulator.length - 1].push(currentDay);
      } else {
        accumulator.push([currentDay]);
      }

      return accumulator;
    }, []).map(function (range) {
      if (!range.length) {
        return '';
      }

      if (range.length === 7) {
        return 'Every Day';
      }

      var firstDayLabel = range[0].label;

      if (range.length > 1) {
        var lastDayLabel = range[range.length - 1].label;
        return "".concat(firstDayLabel, " - ").concat(lastDayLabel);
      }

      return firstDayLabel;
    });
    return ranges.join(', ');
  }
  /**
   * Returns a list of up to 2 pricing schedules in a following format:
   * "[[Schedule Name]]: [[Active Days Range]] [[Time Range]]"
   *
   * Example output: "Schedule 1: Mon - Fri, Sun 10:00 AM - 12:00 PM"
   *
   * @param {Array} params helper params
   * @param {String}  includeScheduleName - whether to include schedule name into output string
   * @returns {SafeString} formatted schedule list string
   */


  function renderPricingSchedules(params) {
    var _params$;

    if (!params[0] || !params[0].length) {
      return '';
    }

    var pricingSchedules = params[0].sortBy('createdAt').toArray();
    var includeScheduleName = (_params$ = params[1]) !== null && _params$ !== void 0 ? _params$ : true;
    return Ember.String.htmlSafe(pricingSchedules.slice(0, AMOUNT_OF_SCHEDULES_TO_SHOW).map(function (schedule, index) {
      var scheduleName = '';

      if (includeScheduleName && schedule.name) {
        scheduleName = schedule.name.length > MAX_SCHEDULE_NAME_LENGTH ? "".concat(schedule.name.slice(0, MAX_SCHEDULE_NAME_LENGTH), "...: ") : "".concat(schedule.name, ": ");
      } // Prepare a time range for render


      var daysString = getDaysOfWeekRangeString(schedule);
      var hoursString = "".concat(getHoursString(schedule.startsAtSeconds), " - ").concat(getHoursString(schedule.endsAtSeconds)); // Indicate concatenation when there are more than 2 (by default) pricing schedules

      var ellipsisString = pricingSchedules.length > AMOUNT_OF_SCHEDULES_TO_SHOW && index === AMOUNT_OF_SCHEDULES_TO_SHOW - 1 ? '...' : '';
      return Ember.Handlebars.Utils.escapeExpression("".concat(scheduleName).concat(daysString, " ").concat(hoursString).concat(ellipsisString));
    }).join('<br/>'));
  }

  var _default = Ember.Helper.helper(renderPricingSchedules);

  _exports.default = _default;
});