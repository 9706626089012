define("lh4/models/echo-pro/job", ["exports", "ember-data", "ember-data/relationships", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/boolean-property-array", "lh4/utils/has-many-ids"], function (_exports, _emberData, _relationships, _emberCpValidations, _modelCloneable, _booleanPropertyArray, _hasManyIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    description: {
      description: 'Job Description',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    name: {
      description: 'Job Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    },
    payRate: (0, _emberCpValidations.validator)('number', {
      description: 'Pay Rate',
      allowString: true,
      positive: true,
      message: 'Pay Rate cannot be a negative number or empty',
      allowNone: false
    }),
    ticketPaymentOperationType: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Operates As'
    }),
    code: {
      description: 'Job Code',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 100
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^[A-Z0-9]*$/,
        message: 'Incorrect Job Code format, should be alphanumeric & uppercase'
      })],
      allowBlank: true
    },
    defaultMenuType: {
      description: 'Default Menu Type',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })],
      allowNone: false
    }
  });
  var SETTING_ATTRIBUTES = {
    isTrackEnabled: _emberData.default.attr('boolean'),
    isHostModeEnabled: _emberData.default.attr('boolean'),
    canAccessRecentlyClosedTicket: _emberData.default.attr('boolean'),
    isAccessAllTicketsEnabled: _emberData.default.attr('boolean'),
    isFastPayEnabled: _emberData.default.attr('boolean'),
    isGridViewEnabled: _emberData.default.attr('boolean'),
    shouldAutoPrintDeliveryInformationOnTransfer: _emberData.default.attr('boolean'),
    isBlind: _emberData.default.attr('boolean'),
    isDeclareTipsAtClockoutEnabled: _emberData.default.attr('boolean'),
    isHoldItemsEnabled: _emberData.default.attr('boolean'),
    isIgnoreRequiredModifiersEnabled: _emberData.default.attr('boolean'),
    canSetHouseAccountThreshold: _emberData.default.attr('boolean'),
    isTransferTicketOnScanEnabled: _emberData.default.attr('boolean'),
    usesPersonalTill: _emberData.default.attr('boolean'),
    isDisplayShiftReportEnabled: _emberData.default.attr('boolean'),
    canViewHiddenDepartments: _emberData.default.attr('boolean'),
    isManualWeightEntryEnabled: _emberData.default.attr('boolean')
  };

  var _default = _emberData.default.Model.extend(Validations, SETTING_ATTRIBUTES, _modelCloneable.default, {
    name: _emberData.default.attr('string'),
    locationId: _emberData.default.attr('number'),
    guid: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    payRate: _emberData.default.attr('string', {
      defaultValue: '0.00'
    }),
    // TODO: remove 'overtimePayRate' after old timeclock management UI deprecation
    overtimePayRate: _emberData.default.attr('number', {
      defaultValue: '0.00'
    }),
    loginType: _emberData.default.attr('number'),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    ticketPaymentOperationType: _emberData.default.attr('number'),
    defaultOrderTypeGuid: _emberData.default.attr('string'),
    employee: (0, _relationships.hasMany)('echo-pro/employee'),
    printers: (0, _relationships.hasMany)('echo-pro/printer'),
    printerIds: Ember.computed('printers', (0, _hasManyIds.default)('printers', 'echo-pro/printer')),
    settings: (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(Object.keys(SETTING_ATTRIBUTES)).concat([(0, _booleanPropertyArray.default)(Object.keys(SETTING_ATTRIBUTES))])),
    code: _emberData.default.attr('string'),
    defaultMenuType: _emberData.default.attr('string'),
    isTipped: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});