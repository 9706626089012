define("lh4/controllers/authenticated/locations/location/echo-pro/reservations/calendar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // second dependent property forces events to trigger when reservation is dragged
    events: Ember.computed('model.reservation.[]', 'model.reservation.@each.partyTime', function getter() {
      var _this = this;

      var _events = [];
      this.get('model.reservation').forEach(function (res) {
        if (!res.get('isNew') && res.get('locationId') === parseInt(_this.get('model.location.id'), 10)) {
          _events.push({
            id: res.get('id'),
            editable: true,
            className: 'cal-event',
            color: '#00C0F4',
            title: "".concat(res.get('customerFullName'), " - Party of ").concat(res.get('partySize')),
            start: (0, _moment.default)(res.get('partyTime'))
          });
        }
      });
      return _events;
    }),
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    years: Ember.computed(function () {
      var prevYear = (0, _moment.default)().year() - 1;
      return Array.from({
        length: 50
      }, function (v, i) {
        return i + prevYear;
      });
    }),
    queryParams: ['month', 'year'],
    month: (0, _moment.default)().format('MMMM'),
    year: (0, _moment.default)().year(),
    timezone: Ember.computed(function () {
      return _moment.default.defaultZone ? _moment.default.defaultZone.name : _moment.default.tz.guess();
    }),
    date: Ember.computed('month', 'year', function getter() {
      return (0, _moment.default)(this.get('month'), 'MMMM').year(this.get('year'));
    }),
    actions: {
      clicked: function clicked(event) {
        this.transitionToRoute('authenticated.locations.location.echo-pro.reservations.reservation.edit', event.id, {
          queryParams: {
            startDate: event.start.format('YYYY-MM-DD'),
            endDate: (0, _moment.default)(event.start).add(1, 'd').format('YYYY-MM-DD')
          }
        });
      },
      yearChanged: function yearChanged(selected) {
        var _this2 = this;

        Ember.run.later(function () {
          _this2.set('year', selected);
        }, 100);
      },
      monthChanged: function monthChanged(selected) {
        var _this3 = this;

        Ember.run.later(function () {
          _this3.set('month', selected);
        }, 100);
      }
    }
  });

  _exports.default = _default;
});