define("lh4/models/echo-pro/setting", ["exports", "ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _model.default.extend(Validations, {
    accounting: (0, _relationships.belongsTo)('echo-pro/setting/accounting'),
    business: (0, _relationships.belongsTo)('echo-pro/setting/business'),
    customer: (0, _relationships.belongsTo)('echo-pro/setting/customer'),
    receipt: (0, _relationships.belongsTo)('echo-pro/setting/receipt'),
    localization: (0, _relationships.belongsTo)('echo-pro/setting/localization'),
    mobileWallet: (0, _relationships.belongsTo)('echo-pro/setting/mobileWallet'),
    managedMenus: (0, _relationships.belongsTo)('echo-pro/setting/managedMenus'),
    orderReadyScreen: (0, _relationships.belongsTo)('echo-pro/setting/orderReadyScreen')
  });

  _exports.default = _default;
});