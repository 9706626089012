define("lh4/controllers/authenticated/epro/menu/dynamic-pricing", ["exports", "lh4/controllers/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend({
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    order: 'isActive',
    sort: 'desc',
    headers: [{
      field: 'scheduleTitle',
      name: 'Group Name',
      width: 'four',
      sortable: true
    }, {
      field: 'revenueCenters',
      name: 'Revenue Centers',
      width: 'five',
      sortable: false
    }, {
      field: 'timeRanges',
      name: '',
      width: 'five',
      sortable: false
    }, {
      field: 'isActive',
      name: 'Status',
      width: 'two',
      sortable: true,
      type: 'active'
    }],
    init: function init() {
      this._super.apply(this, arguments);

      this.locationChanged();
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      var _this$location, _this$location$model;

      this._super((_this$location = this.location) === null || _this$location === void 0 ? void 0 : (_this$location$model = _this$location.model) === null || _this$location$model === void 0 ? void 0 : _this$location$model.id);
    }),
    dynamicPricesList: Ember.computed('model.dynamicPrices', 'model.revenueCenterOptions', function getter() {
      var _this = this;

      var getRevenueCenterName = function getRevenueCenterName(guid) {
        var revCenter = _this.model.revenueCenterOptions.find(function (_ref) {
          var value = _ref.value;
          return value === guid;
        });

        return revCenter ? revCenter.name : 'Unknown';
      };

      return this.model.dynamicPrices.map(function (dynamicPrice) {
        var revenueCenterNameList = (dynamicPrice.revenueCenterGuids || []).length === 0 ? ['All Revenue Centers'] : dynamicPrice.revenueCenterGuids.map(getRevenueCenterName);
        return {
          id: dynamicPrice.guid,
          scheduleTitle: dynamicPrice.scheduleTitle,
          isActive: dynamicPrice.isActive,
          revenueCenterNames: revenueCenterNameList.join(', ')
        };
      });
    })
  });

  _exports.default = _default;
});