define("lh4/templates/components/ui/navigation/vertical-navigation-categories/fundraising", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P+J6PtPK",
    "block": "{\"symbols\":[\"@notificationList\",\"@stJudeLink\",\"@navOpenHover\",\"@navCloseHover\",\"@navOpenClick\",\"@isFundraisingActive\",\"@navCloseClick\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"sidebar-content has-sub-nav\"],[3,\"on\",[\"mouseenter\",[23,3,[]]]],[3,\"on\",[\"mouseleave\",[23,4,[]]]],[3,\"on\",[\"click\",[23,5,[]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"row \",[28,\"if\",[[23,6,[]],\"active\"],null]]]],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"icon\"],[8],[1,[22,\"ui/icons/sidebar/flare\"],false],[9],[0,\"\\n\\t\\t\"],[1,[28,\"ui/navigation/nav-item\",null,[[\"name\",\"notificationList\"],[\"Fundraising\",[23,1,[]]]]],false],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"chevron right icon\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"sub-nav\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sub-content\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",\"sub-title\"],[3,\"on\",[\"click\",[23,7,[]]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"chevron left icon\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"span\",true],[8],[0,\"Fundraising\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sub-links\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"can\",[\"manage in st-jude\"],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"item\",[23,2,[]]]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"nav-tab\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[1,[28,\"ui/navigation/nav-item\",null,[[\"name\",\"notificationList\"],[\"St. Jude Fundraising\",[23,1,[]]]]],false],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/navigation/vertical-navigation-categories/fundraising.hbs"
    }
  });

  _exports.default = _default;
});