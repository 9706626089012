define("lh4/templates/components/ui/modal/dialog-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5+J3zb7z",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"otherwise\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",false],[12,\"class\",[29,[\"ui \",[28,\"if\",[[24,[\"isOtherwiseLoading\"]],\"loading\"],null],\" left floated negative basic \",[28,\"if\",[[24,[\"isLoading\"]],\"disabled\"],null],\" button otherwise\"]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[24,[\"otherwise\"]]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"otherwiseText\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[14,1],[0,\"\\n\\t\"],[7,\"div\",false],[12,\"class\",[29,[\"ui deny \",[28,\"if\",[[24,[\"isOtherwiseLoading\"]],\"disabled\"],null],\" button\"]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[24,[\"decline\"]]]],[8],[0,\"\\n\\t\\t\"],[1,[22,\"declineText\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",false],[12,\"class\",[29,[\"ui primary \",[28,\"if\",[[24,[\"isLoading\"]],\"loading\",\"\"],null],\" \",[28,\"if\",[[24,[\"isOtherwiseLoading\"]],\"disabled\"],null],\" button\"]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[24,[\"accept\"]]]],[8],[0,\"\\n\\t\\t\"],[1,[22,\"acceptText\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/modal/dialog-buttons.hbs"
    }
  });

  _exports.default = _default;
});