define("lh4/routes/authenticated/updates", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, {
    titleToken: 'Updates',
    location: Ember.inject.service(),
    queryParams: {
      searchName: {
        refreshModel: true
      },
      selectedStatus: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      if (!this.location.model.isCorpHq) {
        this.transitionTo('authenticated.dashboard2');
      }
    },
    model: function model(_ref) {
      var limit = _ref.limit,
          offset = _ref.offset,
          searchName = _ref.searchName,
          selectedStatus = _ref.selectedStatus;
      var corporation = this.location.model.corpId;
      var updateParams = {
        name: searchName,
        status: selectedStatus && selectedStatus !== 'all' ? selectedStatus : undefined
      };
      var updates = this.store.query('echo-pro/menu-version', _objectSpread(_objectSpread({}, updateParams), {}, {
        corporation: corporation,
        limit: limit,
        offset: offset
      }));
      return Ember.RSVP.hash({
        updates: updates
      });
    }
  });

  _exports.default = _default;
});