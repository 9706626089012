define("lh4/templates/components/credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ne8qHLIA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"credit-card \",[23,0,[\"cardClass\"]]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"credit-card-inner-wrapper\"],[8],[0,\"\\n\"],[4,\"unless\",[[23,0,[\"hideHeader\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"credit-card-text\"],[8],[0,\"Last 4 digits\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"card-number\"],[8],[0,\"*\"],[1,[23,0,[\"args\",\"cardNumber\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/credit-card.hbs"
    }
  });

  _exports.default = _default;
});