define("lh4/serializers/echo-pro/setting", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /* eslint-disable camelcase */
  var _default = _application.default.extend({
    serialize: function serialize(snapshot) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      var json = this._super.apply(this, [snapshot].concat(args));

      json.locationId = snapshot.id;
      json.accounting = this.decamelize(json.accounting);
      json.business = this.decamelize(json.business);
      json.customer = this.decamelize(json.customer);
      json.managed_menus = this.decamelize(json.managed_menus);
      json.receipt = this.decamelize(json.receipt);
      delete json.localization; // readonly, we do not change localization settings

      delete json.mobile_wallet; // readonly, we do not change mobile_wallet settings

      json.accounting.breaks_paid = json.accounting.breaks_paid === 'Always Paid' ? 'always' : 'never';
      json.accounting.auto_clock_out = json.accounting.auto_clock_out === 'Yes' ? '1' : '0';
      return {
        settings: json
      };
    },
    decamelize: function decamelize() {
      var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var keys = {};

      if (obj) {
        Object.keys(obj).forEach(function (attr) {
          keys[attr.replace(/[A-Z 0-9]/g, function (match, offset) {
            return (offset > 0 ? '_' : '') + match.toLowerCase();
          })] = obj[attr];
        });
      }

      return keys;
    },
    camelizeKeys: function camelizeKeys() {
      var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var keys = {};

      if (obj) {
        Object.keys(obj).forEach(function (attr) {
          keys[attr.camelize()] = obj[attr];
        });
      }

      return keys;
    },
    stringToBoolean: function stringToBoolean() {
      var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return obj ? Object.entries(obj).reduce(function (res, _ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        return _objectSpread(_objectSpread({}, res), {}, _defineProperty({}, key, value === '1'));
      }, {}) : {};
    },
    createNormalizedResponse: function createNormalizedResponse(id) {
      return {
        data: {
          id: id,
          type: 'echo-pro/setting',
          relationships: {
            accounting: {
              data: {
                id: id,
                type: 'echo-pro/setting/accounting'
              }
            },
            business: {
              data: {
                id: id,
                type: 'echo-pro/setting/business'
              }
            },
            customer: {
              data: {
                id: id,
                type: 'echo-pro/setting/customer'
              }
            },
            managedMenus: {
              data: {
                id: id,
                type: 'echo-pro/setting/managedMenus'
              }
            },
            receipt: {
              data: {
                id: id,
                type: 'echo-pro/setting/receipt'
              }
            },
            localization: {
              data: {
                id: id,
                type: 'echo-pro/setting/localization'
              }
            },
            mobileWallet: {
              data: {
                id: id,
                type: 'echo-pro/setting/mobileWallet'
              }
            },
            orderReadyScreen: {
              data: {
                id: id,
                type: 'echo-pro/setting/orderReadyScreen'
              }
            }
          }
        }
      };
    },
    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, primaryModelClass, payload, id) {
      return this.createNormalizedResponse(id);
    },
    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload) {
      var id = payload.settings.locationId || 0; // prevents error if nothing is returned from API

      var accounting = this.camelizeKeys(payload.settings.accounting);
      accounting.breaksPaid = accounting.breaksPaid === 'always' ? 'Always Paid' : 'Never Paid';
      accounting.autoClockOut = accounting.autoClockOut === '1' ? 'Yes' : 'No';
      accounting.minimumWage = Number.parseFloat(accounting.minimumWage).toFixed(2);
      var managedMenus = this.camelizeKeys(payload.settings.managed_menus);
      managedMenus = this.stringToBoolean(managedMenus);
      var orderReadyScreen = this.camelizeKeys(payload.settings.order_ready_screen);
      orderReadyScreen.enabled = orderReadyScreen.enabled === '1';
      orderReadyScreen.color = orderReadyScreen.backgroundColor;
      orderReadyScreen.displayModeAuto = orderReadyScreen.displayMode === 'auto';
      orderReadyScreen.displayModeManual = orderReadyScreen.displayMode === 'manual';
      this.store.push({
        data: {
          id: id,
          type: 'echo-pro/setting/accounting',
          attributes: accounting
        }
      });
      this.store.push({
        data: {
          id: id,
          type: 'echo-pro/setting/business',
          attributes: this.camelizeKeys(payload.settings.business)
        }
      });
      this.store.push({
        data: {
          id: id,
          type: 'echo-pro/setting/customer',
          attributes: this.camelizeKeys(payload.settings.customer)
        }
      });
      this.store.push({
        data: {
          id: id,
          type: 'echo-pro/setting/managedMenus',
          attributes: managedMenus
        }
      });
      this.store.push({
        data: {
          id: id,
          type: 'echo-pro/setting/receipt',
          attributes: this.camelizeKeys(payload.settings.receipt)
        }
      });
      this.store.push({
        data: {
          id: id,
          type: 'echo-pro/setting/localization',
          attributes: this.camelizeKeys(payload.settings.localization)
        }
      });
      this.store.push({
        data: {
          id: id,
          type: 'echo-pro/setting/mobileWallet',
          attributes: this.camelizeKeys(payload.settings.mobile_wallet)
        }
      });
      this.store.push({
        data: {
          id: id,
          type: 'echo-pro/setting/orderReadyScreen',
          attributes: orderReadyScreen
        }
      });
      return this.createNormalizedResponse(id);
    },
    attrs: {
      accounting: {
        embedded: 'always'
      },
      business: {
        embedded: 'always'
      },
      customer: {
        embedded: 'always'
      },
      managedMenus: {
        embedded: 'always'
      },
      receipt: {
        embedded: 'always'
      },
      localization: {
        embedded: 'always'
      },
      mobile_wallet: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});