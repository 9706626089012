define("lh4/routes/authenticated/locations/location/echo-pro/manage-pos/timeclock/edit-shift", ["exports", "lh4/mixins/authenticated-route-mixin", "moment"], function (_exports, _authenticatedRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return {
        selectedShift: this.store.peekRecord('echo-pro/time-clock-shift', params.shiftId)
      };
    },
    beforeModel: function beforeModel() {
      var location = this.modelFor('authenticated.locations.location');

      if (location.timezone) {
        _moment.default.tz.setDefault(location.timezone);
      } else {
        _moment.default.tz.setDefault();
      }
    },
    afterModel: function afterModel(model) {
      this.controllerFor('authenticated.locations.location.echo-pro.manage-pos.timeclock').set('selectedShift', model.selectedShift);
      var _model$selectedShift = model.selectedShift,
          standardWorkTimeTotalSeconds = _model$selectedShift.standardWorkTimeTotalSeconds,
          standardOvertimeTotalSeconds = _model$selectedShift.standardOvertimeTotalSeconds,
          doubleOvertimeTotalSeconds = _model$selectedShift.doubleOvertimeTotalSeconds;
      this.controllerFor('authenticated.locations.location.echo-pro.manage-pos.timeclock').set('workTimeSnapshot', {
        standardWorkTimeTotalSeconds: standardWorkTimeTotalSeconds,
        standardOvertimeTotalSeconds: standardOvertimeTotalSeconds,
        doubleOvertimeTotalSeconds: doubleOvertimeTotalSeconds
      });
    }
  });

  _exports.default = _default;
});