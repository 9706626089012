define("lh4/constants/launch-control/reservations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RESERVATIONS_MODULE_NAME = void 0;
  var RESERVATIONS_STEP_NAME = Object.freeze({
    BLOCKS: 'blocks',
    SETTINGS: 'settings'
  });
  var RESERVATIONS_MODULE_NAME = 'mod-reservations-v1';
  _exports.RESERVATIONS_MODULE_NAME = RESERVATIONS_MODULE_NAME;
  var _default = RESERVATIONS_STEP_NAME;
  _exports.default = _default;
});