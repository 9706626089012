define("lh4/routes/authenticated/internet-payments/reporting/documents", ["exports", "moment", "lh4/mixins/authenticated-route-mixin"], function (_exports, _moment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: 'Documents',
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    queryParams: {
      filterLocationId: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('view documents in general')) {
        this.transitionTo('authenticated.internet-payments.dashboard');
      }
    },
    model: function model(params) {
      var _this = this;

      var location = this.location.model;
      var locationId = params.filterLocationId || location.id;
      var hasOverviewAccess = this.can.can('view documents in general', null, {
        locationId: locationId
      });

      if (!hasOverviewAccess) {
        var locations = this.modelFor('authenticated').locations.filter(function (loc) {
          return _this.can.can('view documents in general', null, {
            locationId: loc.id
          });
        });

        if (locations && locations.length > 0) {
          locationId = locations.firstObject.id;
        }
      }

      var documentsMerchantId = this.modelFor('authenticated').locations.filter(function (documentLocation) {
        return documentLocation.id === locationId;
      }).map(function (loc) {
        return loc.merchantId;
      });
      var filter = {
        adapterOptions: {
          merchantId: documentsMerchantId,
          zone: _moment.default.tz.guess()
        },
        reload: true
      };
      return Ember.RSVP.hash({
        documentsMerchantId: documentsMerchantId,
        location: location,
        overview: this.store.findRecord('overview', locationId, filter),
        documents: this.store.findRecord('document', locationId),
        locations: this.modelFor('authenticated').locations
      });
    },
    actions: {
      updateDocumentsLocation: function updateDocumentsLocation() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});