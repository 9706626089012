define("lh4/routes/authenticated/locations/location/day-parts", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    router: Ember.inject.service(),
    corporation: Ember.inject.service(),
    intradayPeriod: Ember.inject.service('intraday-period'),
    beforeModel: function beforeModel() {
      // Fetch location menu settings to evaluate isMenuManagedByCorporate before components are loaded
      if (this.location.model.corpId) {
        this.corporation.loadLocationMenuSettings(this.location.model.id);
      }
    },
    model: function model(params) {
      var location = this.location.model.id;
      return this.intradayPeriod.getDayPartsData(this.controller, location, params);
    },
    setupController: function setupController() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var controller = args[0],
          model = args[1];

      this._super.apply(this, args);

      var dayPartsSnapshot = JSON.parse(JSON.stringify(model.dayParts));
      var intradayPeriodGroupsSnapshot = this.intradayPeriod.mapIntradayPeriodGroupNameToGuid(model.intradayPeriodGroups);
      controller.setProperties({
        dayPartsSnapshot: dayPartsSnapshot,
        intradayPeriodGroupsSnapshot: intradayPeriodGroupsSnapshot
      });
      var locationController = this.controllerFor('authenticated.locations.location');
      locationController.set('showLocationTabs', true);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var locationController = this.controllerFor('authenticated.locations.location');
        locationController.set('showLocationTabs', false);
      }
    },
    redirect: function redirect() {
      if (this.can.cannot('view day parts in day-part', this.location.model)) {
        this.transitionTo('authenticated.dashboard2');
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.isDirty) {
          if (transition.targetName.includes(this.router.currentRoute.parent.name)) {
            return true;
          }

          var confirmation = window.confirm('You have unsaved changes. Are you sure you want to leave this page?');

          if (confirmation) {
            this.controller.set('isDirty', false);
            return true;
          }

          transition.abort();
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});