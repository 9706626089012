define("lh4/controllers/authenticated/st-jude/opt-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ERROR_MESSAGE = 'Something went wrong. Please try again later.';

  var _default = Ember.Controller.extend({
    media: Ember.inject.service(),
    permissions: Ember.inject.service(),
    loading: false,
    error: null,
    actions: {
      optIn: function optIn() {
        var _this = this;

        this.setProperties({
          loading: true,
          error: null
        });
        return this.permissions.activateService('st-jude').then(function () {
          return _this.transitionToRoute('authenticated.st-jude');
        }).catch(function () {
          return _this.set('error', ERROR_MESSAGE);
        }).finally(function () {
          return _this.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});