define("lh4/templates/components/ui/dispute-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3aZAibWw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[10,\"class\",\"dispute-activity\"],[8],[0,\"\\n\\t\"],[7,\"li\",true],[10,\"class\",\"past-days\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[8],[1,[28,\"date-format\",[[23,0,[\"args\",\"event\",\"date\"]],\"MM/DD/YY h:mm A\"],null],false],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"days\"],[8],[1,[23,0,[\"daysPast\"]],false],[0,\" d.\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[4,\"if\",[[23,0,[\"args\",\"event\",\"name\"]]],null,{\"statements\":[[7,\"li\",true],[8],[1,[23,0,[\"args\",\"event\",\"name\"]],false],[0,\":\"],[9]],\"parameters\":[]},null],[0,\"\\n\\t\"],[7,\"li\",true],[8],[1,[23,0,[\"args\",\"event\",\"message\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/dispute-event.hbs"
    }
  });

  _exports.default = _default;
});