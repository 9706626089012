define("lh4/components/ui/form-fields/input-field", ["exports", "lh4/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    // input attributes
    maxLength: null,
    pattern: null,
    placeholder: null,
    inputmode: 'text',
    type: 'text',
    min: null,
    max: null,
    step: null,
    dataTest: 'form-field-input-field',
    trimValue: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.actions.onKeydown = function defaultOnKeydown() {
        return true;
      };

      Ember.defineProperty(this, 'showClearButton', Ember.computed("changeset.".concat(this.property), 'isClearButtonVisible', function compute() {
        if (!this.changeset || !this.property) {
          return false;
        }

        return this.isClearButtonVisible && !!this.changeset.get(this.property);
      }));

      if (!this.changeset || !this.changeset.data || !this.changeset.data.validations) {
        return;
      }

      var _ref = this.changeset.data.validations.attrs[this.property] || {},
          _ref$options = _ref.options,
          options = _ref$options === void 0 ? {} : _ref$options;

      if (options.length && options.length.max) {
        this.setProperties({
          maxLength: options.length.max
        });
      }

      if (this.type === 'number' && options.number) {
        var _options$number = options.number,
            positive = _options$number.positive,
            gt = _options$number.gt,
            gte = _options$number.gte,
            lt = _options$number.lt,
            lte = _options$number.lte; // min wasn't passed as attribute, try setting it from validation options

        if (this.min === null) {
          if (positive) {
            this.min = 0;
          } // both positive and gte/gt can be set


          if (gte || gt) {
            this.min = gte || gt + 1;
          }
        } // max wasn't passed as attribute, try setting it from validation options


        if (this.max === null) {
          this.max = lt ? lt - 1 : lte;
        }

        this.inputmode = 'decimal';
        this.pattern = '-[0-9.]*';

        this.actions.onKeydown = function keydown(event) {
          // only test single character keys ("Backspace" and arrows are OK)
          // allow numbers with decimal points
          if (event.key.length === 1 && !/[\d\-.]/.test(event.key)) {
            return false;
          }

          return true;
        };
      }
    },
    inputClass: Ember.computed('fullWidth', 'fieldWidth', function compute() {
      var suffix = ' wide column';

      if (this.fieldWidth) {
        return "".concat(this.fieldWidth).concat(suffix);
      }

      if (!this.label) {
        return "sixteen".concat(suffix);
      }

      return "".concat(this.fullWidth ? 'twelve' : 'eight').concat(suffix);
    }),
    // didUpdate: Ember lifecycle hook on Re-Render or any updates.
    didUpdate: function didUpdate() {
      if (this.isClearButtonVisible) {
        if (this.changeset.get("error.".concat(this.property)) === undefined) {
          return;
        }

        this.set('showErrors', true);
        this.set('showErrorTooltip', false);
      }
    },
    actions: {
      clear: function clear() {
        if (!this.changeset && !this.property) {
          return;
        }

        this.changeset.set(this.property, null);

        if (this.onChange) {
          this.onChange();
        }
      },
      onBlur: function onBlur(event) {
        if ((this.changeset || this.property) && this.get('trimValue')) {
          this.changeset.set(this.attrs.property, event.target.value.trim());
        }

        this.send('blurField');
      }
    }
  });

  _exports.default = _default;
});