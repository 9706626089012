define("lh4/components/epro/menu/modifier-set-form", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid', 'modifier-set-form'],
    showErrors: false,
    store: Ember.inject.service(),
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    selectedMapChangeset: null,
    mapSnapshots: {},
    locale: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.modifierPriceOptions = [{
        value: true,
        name: "Flat (".concat(this.locale.currencySymbol, ")")
      }, {
        value: false,
        name: 'Percentage (%)'
      }];
    },
    modifierSetDetailOptions: [{
      id: 'isPrintedRedOrBold',
      name: 'Print Modifiers in Red or Bold on Ticket'
    }, {
      id: 'isSetNamePrepended',
      name: 'Print Modifier Set Name with Modifiers on Ticket'
    }],
    headers: [{
      field: 'remove',
      name: '',
      width: 'one'
    }, {
      field: 'name',
      name: 'Modifier',
      width: 'four'
    }, {
      field: 'value',
      name: 'Price',
      width: 'four'
    }, {
      field: 'isActive',
      name: 'Online Ordering',
      width: 'four'
    }, {
      field: 'onlineValue',
      name: 'Online Price',
      width: 'three'
    }],
    defaultModifierPrice: Ember.computed('changeset.isFlatPrice', 'changeset.value', function getter() {
      var value = this.changeset.get('value') ? this.changeset.get('value') : 0;
      return this.changeset.get('isFlatPrice') ? "".concat(this.locale.currencyFormat(value)) : "".concat(Number(value), "%");
    }),
    modifierTableRows: Ember.computed('changeset.mapChangesets.@each.{modifierGuid,value,isFlatAmount,onlineValue,isFlatAmountOnline}', 'defaultModifierPrice', function getter() {
      var _this = this;

      return this.changeset.mapChangesets.map(function (changeset) {
        var modifierGuid = changeset.get('modifierGuid');

        var modifier = _this.modifiers.find(function (modifierClass) {
          return modifierClass.guid === modifierGuid;
        });

        var changesetValue = changeset.get('value') !== null ? Number(changeset.get('value')) : null;
        var changesetOnlineValue = changeset.get('onlineValue') !== null ? Number(changeset.get('onlineValue')) : null;
        var price = _this.defaultModifierPrice;

        if (changesetValue || changesetValue === 0) {
          price = changeset.get('isFlatAmount') ? "".concat(_this.locale.currencyFormat(changesetValue.toFixed(2))) : "".concat(changesetValue, "%");
        }

        var onlinePrice = price;

        if (changesetOnlineValue) {
          onlinePrice = changeset.get('isFlatAmountOnline') ? "".concat(_this.locale.currencyFormat(changesetOnlineValue.toFixed(2))) : "".concat(changesetOnlineValue, "%");
        }

        if (price === _this.defaultModifierPrice) {
          price = "Default (".concat(_this.defaultModifierPrice, ")");
        }

        return {
          modifierName: modifier.name,
          modifierActive: modifier.active,
          onlineActive: modifier.onlineActive,
          onlinePrice: onlinePrice,
          price: price,
          changeset: changeset
        };
      });
    }),

    /**
     * Returns a filtered array of modifiers that are not currently selected in
     * the modifier set.  This prevents duplicate modifiers from being added to the
     * same modifier.
     * @returns  {Array} array - returns an array of unused available Modifiers.
     */
    availableModifiers: Ember.computed('changeset.mapChangesets.@each.modifierGuid', 'selectedMapChangeset', function getter() {
      if (this.changeset.mapChangesets.length === 0) {
        return this.modifiers;
      }

      var excludedModifierGuids = this.changeset.mapChangesets.map(function (changeset) {
        return changeset.get('modifierGuid');
      });

      if (this.selectedMapChangeset) {
        excludedModifierGuids.removeObject(this.selectedMapChangeset.get('modifierGuid'));
      }

      return this.modifiers.filter(function (_ref) {
        var guid = _ref.guid;
        return !excludedModifierGuids.includes(guid);
      });
    }),
    noMoreModifiers: Ember.computed('availableModifiers', function getter() {
      if (this.availableModifiers) {
        return !this.availableModifiers.length;
      }

      return true;
    }),
    showModal: function showModal(mapChangeset) {
      var showModal = mapChangeset ? 'show' : 'hide';
      this.set('selectedMapChangeset', mapChangeset);
      (0, _jquery.default)('#modifier-modal').modal(showModal);
    },
    actions: {
      addModifier: function addModifier() {
        var modifierSetMap = this.store.createRecord('echo-pro/modifier-set-map', {
          modifierSetGuid: this.changeset.get('guid'),
          modifierGuid: this.availableModifiers.firstObject.guid
        });
        var selectedMapChangeset = this.buildChildChangeset(modifierSetMap);
        this.changeset.mapChangesets.pushObject(selectedMapChangeset);
        this.showModal(selectedMapChangeset);
      },
      removeModifier: function removeModifier(record) {
        this.changeset.mapChangesets.removeObject(record.changeset);
      },
      updateModifier: function updateModifier(record) {
        this.showModal(record.changeset);
      },
      modalDeny: function modalDeny() {
        var mapChangeset = this.selectedMapChangeset;
        var snapshot = this.mapSnapshots[mapChangeset.get('modifierGuid')];

        if (snapshot) {
          mapChangeset.restore(snapshot);
        } else if (!mapChangeset.get('id')) {
          this.changeset.mapChangesets.removeObject(mapChangeset);
        } else {
          mapChangeset.rollback();
        }

        this.showModal(null);
      },
      onModalSave: function onModalSave() {
        var mapChangeset = this.selectedMapChangeset;
        this.mapSnapshots[mapChangeset.get('modifierGuid')] = mapChangeset.snapshot();
        this.showModal(null);
      },
      handleIsRequiredChange: function handleIsRequiredChange(value) {
        this.changeset.set('oneOnly', !value && this.changeset.get('oneOnly'));
        this.changeset.set('isRequired', value);
      }
    }
  });

  _exports.default = _default;
});