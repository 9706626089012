define("lh4/mirage/fixtures/internet-payments/configurable-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CONFIGURABLE_COLUMNS_FIXTURE = [{
    createdAt: '2024-03-04T15:41:17.633Z',
    updatedAt: '2024-03-07T16:18:27.507Z',
    guid: '0c9197e8-7d47-4983-9379-38bc76d40e2e',
    viewName: 'test',
    resourceViewPerspective: 'TRANSACTION-MASTER-EW',
    userId: 3249,
    locationId: 5520,
    lbmColumnOptions: [{
      sortable: true,
      optionId: 'DATE',
      visible: true,
      name: 'Date',
      field: 'date',
      columnType: 'date'
    }, {
      sortable: true,
      optionId: 'TRANSACTION_ID',
      visible: true,
      name: 'Transaction ID',
      field: 'transactionId',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false,
        position: 'left'
      }
    }, {
      sortable: false,
      optionId: 'AUTHORIZATION_CODE',
      visible: true,
      name: 'Authorization Code',
      field: 'authorizationCode',
      columnType: 'text'
    }, {
      sortable: true,
      optionId: 'AMOUNT',
      visible: true,
      name: 'Total Amount',
      field: 'amount',
      columnType: 'currency'
    }, {
      sortable: true,
      optionId: 'CREDIT_CARD',
      visible: true,
      name: 'Credit Card',
      field: 'cardNumber',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false
      }
    }, {
      sortable: true,
      optionId: 'PRIMARY_AMOUNT',
      visible: true,
      name: 'Primary Amount',
      field: 'primaryAmount',
      columnType: 'currency'
    }, {
      sortable: true,
      optionId: 'SECONDARY_AMOUNT',
      visible: true,
      name: 'Tip Amount',
      field: 'secondaryAmount',
      columnType: 'currency'
    }, {
      sortable: false,
      optionId: 'CARD_TOKEN',
      visible: false,
      name: 'Card Token',
      field: 'cardToken',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'CUSTOMER_EMAIL',
      visible: true,
      name: 'Email',
      field: 'customerEmail',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false,
        position: 'right'
      }
    }, {
      sortable: true,
      optionId: 'CUSTOMER_NAME',
      visible: true,
      name: 'Customer',
      field: 'customerName',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false,
        position: 'right'
      }
    }, {
      sortable: true,
      optionId: 'ORDER_STATUS',
      visible: true,
      name: 'Transaction Status',
      field: 'processingStatus',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'CUSTOMER_IP',
      visible: false,
      name: 'Customer IP Address',
      field: 'customerIpAddress',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'EVENT_STATUS',
      visible: false,
      name: 'Event Status',
      field: 'eventStatus',
      columnType: 'text'
    }, {
      sortable: true,
      optionId: 'TRANSACTION_TYPE',
      visible: true,
      name: 'Order Type',
      field: 'transType',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'ORDER_NUMBER',
      visible: true,
      name: 'Order',
      field: 'orderNumber',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'SERVER_NAME',
      visible: true,
      name: 'Server',
      field: 'serverName',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'ACQUIRER_RESPONSE',
      visible: true,
      name: 'Acquirer Response',
      field: 'acquirerResponse',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }],
    product: 'internet-payments',
    isFavorite: false
  }, {
    createdAt: '2024-03-04T17:52:32.362Z',
    updatedAt: '2024-03-04T17:52:32.362Z',
    guid: '8c3b5be0-eda0-4048-bcd1-7293557c6bb2',
    viewName: 'test1',
    resourceViewPerspective: 'TRANSACTION-MASTER-EW',
    userId: 3249,
    locationId: 5520,
    lbmColumnOptions: [{
      sortable: true,
      optionId: 'DATE',
      visible: true,
      name: 'Date',
      field: 'date',
      columnType: 'date'
    }, {
      sortable: true,
      optionId: 'TRANSACTION_ID',
      visible: true,
      name: 'Transaction ID',
      field: 'transactionId',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false,
        position: 'left'
      }
    }, {
      sortable: false,
      optionId: 'AUTHORIZATION_CODE',
      visible: true,
      name: 'Authorization Code',
      field: 'authorizationCode',
      columnType: 'text'
    }, {
      sortable: true,
      optionId: 'AMOUNT',
      visible: true,
      name: 'Total Amount',
      field: 'amount',
      columnType: 'currency'
    }, {
      sortable: true,
      optionId: 'CREDIT_CARD',
      visible: true,
      name: 'Credit Card',
      field: 'cardNumber',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false
      }
    }, {
      sortable: true,
      optionId: 'PRIMARY_AMOUNT',
      visible: true,
      name: 'Primary Amount',
      field: 'primaryAmount',
      columnType: 'currency'
    }, {
      sortable: true,
      optionId: 'SECONDARY_AMOUNT',
      visible: true,
      name: 'Tip Amount',
      field: 'secondaryAmount',
      columnType: 'currency'
    }, {
      sortable: false,
      optionId: 'CARD_TOKEN',
      visible: false,
      name: 'Card Token',
      field: 'cardToken',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'CUSTOMER_EMAIL',
      visible: false,
      name: 'Email',
      field: 'customerEmail',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false,
        position: 'right'
      }
    }, {
      sortable: true,
      optionId: 'CUSTOMER_NAME',
      visible: false,
      name: 'Customer',
      field: 'customerName',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false,
        position: 'right'
      }
    }, {
      sortable: true,
      optionId: 'ORDER_STATUS',
      visible: false,
      name: 'Transaction Status',
      field: 'processingStatus',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'CUSTOMER_IP',
      visible: true,
      name: 'Customer IP Address',
      field: 'customerIpAddress',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'EVENT_STATUS',
      visible: true,
      name: 'Event Status',
      field: 'eventStatus',
      columnType: 'text'
    }, {
      sortable: true,
      optionId: 'TRANSACTION_TYPE',
      visible: true,
      name: 'Order Type',
      field: 'transType',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'ORDER_NUMBER',
      visible: true,
      name: 'Order',
      field: 'orderNumber',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'SERVER_NAME',
      visible: true,
      name: 'Server',
      field: 'serverName',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'ACQUIRER_RESPONSE',
      visible: true,
      name: 'Acquirer Response',
      field: 'acquirerResponse',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }],
    product: 'internet-payments',
    isFavorite: false
  }, {
    guid: 'default',
    viewName: 'Default',
    locationId: 5520,
    userId: 3249,
    lbmColumnOptions: [{
      sortable: true,
      optionId: 'DATE',
      visible: true,
      name: 'Date',
      field: 'date',
      columnType: 'date'
    }, {
      sortable: true,
      optionId: 'TRANSACTION_ID',
      visible: true,
      name: 'Transaction ID',
      field: 'invoice',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false,
        position: 'left'
      }
    }, {
      sortable: false,
      optionId: 'AUTHORIZATION_CODE',
      visible: true,
      name: 'Authorization Code',
      field: 'authorizationCode',
      columnType: 'text'
    }, {
      sortable: true,
      optionId: 'AMOUNT',
      visible: true,
      name: 'Total Amount',
      field: 'amount',
      columnType: 'currency'
    }, {
      sortable: true,
      optionId: 'PRIMARY_AMOUNT',
      visible: true,
      name: 'Primary Amount',
      field: 'primaryAmount',
      columnType: 'currency'
    }, {
      sortable: true,
      optionId: 'SECONDARY_AMOUNT',
      visible: true,
      name: 'Tip Amount',
      field: 'secondaryAmount',
      columnType: 'currency'
    }, {
      sortable: false,
      optionId: 'CUSTOMER_EMAIL',
      visible: true,
      name: 'Email',
      field: 'customerEmail',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false,
        position: 'right'
      }
    }, {
      sortable: true,
      optionId: 'CUSTOMER_NAME',
      visible: true,
      name: 'Customer',
      field: 'customerName',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false,
        position: 'right'
      }
    }, {
      sortable: true,
      optionId: 'CREDIT_CARD',
      visible: true,
      name: 'Credit Card',
      field: 'cardNumber',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false
      }
    }, {
      sortable: true,
      optionId: 'ORDER_STATUS',
      visible: true,
      name: 'Transaction Status',
      field: 'processingStatus',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'EVENT_STATUS',
      visible: true,
      name: 'Event Status',
      field: 'eventStatus',
      columnType: 'text'
    }, {
      sortable: true,
      optionId: 'TRANSACTION_TYPE',
      visible: true,
      name: 'Order Type',
      field: 'transType',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'ORDER_NUMBER',
      visible: true,
      name: 'Order',
      field: 'orderNumber',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'SERVER_NAME',
      visible: true,
      name: 'Server',
      field: 'serverName',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'ACQUIRER_RESPONSE',
      visible: true,
      name: 'Acquirer Response',
      field: 'acquirerResponse',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'CUSTOMER_IP',
      visible: false,
      name: 'Customer IP Address',
      field: 'customerIpAddress',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'CARD_TOKEN',
      visible: false,
      name: 'Card Token',
      field: 'cardToken',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }],
    isFavorite: true,
    resourceViewPerspective: 'TRANSACTION-MASTER-EW',
    product: 'internet-payments'
  }];
  var _default = CONFIGURABLE_COLUMNS_FIXTURE;
  _exports.default = _default;
});