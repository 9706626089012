define("lh4/templates/components/ui/icons/internet-payments/download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WtKp8B9g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"class\",\"download-btn\"],[10,\"width\",\"20\"],[10,\"height\",\"20\"],[10,\"viewBox\",\"0 0 16 16\"],[10,\"fill\",\"currentColor\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/internet-payments/download.hbs"
    }
  });

  _exports.default = _default;
});