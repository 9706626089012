define("lh4/controllers/authenticated/epro/menu/items/edit", ["exports", "lh4/controllers/general-form-controller", "lh4/mixins/controllers/authenticated/epro/menu/stylesheet-saving"], function (_exports, _generalFormController, _stylesheetSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend(_stylesheetSaving.default, {
    fullBlob: null,
    thumbBlob: null,
    metrics: Ember.inject.service(),
    locale: Ember.inject.service(),
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    ajax: Ember.inject.service(),
    can: Ember.inject.service(),
    previewImageUrl: Ember.computed('changeset.imageUrl', 'shouldDeleteImage', function getter() {
      if (this.shouldDeleteImage) {
        return null;
      }

      return this.changeset.get('imageUrl');
    }),
    shouldHandleImage: Ember.computed('shouldSaveImage', 'shouldDeleteImage', function getter() {
      return this.shouldSaveImage || this.shouldDeleteImage;
    }),
    removeRecordsFromModel: function removeRecordsFromModel(model, changeset) {
      model.filter(function (_ref) {
        var id = _ref.id;
        return !changeset.findBy('id', id);
      }).forEach(function (record) {
        return model.removeObject(record);
      });
    },
    saveImage: function saveImage(_ref2) {
      var itemId = _ref2.itemId;
      var formData = new FormData();
      formData.append('full', this.fullBlob, "".concat(itemId, "-full.png"));
      formData.append('thumb', this.thumbBlob, "".concat(itemId, "-thumb.png"));
      return this.ajax.request("/api/v1/echo-pro/items/".concat(itemId, "/image"), {
        method: 'POST',
        data: formData,
        contentType: false,
        processData: false
      }).catch(function (error) {
        var errorMessage = 'Something went wrong, image could not be saved';

        if (error.payload && error.payload.error && error.payload.error.message) {
          errorMessage = "".concat(errorMessage, ": ").concat(error.payload.error.message);
        }

        return Ember.RSVP.reject(errorMessage);
      });
    },
    deleteImage: function deleteImage(_ref3) {
      var _this = this;

      var itemId = _ref3.itemId;
      return this.ajax.delete("/api/v1/echo-pro/items/".concat(itemId, "/image")).then(function () {
        return _this.setProperties({
          fullBlob: null,
          thumbBlob: null
        });
      }).catch(function () {
        return Ember.RSVP.resolve('There was an unexpected error while removing your image');
      });
    },
    handleImageError: function handleImageError(error) {
      var _this2 = this;

      // TODO normalize how we handle these error messages
      var updateError = 'Something went wrong, image could not be saved';
      var errorMessage = error || updateError;
      this.set('serverError', errorMessage);
      this.set('serverErrorTimeoutId', Ember.run.later(this, function () {
        _this2.set('serverError', null);
      }, this.serverErrorTimeoutDelay));
    },
    unloadChildRecords: function unloadChildRecords() {
      // Old "Pricing Schedules" functionality
      this.model.item.itemPriceScheduleMaps.toArray().forEach(function (map) {
        map.unloadRecord();
      }); // New "Dynamic Pricing" functionality

      this.model.item.dynamicPricing.toArray().forEach(function (map) {
        map.unloadRecord();
      });
    },
    validateAndSaveChangeset: function validateAndSaveChangeset(changesets, key) {
      var _this3 = this;

      return changesets.map(function (record) {
        return record.validate().then(function () {
          if (!record.isValid) {
            return Ember.RSVP.reject(record.get('errors'));
          }

          record.execute();
          record.rollback();

          _this3.model.item.get(key).pushObject(record.data);

          return true;
        }).catch(function () {
          _this3.set('areChildrenInvalid', true);

          return false;
        });
      });
    },
    saveChangeset: function saveChangeset() {
      var _this4 = this;

      var shouldCloseTray = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var cloning = arguments.length > 1 ? arguments[1] : undefined;
      var snapshot = this.changeset.snapshot();
      this.createStposUiStylesheetObjectLink({
        locationId: this.changeset.get('locationId'),
        isActive: true,
        itemGuid: this.changeset.get('id')
      });
      return this.changeset.save().then(function (data) {
        // Reload child items fully to get proper formatting of the .priceValue
        // otherwise child elements stay in the dirty stay forever where
        // dirtyType: 'updated'
        _this4.unloadChildRecords();

        if (shouldCloseTray) {
          if (cloning) {
            _this4.duplicateItem(data.id);
          }

          _this4.closeTray();
        }
      }).catch(function (_ref4) {
        var errors = _ref4.errors;

        if (!(errors && errors[0])) {
          return false;
        } // TODO normalize how we handle these error messages


        var updateError = "Something went wrong, ".concat(_this4.instanceName, " could not be updated.");
        errors.forEach(function (error) {
          var errorMessage = error.details && error.details[0] || error.message || updateError;

          _this4.changeset.restore(snapshot);

          _this4.changeset.data.rollbackAttributes();

          _this4.set('serverError', errorMessage);

          _this4.set('serverErrorTimeoutId', Ember.run.later(_this4, function () {
            _this4.set('serverError', null);
          }, _this4.serverErrorTimeoutDelay));
        });
        return Ember.RSVP.reject([updateError]);
      });
    },
    // Remove maps that have no priceValue set - that's the only property that can indicate us
    // that this is being deleted
    removeDeletedPriceScheduleMaps: function removeDeletedPriceScheduleMaps() {
      this.changeset.priceScheduleMapChangesets = this.changeset.priceScheduleMapChangesets.reduce(function (result, option) {
        if (option.get('priceValue')) {
          result.push(option);
        }

        return result;
      }, []);
    },
    // To handle both old "Pricing Schedules" and new "Dynamic Pricing"
    savePricing: function savePricing() {
      var _this5 = this;

      // Begin, old "Pricing Schedules" functionality
      this.removeDeletedPriceScheduleMaps(); // Logic to warn user that with Flat Discount in price schedules item can be prized at 0 (or lower)

      var priceScheduleZeroRecords = this.changeset.priceScheduleMapChangesets.filter(function (record) {
        return record.get('priceType') === 'Flat Discount';
      }).filter(function (record) {
        var priceValue = parseFloat(record.get('priceValue'));
        return priceValue >= parseFloat(_this5.changeset.get('price'));
      });

      if (priceScheduleZeroRecords.length > 0) {
        var confirmed = window.confirm("One of the prices affected by Scheduled Pricing rules will result to ".concat(this.locale.currencySymbol, "0. Would you like to proceed?"));

        if (!confirmed) {
          this.set('isSaving', false);
          return Promise.reject(new Error('Please confirm'));
        }
      }

      var itemPriceScheduleMapPromises = this.validateAndSaveChangeset(this.changeset.priceScheduleMapChangesets, 'itemPriceScheduleMaps'); // End, old "Pricing Schedules" functionality
      // Begin, new "Dynamic Pricing" functionality

      if (this.changeset.dynamicPricingChangesets.some(function (c) {
        return c.get('hasZeroPrice') === true;
      })) {
        var _confirmed = window.confirm("The item price when affected by Dynamic Pricing rules will result to ".concat(this.locale.currencySymbol, "0. Would you like to proceed?"));

        if (!_confirmed) {
          this.set('isSaving', false);
          return Promise.reject(new Error('Please confirm'));
        }
      }

      var dynamicPricingChangesets = this.validateAndSaveChangeset(this.changeset.dynamicPricingChangesets, 'dynamicPricing'); // End, new "Dynamic Pricing" functionality

      return Ember.RSVP.all([itemPriceScheduleMapPromises, dynamicPricingChangesets]);
    },
    duplicateItem: function duplicateItem(itemId) {
      var _this6 = this;

      Ember.run.later(this, function () {
        _this6.transitionToRoute('authenticated.epro.menu.items.create', {
          queryParams: {
            clone: itemId
          }
        });
      }, 500);
    },
    trackUpdateCompleted: function trackUpdateCompleted() {
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Completed',
        'Entry Type': 'Edit',
        'Entry ID': this.changeset.get('id'),
        'Number of Updates': Object.keys(this.changeset.change).length,
        'Entries Updated': Object.keys(this.changeset.change),
        'Menu Component': 'Item'
      });
    },
    actions: {
      setImageBlobs: function setImageBlobs(_ref5) {
        var fullBlob = _ref5.fullBlob,
            thumbBlob = _ref5.thumbBlob;

        if (!(fullBlob instanceof Blob) || !(thumbBlob instanceof Blob)) {
          throw new Error('fullBlob & thumbBlob must be of type Blob');
        } // ensure change will be detected and discard dialog will be shown
        // value doesn't matter as this will be updated after image upload


        this.changeset.set('imageUrl', 'any');
        this.setProperties({
          fullBlob: fullBlob,
          thumbBlob: thumbBlob,
          shouldSaveImage: true
        });
      },
      deleteImage: function deleteImage() {
        // ensure change will be detected and discard dialog will be shown
        this.changeset.set('imageUrl', null);
        this.setProperties({
          shouldSaveImage: false,
          shouldDeleteImage: true
        });
      },
      update: function update() {
        var _this7 = this;

        var cloning = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError(); // we have to check for pristine before we validate and save

        var isPristine = this.isModelAndChangesetPristine() && this.areChildrenPristine(this.model.item.itemPriceScheduleMaps, this.changeset.priceScheduleMapChangesets);
        this.savePricing().then(function () {
          _this7.changeset.validate().then(function () {
            if (!_this7.changeset.get('isValid') || _this7.areChildrenInvalid) {
              return Ember.RSVP.reject(_this7.changeset.get('errors'));
            }

            var itemId = _this7.changeset.get('id');

            _this7.trackUpdateCompleted();

            if (isPristine && !_this7.shouldHandleImage) {
              if (cloning) {
                _this7.duplicateItem(itemId);
              }

              _this7.closeTray();

              return Ember.RSVP.resolve();
            }

            _this7.removeRecordsFromModel(_this7.model.item.itemPriceScheduleMaps, _this7.changeset.priceScheduleMapChangesets);

            if (!_this7.shouldHandleImage) {
              return _this7.saveChangeset(true, cloning);
            }

            return _this7.saveChangeset(false, cloning).then(function () {
              var imagePromise = _this7.shouldSaveImage ? _this7.saveImage({
                itemId: itemId
              }) : _this7.deleteImage({
                itemId: itemId
              });
              return imagePromise.then(function () {
                if (cloning) {
                  _this7.duplicateItem(itemId);
                }

                return Ember.RSVP.resolve(_this7.closeTray());
              }).catch(function (error) {
                return _this7.handleImageError(error);
              });
            });
          }).catch(function (errors) {
            // We currently don't have a field component for checkboxes
            // Because of this there is no standard way of displaying
            // error messages for boolean fields
            // https://jira.shift4payments.com/browse/LH-8853
            if (!errors.length) {
              return;
            }

            var booleanError = errors.find(function (error) {
              return typeof error.value === 'boolean';
            });

            if (booleanError) {
              _this7.set('booleanError', booleanError.validation);

              _this7.set('serverErrorTimeoutId', Ember.run.later(_this7, function () {
                _this7.set('booleanError', null);
              }, _this7.serverErrorTimeoutDelay));
            }
          }).finally(function () {
            return _this7.setProperties({
              isSaving: false
            });
          });
        });
      },
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      },
      selectedPriceGroupChanged: function selectedPriceGroupChanged(data) {
        return this.selectedPriceGroupChanged(data);
      },
      updateAndDuplicate: function updateAndDuplicate() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.send.apply(this, ['update', true].concat(args));
      }
    }
  });

  _exports.default = _default;
});