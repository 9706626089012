define("lh4/templates/components/add-on-devices/record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oB9Yqth5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"list-item\"],[10,\"tabindex\",\"0\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"item-name\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"item\"],true],[0,\"\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",\"times circle icon\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[24,[\"onDelete\"]],[24,[\"record\"]]]],[8],[9],[1,[24,[\"record\",\"name\"]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"quantity\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"ui/form-fields/input-field\",null,[[\"changeset\",\"type\",\"min\",\"max\",\"placeholder\",\"property\",\"showErrors\",\"showErrorTooltip\"],[[24,[\"record\"]],\"number\",\"1\",\"100\",\"Enter Amount\",\"quantity\",[24,[\"showErrors\"]],[24,[\"showErrorTooltip\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/add-on-devices/record.hbs"
    }
  });

  _exports.default = _default;
});