define("lh4/templates/components/ui/form-fields/radio-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ODQ0MTG",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"radio-list \",[22,\"fieldClasses\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[4,\"ui/form-fields/shared/label\",null,[[\"dataTest\",\"fullWidth\",\"required\"],[\"label\",[24,[\"fullWidth\"]],[24,[\"required\"]]]],{\"statements\":[[1,[22,\"label\"],false]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"radio-items\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"radioItems\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"radio-item\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"ui-radio\",null,[[\"name\",\"label\",\"current\",\"onChange\",\"value\"],[[28,\"or\",[[24,[\"name\"]],[24,[\"property\"]]],null],[23,1,[\"label\"]],[28,\"get\",[[24,[\"changeset\"]],[24,[\"property\"]]],null],[28,\"action\",[[23,0,[]],\"onChange\",[23,1,[\"value\"]]],null],[23,1,[\"value\"]]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showErrorTooltip\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui inverted red segment error-message\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"get\",[[28,\"get\",[[24,[\"changeset\",\"error\"]],[24,[\"property\"]]],null],\"validation\"],null],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/form-fields/radio-list.hbs"
    }
  });

  _exports.default = _default;
});