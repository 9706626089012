define("lh4/templates/authenticated/hardware/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D/Upxzdj",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"hardware/product-page\",[],[[\"@productName\",\"@price\",\"@heading\",\"@about\",\"@secondAbout\",\"@productDetails\",\"@productDetailsImageSrc\",\"@options\",\"@selectedOption\",\"@selectedOptions\",\"@itemsToAdd\"],[[23,1,[\"product\",\"productPageDetails\",\"name\"]],[23,1,[\"product\",\"price\"]],[23,1,[\"product\",\"productPageDetails\",\"headingSection\"]],[23,1,[\"product\",\"productPageDetails\",\"aboutSection\"]],[23,1,[\"product\",\"productPageDetails\",\"secondAboutSection\"]],[23,1,[\"product\",\"productPageDetails\",\"productDetailsSection\"]],[23,1,[\"product\",\"productPageDetails\",\"productDetailsSection\",\"imageUrl\"]],[23,1,[\"product\",\"options\"]],[23,0,[\"selectedOption\"]],[23,0,[\"selectedOptions\"]],[23,0,[\"itemsToAdd\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/hardware/product.hbs"
    }
  });

  _exports.default = _default;
});