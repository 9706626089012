define("lh4/templates/components/tables/sales-summary-tender-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DVP/JB0l",
    "block": "{\"symbols\":[\"row\",\"index\",\"col\",\"row\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\\t\"],[7,\"table\",true],[10,\"class\",\"ui table unstackable padded sales-summary-table\"],[8],[0,\"\\n\\t\\t\"],[7,\"thead\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"get\",[[24,[\"data\",\"cardBrandResults\"]],\"0\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[23,4,[]],false],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"data\",\"cardBrandResults\"]]],null,{\"statements\":[[4,\"if\",[[28,\"not\",[[28,\"and\",[[24,[\"firstRowHeader\"]],[28,\"eq\",[[23,2,[]],0],null]],null]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\\t\"],[7,\"td\",true],[8],[1,[23,3,[]],false],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"tfoot\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"Grand Totals\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"td\",true],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"td\",true],[8],[1,[24,[\"data\",\"totalPlusTips\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"td\",true],[8],[1,[24,[\"data\",\"totalTips\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"td\",true],[8],[1,[24,[\"data\",\"totalAmount\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/tables/sales-summary-tender-table.hbs"
    }
  });

  _exports.default = _default;
});