define("lh4/models/echo-pro/availability-price/configure-pricing", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    priceType: (0, _emberCpValidations.validator)('presence', {
      description: 'Price Type',
      presence: true
    }),
    priceValue: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      description: 'Price Value',
      positive: true,
      allowString: true,
      lte: Ember.computed('model.priceType', function getter() {
        var priceType = this.model.get('priceType');

        if (priceType === 'Discount' || priceType === 'Surcharge') {
          // 100%
          return 100;
        } // This is to help mimic what is happening on the terminal


        return 10000000;
      })
    })
  });

  var _default = _model.default.extend(Validations, {
    scheduleTitleGuid: (0, _attr.default)('string'),
    scheduleNumber: (0, _attr.default)('number'),
    useIntradayPeriods: (0, _attr.default)('boolean'),
    intradayPeriodGroupGuid: (0, _attr.default)('string'),
    // Dollars, Discount, Surcharge, Flat Discount, Flat Surcharge
    priceType: (0, _attr.default)('string', {
      defaultValue: 'Dollars'
    }),
    priceValue: (0, _attr.default)('number'),
    compare: function compare(other) {
      if (!other) {
        return false;
      }

      return this.get('scheduleTitleGuid') === other.get('scheduleTitleGuid') && this.get('scheduleNumber') === other.get('scheduleNumber');
    }
  });

  _exports.default = _default;
});