define("lh4/routes/authenticated/epro/menu/items/create", ["exports", "lh4/routes/general-create-route", "lh4/utils/increment-name"], function (_exports, _generalCreateRoute, _incrementName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    intradayPeriod: Ember.inject.service('intraday-period'),
    modelName: 'item',
    // TODO: implement hasDirtyChangesets
    // @see https://jira.shift4payments.com/browse/LH-10726
    queryParams: {
      clone: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var clone = _ref.clone;
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        item: this.createNewItem(clone),
        departments: this.modelFor('authenticated.epro.menu.items').departments,
        modifierSets: this.modelFor('authenticated.epro.menu.items').modifierSets,
        taxes: this.modelFor('authenticated.epro.menu.items').taxes,
        revenueClasses: this.modelFor('authenticated.epro.menu.items').revenueClasses,
        courses: this.modelFor('authenticated.epro.menu.items').courses,
        printers: this.modelFor('authenticated.epro.menu.items').printers,
        itemTags: this.modelFor('authenticated.epro.menu.items').itemTags,
        priceScheduleGroups: this.modelFor('authenticated.epro.menu.items').priceScheduleGroups,
        stylesheets: this.isEproColorPickerFeatureFlagEnabled() ? this.store.findAll('echo-pro/stylesheet') : null,
        dayPartOptions: this.intradayPeriod.getDayPartsData(this.controller, location),
        revenueCenterOptions: this.store.query('echo-pro/revenue-center', {
          location: location
        }).then(function (revenueCenters) {
          return revenueCenters.map(function (_ref2) {
            var guid = _ref2.guid,
                revenueCenterName = _ref2.revenueCenterName;
            return {
              value: guid,
              name: revenueCenterName
            };
          });
        }),
        dynamicPrices: this.store.query('echo-pro/dynamic-price', {
          location: this.location.model.id,
          limit: 100,
          order: 'isActive',
          sort: 'desc'
        }),
        timeOptions: ['All Day']
      });
    },
    setupController: function setupController(theController, model) {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [theController, model].concat(args));

      var controller = theController;
      controller.changeset.priceScheduleMapChangesets = model.item.itemPriceScheduleMaps.map(this.buildChildChangeset);
      controller.changeset.dynamicPricingChangesets = model.item.dynamicPricing.map(this.buildChildChangeset);
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Started',
        'Entry Type': 'New',
        'Entry ID': model.item.guid,
        'Menu Component': 'Item'
      });

      if (controller.clone) {
        // changing of changeset properties instead of model properties ensures that changeset becomes dirty
        // and confirm dialog is fired when trying to close modal after cloning even if nothing was changed
        var _this$store$peekRecor = this.store.peekRecord('echo-pro/item', controller.clone),
            name = _this$store$peekRecor.name,
            onlineName = _this$store$peekRecor.onlineName;

        controller.changeset.set('name', (0, _incrementName.default)(name));
        controller.changeset.set('onlineName', onlineName ? (0, _incrementName.default)(onlineName) : null);
      }

      var activeGroup = model.priceScheduleGroups.find(function (x) {
        return x.isActive;
      });
      var activeDynamicPrice = model.dynamicPrices.find(function (x) {
        return x.isActive;
      });
      controller.setProperties({
        buildChildChangeset: function buildChildChangeset(childModel) {
          return _this.buildChildChangeset(childModel);
        },
        selectedPriceScheduleGroupGuid: activeGroup === null || activeGroup === void 0 ? void 0 : activeGroup.guid,
        priceScheduleOptions: this.getPriceScheduleOptions(activeGroup, controller.changeset),
        selectedPriceGroupChanged: function selectedPriceGroupChanged(data) {
          return _this.selectedPriceGroupChanged(data);
        },
        firstDynamicPriceGuid: activeDynamicPrice === null || activeDynamicPrice === void 0 ? void 0 : activeDynamicPrice.guid
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('clone', null);
      }
    },
    selectedPriceGroupChanged: function selectedPriceGroupChanged(selectedPriceScheduleGroupGuid) {
      var _this2 = this;

      this.set('selectedPriceScheduleGroupGuid', selectedPriceScheduleGroupGuid);
      var activeGroup = this.currentModel.priceScheduleGroups.find(function (x) {
        return x.guid === _this2.selectedPriceScheduleGroupGuid;
      });
      var priceScheduleOptions = this.getPriceScheduleOptions(activeGroup, this.controller.changeset);
      this.controller.setProperties({
        selectedPriceScheduleGroupGuid: selectedPriceScheduleGroupGuid,
        priceScheduleOptions: priceScheduleOptions
      });
    },
    getPriceScheduleOptions: function getPriceScheduleOptions(activeGroup, changeset) {
      var _this3 = this;

      var itemGuid = changeset.get('guid');
      return activeGroup === null || activeGroup === void 0 ? void 0 : activeGroup.priceSchedules.sortBy('name').map(function (priceSchedule) {
        var name = priceSchedule.name,
            priceScheduleGuid = priceSchedule.guid;
        var itemPriceScheduleMap = changeset.priceScheduleMapChangesets.findBy('priceScheduleGuid', priceScheduleGuid);

        if (!itemPriceScheduleMap) {
          var itemPriceScheduleMapModel = _this3.store.createRecord('echo-pro/item-price-schedule-map', {
            itemGuid: itemGuid,
            priceScheduleGuid: priceScheduleGuid
          });

          itemPriceScheduleMap = _this3.buildChildChangeset(itemPriceScheduleMapModel);
          changeset.priceScheduleMapChangesets.pushObject(itemPriceScheduleMap);
        }

        itemPriceScheduleMap.set('name', name);
        itemPriceScheduleMap.set('ranges', [priceSchedule]);
        return itemPriceScheduleMap;
      });
    },
    createNewItem: function createNewItem(cloneId) {
      return cloneId ? this.store.findRecord('echo-pro/item', cloneId).then(function (original) {
        return original.duplicateModel();
      }).then(function (duplicate) {
        return duplicate.recreateMappingRelation('itemPriceScheduleMaps', 'echo-pro/item-price-schedule-map');
      }) : this.store.createRecord('echo-pro/item', {
        locationId: this.location.model.id
      });
    }
  });

  _exports.default = _default;
});