define("lh4/services/location", ["exports", "@datadog/browser-rum", "lh4/enums/timezone", "lh4/enums/country-code", "moment", "lh4/enums/account-status", "lh4/enums/products", "lh4/constants/launch-control/constants"], function (_exports, _browserRum, _timezone, _countryCode, _moment, _accountStatus, _products, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /* Selected Location */
    model: null,
    sessionStorage: Ember.inject.service(),
    hasInternetPaymentProduct: false,
    hasMultipleLocations: false,
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    metrics: Ember.inject.service(),
    hardware: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    locale: Ember.inject.service(),
    can: Ember.inject.service(),
    userPreferenceTimezone: null,
    workflow: Ember.inject.service(),
    loadLocation: function loadLocation(id) {
      var _this = this;

      if (!Number.isInteger(parseInt(id, 10))) {
        throw new Error('locationId required');
      }

      this.set('isLoading', true);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _this.store.findRecord('location', id).then(function (record) {
          return _this._afterLocationLoaded(record, resolve);
        }).catch(reject);
      });
    },

    get isUS() {
      return this.model.countryCode === _countryCode.default.US;
    },

    get isCA() {
      return this.model.countryCode === _countryCode.default.CA;
    },

    // Temporary method to hide certain menu items for Canada location
    get isTemporarilyHiddenForCA() {
      return this.isCA;
    },

    get usesShift4PaymentProcessor() {
      return this.model.usesShift4PaymentProcessor;
    },

    _afterLocationLoaded: function _afterLocationLoaded(record, resolve) {
      var _this2 = this;

      var loadDependencies = this.launchDarkly.setFeatureFlags(record).then(function () {
        return _this2.workflow.loadWorkflows(record === null || record === void 0 ? void 0 : record.id);
      });
      return loadDependencies.then(function () {
        _this2.setProperties({
          model: record,
          isLoading: false,
          hasInternetPaymentProduct: record.product === _products.default.INTERNET_PAYMENTS
        }); // Use ember simple auth to store locationId alongside session data
        // Will be stored in either localStorage or cookies automatically
        // https://github.com/simplabs/ember-simple-auth/issues/805


        _this2.session.set('data.locationId', record === null || record === void 0 ? void 0 : record.id);

        var locationMetric = {
          merchantId: record === null || record === void 0 ? void 0 : record.merchantId,
          locationId: record === null || record === void 0 ? void 0 : record.id,
          locationProduct: record === null || record === void 0 ? void 0 : record.product,
          isSSO: _this2.session.isSSO
        };

        _this2.metrics.invoke('addContext', 'MixpanelCustom', locationMetric);

        _browserRum.datadogRum.setGlobalContextProperty('location', locationMetric);

        var userId = _this2.tokenData.get('data.sub');

        var preferences = _this2.store.peekRecord('preferences', userId) || _this2.store.findRecord('preferences', userId); // need to set timezone according to location rules if location was changed
        // setting default timezone after it was set already does no result, so need to reset it before
        // https://momentjs.com/timezone/docs/#/using-timezones/default-timezone/


        _moment.default.tz.setDefault();

        var timezone; // If the user can view the business settings, that means they cannot change the preferred time zone
        // and because of that system should use location's timezone

        if (_this2.hasInternetPaymentProduct || _this2.can.can('use eod reconciliation in business-setting')) {
          timezone = _this2.getLocationTimezone();
        } else {
          timezone = preferences.timeZone;
          _this2.userPreferenceTimezone = timezone;
        }

        _moment.default.tz.setDefault(timezone);

        _this2.hardware.loadItems();

        _this2.locale.setupIntlForLocale(timezone);

        return resolve(record);
      });
    },
    getLocationTimezone: function getLocationTimezone() {
      var _this$model$timezone;

      return (_this$model$timezone = this.model.timezone) !== null && _this$model$timezone !== void 0 ? _this$model$timezone : _timezone.default.UTC;
    },
    getUserPreferenceTimezone: function getUserPreferenceTimezone() {
      return this.userPreferenceTimezone;
    },

    get isLaunchControlActive() {
      return this.model.accountStatus != null;
    },

    get isLaunchControlAccountDeclined() {
      return this.accountStatus === _accountStatus.ACCOUNT_STATUS_CODES.DECLINED;
    },

    get accountStatus() {
      return parseInt(this.model.accountStatus, 10);
    },

    getLocationAccountStatus: function getLocationAccountStatus() {
      if (_constants.PENDING_ACCOUNT_STATUSES.includes(parseInt(this.model.accountStatus, 10))) {
        return _accountStatus.ACCOUNT_STATUS.PENDING;
      }

      if (_constants.APPROVED_ACCOUNT_STATUSES.includes(parseInt(this.model.accountStatus, 10))) {
        return _accountStatus.ACCOUNT_STATUS.APPROVED;
      }

      if (_constants.BLOCKED_ACCOUNT_STATUSES.includes(parseInt(this.model.accountStatus, 10))) {
        return _accountStatus.ACCOUNT_STATUS.BLOCKED;
      }

      return _accountStatus.ACCOUNT_STATUS.UNKNOWN;
    }
  });

  _exports.default = _default;
});