define("lh4/constants/launch-control/loyalty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LOYALTY_MODULE_NAME = void 0;
  var LOYALTY_STEP_NAMES = Object.freeze({
    PROGRAMS: 'programs',
    DISCOUNTS: 'discounts',
    PROMOTIONS: 'promotions'
  });
  var LOYALTY_MODULE_NAME = 'mod-loyalty-v1';
  _exports.LOYALTY_MODULE_NAME = LOYALTY_MODULE_NAME;
  var _default = LOYALTY_STEP_NAMES;
  _exports.default = _default;
});