define("lh4/templates/components/modal/change-location-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U2mA7AzA",
    "block": "{\"symbols\":[\"modal\",\"@location\",\"@onClose\",\"@col\",\"@onSwitch\"],\"statements\":[[5,\"ui/modal/modal-dialog\",[],[[\"@title\",\"@isOpen\",\"@onClose\"],[\"Change Location\",[28,\"if\",[[23,2,[]],true],null],[28,\"action\",[[23,0,[]],[23,3,[]]],null]]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"To view transaction you need to switch location to\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[10,\"class\",\"bold\"],[8],[1,[23,2,[\"name\"]],false],[0,\" #\"],[1,[23,2,[\"merchantId\"]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[6,[23,1,[\"actions\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"ui/buttons/button\",[],[[\"@onClick\",\"@variant\"],[[28,\"action\",[[23,0,[]],[23,3,[]]],null],\"outlined\"]],{\"statements\":[[0,\"Cancel\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\"],[5,\"ui/buttons/button\",[],[[\"@onClick\",\"@variant\"],[[28,\"action\",[[23,0,[]],[23,5,[]],[23,2,[]],[23,4,[]]],null],\"contained\"]],{\"statements\":[[0,\"Switch\"]],\"parameters\":[]}],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/modal/change-location-modal.hbs"
    }
  });

  _exports.default = _default;
});