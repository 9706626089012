define("lh4/serializers/echo-pro/item", ["exports", "lh4/enums/launch-darkly-feature-flags", "lh4/serializers/echo-pro/application"], function (_exports, _launchDarklyFeatureFlags, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _application.default.extend({
    primaryKey: 'guid',
    attrs: {
      modifierSets: {
        embedded: 'always',
        serialize: 'id'
      },
      taxes: {
        embedded: 'always',
        serialize: 'id'
      },
      printers: {
        embedded: 'always',
        serialize: 'id'
      },
      itemTags: {
        embedded: 'always',
        serialize: 'id'
      },
      itemPriceScheduleMaps: {
        embedded: 'always'
      },
      stposUiStylesheetObjectLinks: {
        embedded: 'always'
      },
      dynamicPricing: {
        embedded: 'always'
      }
    },
    launchDarkly: Ember.inject.service('launch-darkly'),
    textToSeconds: function textToSeconds() {
      var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      var _text$split = text.split(':'),
          _text$split2 = _slicedToArray(_text$split, 2),
          minutes = _text$split2[0],
          seconds = _text$split2[1];

      return parseInt(minutes || '0', 10) * 60 + parseInt(seconds || '0', 10) || 0;
    },
    secondsToText: function secondsToText(seconds) {
      if (seconds === undefined) {
        return seconds;
      }

      return "".concat(Math.floor(seconds / 60), ":").concat("00".concat(seconds % 60).slice(-2));
    },
    normalize: function normalize(modelClass, jsonToNormalize) {
      var _this = this;

      var json = jsonToNormalize;
      json.department = json.departmentGuid;
      json.itemUpc = json.lookup;
      json.prepTimeText = this.secondsToText(json.prepTimeSeconds);

      if (json.modifierSets && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.DEFAULT_MODIFIERS_ENABLED]) {
        json.modifierSetsWithDefaults = Ember.A(json.modifierSets.map(function (set) {
          return Ember.Object.create({
            guid: set.guid,
            name: set.name,
            defaultModifiersCount: set.modifiers.filter(function (m) {
              return m.isDefault;
            }).length,
            maxDefaultModifiers: !set.oneOnly ? set.maxModifiersPerSet : 0,
            modifiers: Ember.A(set.modifiers.map(function (m) {
              return Ember.Object.create({
                guid: m.guid,
                name: m.name,
                isDefault: m.isDefault,
                prepTimeText: _this.secondsToText(m.prepTimeSeconds)
              }) || [];
            }))
          });
        }));
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    },
    serialize: function serialize() {
      var _this2 = this;

      var json = this._super.apply(this, arguments); // remove extra spaces from name


      if (json && json.name) {
        var whiteSpacePattern = /\s+/g;
        json.name = json.name.replace(whiteSpacePattern, ' ');
        json.name = json.name.trim();
      }

      json.departmentGuid = json.department;
      json.priceScheduleMap = json.itemPriceScheduleMaps; // When sending priceScheduleMap, API also expects priceSchedules array with guids in them

      json.priceSchedules = json.itemPriceScheduleMaps.map(function (x) {
        return x.priceScheduleGuid;
      });
      delete json.itemPriceScheduleMaps;
      delete json.department; // prevents form validation from getting stuck when clearing field and switching to !isWeighted

      if (json.tareWeight === '') {
        json.tareWeight = null;
      } // empty value is sent as an empty string which fails the api validation


      json.lookup = json.itemUpc !== '' ? json.itemUpc : null;
      delete json.itemUpc;

      if (this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.DEFAULT_MODIFIERS_ENABLED] && json.modifierSetsWithDefaults) {
        json.modifierSets = json.modifierSetsWithDefaults.map(function (m) {
          return m.guid;
        });
        json.modifierDecorators = json.modifierSetsWithDefaults.reduce(function (acc, s) {
          acc.push.apply(acc, _toConsumableArray(s.modifiers // only saving these which has decorator changes
          .filter(function (m) {
            return m.isDefault !== undefined || m.prepTimeText !== undefined;
          }).map(function (m) {
            return {
              modifierSetGuid: s.guid,
              modifierGuid: m.guid,
              isDefault: m.isDefault,
              prepTimeSeconds: _this2.textToSeconds(m.prepTimeText)
            };
          })));
          return acc;
        }, []);
      }

      delete json.modifierSetsWithDefaults; // calculate prepTimeInSeconds based on prepTimeText in {minutes}:{seconds}

      json.prepTimeSeconds = this.textToSeconds(json.prepTimeText);
      delete json.prepTimeText;
      return json;
    }
  });

  _exports.default = _default;
});