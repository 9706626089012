define("lh4/templates/components/ui/step-container/form-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZiIZwK7G",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"stepHeader\"]]],null,{\"statements\":[[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[1,[22,\"stepHeader\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"stretched column sixteen wide\"],[8],[0,\"\\n\\t\"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/step-container/form-content.hbs"
    }
  });

  _exports.default = _default;
});