define("lh4/mirage/fixtures/internet-payments/transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TRANSACTIONS_RESPONSE_FIXTURE = {
    transactions: [{
      date: '2022-10-03 08:01:29',
      processingStatus: 'unsettled',
      transactionId: 1,
      cardType: 'visa',
      cardNumber: '1119',
      customerName: 'SDK TestName',
      customerEmail: 'sdkTest@kountsdktestdomain.com',
      customerIpAddress: '192.168.6.4',
      amount: '1234.56',
      type: 'VOID',
      riskStatus: null,
      riskId: 'EA6E18A8-B9A1-4388-9706-A18AE0F1DEDD',
      acquirerResponse: 'N7 - ',
      invoice: '0000000001',
      cardToken: 1,
      merchantReference: 'simulator:0903180059',
      merchantBatchNumber: null,
      fundingSource: null,
      transType: 'VOID',
      transbaseId: 'uniqueId1',
      id: 1
    }, {
      date: '2022-10-03 08:01:29',
      processingStatus: 'unsettled',
      transactionId: 2,
      cardType: 'visa',
      cardNumber: '1119',
      customerName: 'SDK TestName',
      customerEmail: 'sdkTest@kountsdktestdomain.com',
      customerIpAddress: '192.168.6.5',
      amount: '1234.56',
      type: 'VOID',
      riskStatus: null,
      riskId: 'EA6E18A8-B9A1-4388-9706-A18AE0F1DEDD',
      acquirerResponse: 'N7 - ',
      invoice: '0000000002',
      cardToken: 2,
      merchantReference: 'simulator:0903180059',
      merchantBatchNumber: null,
      fundingSource: null,
      transType: 'VOID',
      transbaseId: 'uniqueId2',
      id: 2,
      orderNumber: 7008,
      serverName: 'Server1',
      authorizationCode: 'OK347W'
    }, {
      date: '2022-10-03 08:01:29',
      processingStatus: 'unsettled',
      transactionId: 3,
      cardType: 'visa',
      cardNumber: '1119',
      customerName: 'SDK TestName',
      customerEmail: 'sdkTest@kountsdktestdomain.com',
      customerIpAddress: '192.168.6.6',
      amount: '1234.56',
      type: 'VOID',
      riskStatus: null,
      riskId: 'EA6E18A8-B9A1-4388-9706-A18AE0F1DEDD',
      acquirerResponse: 'N7 - ',
      invoice: '0000000003',
      cardToken: 3,
      merchantReference: 'simulator:0903180059',
      merchantBatchNumber: null,
      fundingSource: null,
      transType: 'VOID',
      id: 3,
      transbaseId: 'uniqueId3',
      orderNumber: 7009,
      serverName: 'Server2',
      authorizationCode: 'OK348W'
    }],
    count: 3
  };
  var _default = TRANSACTIONS_RESPONSE_FIXTURE;
  _exports.default = _default;
});