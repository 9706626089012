define("lh4/templates/components/internet-payments/tables/sparkline-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GiM7/g8V",
    "block": "{\"symbols\":[\"record\",\"chart\",\"@sortBy\",\"@isSortAscending\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"breakdown-card\"],[8],[0,\"\\n\\t\"],[7,\"h3\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\t\"],[5,\"internet-payments/tables/scroll-table\",[],[[\"@headers\",\"@rows\",\"@onFilterChange\",\"@sortBy\",\"@sortAsc\"],[[22,\"headers\"],[22,\"data\"],[28,\"fn\",[[24,[\"filterChange\"]]],null],[23,3,[]],[23,4,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"cardClass\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"td\",true],[8],[7,\"span\",true],[11,\"class\",[29,[\"ip-credit-card \",[23,1,[\"cardClass\"]]]]],[8],[1,[23,1,[\"cardName\"]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"td\",true],[8],[1,[23,1,[\"rowTitle\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"each\",[[24,[\"charts\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"amcharts3/sparkline-chart\",null,[[\"data\",\"options\"],[[23,1,[\"chartDataSeries\"]],[23,2,[\"chartOptions\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[23,2,[\"descriptionKey\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"span\",true],[8],[1,[28,\"get\",[[23,1,[]],[23,2,[\"descriptionKey\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"span\",true],[8],[1,[23,2,[\"description\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\t\"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/tables/sparkline-table.hbs"
    }
  });

  _exports.default = _default;
});