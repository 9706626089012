define("lh4/templates/components/internet-payments/customers/search-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GY4+Qcfh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"customers-search-summary\"],[8],[0,\"\\n\\t\"],[5,\"internet-payments/info-tile\",[],[[\"@label\",\"@value\",\"@color\",\"@dataTestId\"],[\"Customers\",[23,0,[\"customerCount\"]],[28,\"search-summary-color\",[[23,0,[\"customerCount\"]],[23,0,[\"resultsCount\"]]],null],\"search-summary-customer-count\"]]],[0,\"\\n\\t\"],[5,\"internet-payments/info-tile\",[],[[\"@label\",\"@value\",\"@color\",\"@dataTestId\"],[\"Name\",[23,0,[\"nameCount\"]],[28,\"search-summary-color\",[[23,0,[\"nameCount\"]],[23,0,[\"resultsCount\"]]],null],\"search-summary-name-count\"]]],[0,\"\\n\\t\"],[5,\"internet-payments/info-tile\",[],[[\"@label\",\"@value\",\"@color\",\"@dataTestId\"],[\"Email\",[23,0,[\"emailCount\"]],[28,\"search-summary-color\",[[23,0,[\"emailCount\"]],[23,0,[\"resultsCount\"]]],null],\"search-summary-email-count\"]]],[0,\"\\n\\t\"],[5,\"internet-payments/info-tile\",[],[[\"@label\",\"@value\",\"@color\",\"@dataTestId\"],[\"IP\",[23,0,[\"ipCount\"]],[28,\"search-summary-color\",[[23,0,[\"ipCount\"]],[23,0,[\"resultsCount\"]]],null],\"search-summary-ip-address-count\"]]],[0,\"\\n\\t\"],[5,\"internet-payments/info-tile\",[],[[\"@label\",\"@value\",\"@color\",\"@dataTestId\"],[\"Credit Cards\",[23,0,[\"creditCardCount\"]],[28,\"search-summary-color\",[[23,0,[\"creditCardCount\"]],[23,0,[\"resultsCount\"]]],null],\"search-summary-credit-card-count\"]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/customers/search-summary.hbs"
    }
  });

  _exports.default = _default;
});