define("lh4/controllers/authenticated/reporting/gift-card-reporting", ["exports", "lh4/controllers/authenticated/reporting/reporting", "moment", "lh4/enums/drill-down-report-error-codes"], function (_exports, _reporting, _moment, _drillDownReportErrorCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATE_TIME_FORMAT = 'MM/DD/YY hh:mm A';
  var DEFAULT_TIMEZONE = 'America/New_York';

  var _default = _reporting.default.extend({
    router: Ember.inject.service(),
    reportTypes: Ember.computed('model.reportTypes', function reportTypes() {
      var _this$model$reportTyp;

      return ((_this$model$reportTyp = this.model.reportTypes) === null || _this$model$reportTyp === void 0 ? void 0 : _this$model$reportTyp['gift-card']) || [];
    }),
    isSingleLocationReport: Ember.computed('selectedReport', function getter() {
      var _this$selectedReport, _this$selectedReport$;

      return ((_this$selectedReport = this.selectedReport) === null || _this$selectedReport === void 0 ? void 0 : (_this$selectedReport$ = _this$selectedReport.params) === null || _this$selectedReport$ === void 0 ? void 0 : _this$selectedReport$.isSingleLocationReport) !== false;
    }),
    reportLocationDatesWithTimezones: Ember.computed('selectedLocations', 'currentReport', function reportTypes() {
      var _this$store$peekRecor;

      var locationId = this.selectedLocations[0];
      var currentReport = this.currentReport;
      var timezone = (((_this$store$peekRecor = this.store.peekRecord('location', locationId)) === null || _this$store$peekRecor === void 0 ? void 0 : _this$store$peekRecor.timezone) || DEFAULT_TIMEZONE).toLowerCase();
      return {
        start: "".concat(_moment.default.utc(currentReport.start).utcOffset(0, true).format(DATE_TIME_FORMAT), " ").concat(_moment.default.tz(timezone).format('z')),
        end: "".concat(_moment.default.utc(currentReport.end).utcOffset(0, true).format(DATE_TIME_FORMAT), " ").concat(_moment.default.tz(timezone).format('z'))
      };
    }),
    allowedLocations: Ember.computed('permissions', function allowedLocations() {
      var _this = this;

      return this.get('permissions.reportLocations').map(function (id) {
        return _this.store.peekRecord('location', id);
      }).sortBy('name');
    }),
    reportError: Ember.computed('error', function warning() {
      var _this$error$details;

      if (!this.error) {
        return null;
      }

      switch ((_this$error$details = this.error.details) === null || _this$error$details === void 0 ? void 0 : _this$error$details.code) {
        case _drillDownReportErrorCodes.default.NODE_NOT_FOUND:
          {
            return {
              header: 'Report has expired',
              message: 'Please generate the report again.'
            };
          }

        default:
          return this.error;
      }
    })
  });

  _exports.default = _default;
});