define("lh4/templates/components/ui/tree/checkbox-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cV6ImuKP",
    "block": "{\"symbols\":[\"node\"],\"statements\":[[4,\"if\",[[24,[\"isSearchable\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"ui/search/general-search\",null,[[\"searchText\",\"placeholder\",\"debounceWait\",\"onSearch\",\"autofocus\"],[[24,[\"searchText\"]],[24,[\"searchPlaceholder\"]],200,[28,\"action\",[[23,0,[]],\"onSearch\"],null],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"includeRootNode\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"checkbox-tree-header\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"ui/checkbox-unescaped\",null,[[\"class\",\"label\",\"checked\",\"onChange\",\"disabled\"],[[24,[\"root\",\"cssClass\"]],[24,[\"root\",\"name\"]],[24,[\"root\",\"isSelected\"]],[28,\"action\",[[23,0,[]],\"selectNode\",[24,[\"root\"]]],null],[24,[\"searchText\"]]]]],false],[0,\"\\n\"],[4,\"unless\",[[24,[\"searchText\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"expand-actions\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"a\",false],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"expandAll\"]],[8],[0,\"Expand All\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"a\",false],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"collapseAll\"]],[8],[0,\"Collapse All\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"ul\",true],[10,\"class\",\"tree-nodes\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"nodes\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"ui/tree/checkbox-node\",null,[[\"node\",\"levelShift\",\"onCheck\",\"onToggleIsExpanded\"],[[23,1,[]],[24,[\"levelShift\"]],[28,\"action\",[[23,0,[]],\"selectNode\",[23,1,[]]],null],[28,\"action\",[[23,0,[]],\"toggleIsExpanded\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/tree/checkbox-tree.hbs"
    }
  });

  _exports.default = _default;
});