define("lh4/templates/components/internet-payments/disputes/case-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GllDUFXg",
    "block": "{\"symbols\":[\"@onBeforeHide\",\"@onBeforeShow\",\"@onReloadAllTabs\"],\"statements\":[[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"internet-payments/spinners/loader-circle\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[5,\"internet-payments/ui/page-top-nav\",[],[[\"@titleName\",\"@handleBackClick\"],[[28,\"concat\",[\"Case #\",[23,0,[\"args\",\"dispute\",\"caseNumber\"]]],null],[28,\"action\",[[23,0,[]],\"returnToList\"],null]]]],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"case-details\"],[8],[0,\"\\n\\t\\t\"],[5,\"internet-payments/disputes/case/case-summary-panel\",[],[[\"@dispute\",\"@disputeStatus\"],[[23,0,[\"args\",\"dispute\"]],[23,0,[\"disputeStatus\"]]]]],[0,\"\\n\\t\\t\"],[5,\"internet-payments/disputes/case/case-dispute-panel\",[],[[\"@onBeforeHide\",\"@onBeforeShow\",\"@dispute\",\"@acceptDispute\",\"@disputeStatus\",\"@isAccepted\",\"@onReloadAllTabs\",\"@setIsLoading\"],[[23,1,[]],[23,2,[]],[23,0,[\"args\",\"dispute\"]],[23,0,[\"acceptDispute\"]],[23,0,[\"disputeStatus\"]],[23,0,[\"isAccepted\"]],[23,3,[]],[22,\"setIsLoading\"]]]],[0,\"\\n\\t\\t\"],[5,\"internet-payments/disputes/case/case-details-panel\",[],[[\"@dispute\"],[[23,0,[\"args\",\"dispute\"]]]]],[0,\"\\n\\t\\t\"],[5,\"internet-payments/ui/event-list\",[],[[\"@listType\",\"@eventHistory\"],[\"dispute\",[23,0,[\"args\",\"dispute\",\"eventHistory\"]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"errorModel\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"error-modal\",null,[[\"model\"],[[24,[\"errorModel\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/disputes/case-panel.hbs"
    }
  });

  _exports.default = _default;
});