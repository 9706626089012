define("lh4/templates/authenticated/launch-control/progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T5EnASqA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"progress\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"progressPercentage\"]],100],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",\"title\"],[3,\"did-insert\",[[23,0,[\"shootConfetti\"]]]],[8],[0,\"\\n\\t\\t\\t\\tAll Done!\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\tYour account is all set up and ready to go.\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",\"title\"],[3,\"did-insert\",[[23,0,[\"shootConfetti\"]]]],[8],[0,\"\\n\\t\\t\\t\\tGreat Job!\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\tWe are a few steps away from setting up the basics.\\n\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\"],[5,\"ui/s4/loaders/s4-progress\",[],[[\"@progressPercentage\",\"@progressOffset\"],[[24,[\"model\",\"progressPercentage\"]],[24,[\"model\",\"progressOffset\"]]]]],[0,\"\\n\\n\\t\\t\"],[5,\"ui/s4/buttons/button\",[],[[\"@type\",\"@text\",\"@onClick\"],[\"contained\",\"DONE\",[23,0,[\"exitProgress\"]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/launch-control/progress.hbs"
    }
  });

  _exports.default = _default;
});