define("lh4/mirage/routes/internet-payments/transactions", ["exports", "lh4/mirage/fixtures/internet-payments/transactions"], function (_exports, _transactions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsTransactionsServer;

  function setMirageInternetPaymentsTransactionsServer(server) {
    server.get('/internet-payments/transactions', function () {
      return _transactions.default;
    });
    server.get('/internet-payments/transactions/:transbaseId/detail', function () {
      return {
        transaction: {
          transbaseId: 'uniqueId1',
          id: 1,
          date: '2022-10-03 08:01:29',
          transactionId: 1,
          invoice: '0000000001',
          merchantReference: 'simulator:0903180059',
          riskStatus: null,
          processingStatus: 'unsettled',
          acquirerResponse: 'N7 - ',
          customerName: 'SDK TestName',
          cardType: 'visa',
          cardNumber: '1119',
          customerEmail: 'approve@test.com',
          amount: '1234.56',
          type: 'VOID',
          transType: 'Sale',
          deviceId: 'deviceId1234.decline',
          customerIpAddress: '192.168.6.1'
        }
      };
    });
    server.get('/internet-payments/transactions/related-transactions/data', function () {
      return {
        count: 1,
        transactions: [{
          transbaseId: 'uniqueId2',
          invoice: '0000000002',
          date: '2023-06-08 23:45:12',
          amount: 120,
          id: 4,
          cardNumber: '2011',
          cardType: 'mastercard',
          cardToken: 'token',
          customerName: 'Very good name',
          transactionId: '0003765456',
          processingStatus: 'settled',
          riskStatus: null,
          acquirerResponse: '00 - Approval'
        }]
      };
    });
    server.delete('/internet-payments/users/configurable-columns/:id', {});
    server.get('/internet-payments/users/configurable-columns', function (schema, request) {
      var resourceViewPerspective = request.queryParams.resourceViewPerspective;
      var columnConfigurations = [{
        createdAt: '2023-08-22T08:47:34.251Z',
        updatedAt: '2023-08-22T08:47:34.251Z',
        guid: '79f14bbd-0f48-44ac-9180-dba553257b25',
        viewName: 'Template',
        resourceViewPerspective: 'TRANSACTION-MASTER-DEFAULT',
        userId: 812,
        locationId: 1,
        product: 'internet-payments',
        isFavorite: false,
        lbmColumnOptions: [{
          sortable: true,
          optionId: 'DATE',
          visible: true,
          name: 'Date',
          field: 'date'
        }, {
          sortable: true,
          optionId: 'ORDER_STATUS',
          visible: true,
          name: 'Order Status',
          field: 'processingStatus'
        }, {
          sortable: true,
          optionId: 'TRANSACTION_ID',
          visible: true,
          name: 'Transaction ID',
          field: 'transactionId'
        }, {
          sortable: true,
          optionId: 'CREDIT_CARD',
          visible: true,
          name: 'Credit Card',
          field: 'cardNumber'
        }, {
          sortable: true,
          optionId: 'CUSTOMER_NAME',
          visible: true,
          name: 'Customer',
          field: 'customerName'
        }, {
          sortable: false,
          optionId: 'CUSTOMER_EMAIL',
          visible: true,
          name: 'Email',
          field: 'customerEmail'
        }, {
          sortable: false,
          optionId: 'CUSTOMER_IP',
          visible: false,
          name: 'Customer IP Address',
          field: 'customerIpAddress'
        }, {
          sortable: true,
          optionId: 'AMOUNT',
          visible: true,
          name: 'Amount',
          field: 'amount'
        }, {
          sortable: true,
          optionId: 'TRANSACTION_TYPE',
          visible: true,
          name: 'Type',
          field: 'transType'
        }, {
          sortable: false,
          optionId: 'RISK_STATUS',
          visible: false,
          name: 'Risk',
          field: 'riskStatus'
        }, {
          sortable: false,
          optionId: 'RISK_ID',
          visible: false,
          name: 'Risk ID',
          field: 'riskId'
        }, {
          sortable: true,
          optionId: 'ACQUIRER_RESPONSE',
          visible: false,
          name: 'Acquirer Response',
          field: 'acquirerResponse'
        }, {
          sortable: false,
          optionId: 'CARD_TOKEN',
          visible: false,
          name: 'Card Token',
          field: 'cardToken'
        }, {
          sortable: false,
          optionId: 'MERCHANT_REFERENCE',
          visible: false,
          name: 'Merchant Reference',
          field: 'merchantReference'
        }, {
          sortable: false,
          optionId: 'ISSUER_BIN',
          visible: true,
          name: 'Issuer BIN',
          field: 'issuerBIN'
        }, {
          sortable: false,
          optionId: 'RISK_SCORE',
          visible: false,
          name: 'Risk Score',
          field: 'weightedRiskTotal'
        }, {
          sortable: false,
          optionId: 'SECURE_STATUS',
          visible: false,
          name: 'Secure Status',
          field: 'secureStatus'
        }]
      }, {
        guid: 'default',
        viewName: 'Default',
        locationId: 1,
        userId: 812,
        isFavorite: true,
        resourceViewPerspective: 'TRANSACTION-MASTER-DEFAULT',
        product: 'internet-payments',
        lbmColumnOptions: [{
          sortable: true,
          optionId: 'DATE',
          visible: true,
          name: 'Date',
          field: 'date'
        }, {
          sortable: false,
          optionId: 'MERCHANT_REFERENCE',
          visible: true,
          name: 'Merchant Reference',
          field: 'merchantReference'
        }, {
          sortable: false,
          optionId: 'ISSUER_BIN',
          visible: true,
          name: 'Issuer BIN',
          field: 'issuerBIN'
        }, {
          sortable: false,
          optionId: 'RISK_STATUS',
          visible: true,
          name: 'Risk',
          field: 'riskStatus'
        }, {
          sortable: true,
          optionId: 'ACQUIRER_RESPONSE',
          visible: true,
          name: 'Acquirer Response',
          field: 'acquirerResponse'
        }, {
          sortable: true,
          optionId: 'CUSTOMER_NAME',
          visible: true,
          name: 'Customer',
          field: 'customerName'
        }, {
          sortable: false,
          optionId: 'CUSTOMER_EMAIL',
          visible: true,
          name: 'Email',
          field: 'customerEmail'
        }, {
          sortable: true,
          optionId: 'CREDIT_CARD',
          visible: true,
          name: 'Credit Card',
          field: 'cardNumber'
        }, {
          sortable: true,
          optionId: 'AMOUNT',
          visible: true,
          name: 'Amount',
          field: 'amount'
        }, {
          sortable: true,
          optionId: 'TRANSACTION_TYPE',
          visible: true,
          name: 'Type',
          field: 'transType'
        }, {
          sortable: true,
          optionId: 'ORDER_STATUS',
          visible: true,
          name: 'Order Status',
          field: 'processingStatus'
        }, {
          sortable: true,
          optionId: 'TRANSACTION_ID',
          visible: true,
          name: 'Transaction ID',
          field: 'transactionId'
        }, {
          sortable: false,
          optionId: 'CUSTOMER_IP',
          visible: false,
          name: 'Customer IP Address',
          field: 'customerIpAddress'
        }, {
          sortable: false,
          optionId: 'RISK_ID',
          visible: false,
          name: 'Risk ID',
          field: 'riskId'
        }, {
          sortable: false,
          optionId: 'CARD_TOKEN',
          visible: false,
          name: 'Card Token',
          field: 'cardToken'
        }, {
          sortable: false,
          optionId: 'RISK_SCORE',
          visible: true,
          name: 'Risk Score',
          field: 'weightedRiskTotal'
        }, {
          sortable: false,
          optionId: 'SECURE_STATUS',
          visible: false,
          name: 'Secure Status',
          field: 'secureStatus'
        }]
      }, {
        guid: 'default',
        viewName: 'Default',
        locationId: 3344,
        userId: 5,
        isFavorite: true,
        resourceViewPerspective: 'TRANSACTION-MASTER-EW',
        product: 'internet-payments',
        lbmColumnOptions: [{
          sortable: true,
          optionId: 'DATE',
          visible: true,
          isVisible: true,
          name: 'Date',
          field: 'date'
        }, {
          sortable: true,
          optionId: 'TRANSACTION_ID',
          visible: true,
          isVisible: true,
          name: 'Transaction ID',
          field: 'transactionId'
        }, {
          sortable: true,
          optionId: 'AMOUNT',
          visible: true,
          isVisible: true,
          name: 'Amount',
          field: 'amount'
        }, {
          sortable: false,
          optionId: 'CUSTOMER_EMAIL',
          visible: true,
          isVisible: true,
          name: 'Email',
          field: 'customerEmail'
        }, {
          sortable: true,
          optionId: 'CUSTOMER_NAME',
          visible: true,
          isVisible: true,
          name: 'Customer',
          field: 'customerName'
        }, {
          sortable: true,
          optionId: 'CREDIT_CARD',
          visible: true,
          isVisible: true,
          name: 'Credit Card',
          field: 'cardNumber'
        }, {
          sortable: true,
          optionId: 'ORDER_STATUS',
          visible: true,
          isVisible: true,
          name: 'Order Status',
          field: 'processingStatus'
        }, {
          sortable: true,
          optionId: 'EVENT_STATUS',
          visible: true,
          isVisible: true,
          name: 'Event Status',
          field: 'eventStatus'
        }, {
          sortable: true,
          optionId: 'TRANSACTION_TYPE',
          visible: true,
          isVisible: true,
          name: 'Type',
          field: 'transType'
        }, {
          sortable: true,
          optionId: 'ACQUIRER_RESPONSE',
          visible: true,
          isVisible: true,
          name: 'Acquirer Response',
          field: 'acquirerResponse'
        }, {
          sortable: false,
          optionId: 'CUSTOMER_IP',
          visible: false,
          isVisible: false,
          name: 'Customer IP Address',
          field: 'customerIpAddress'
        }, {
          sortable: false,
          optionId: 'CARD_TOKEN',
          visible: false,
          isVisible: false,
          name: 'Card Token',
          field: 'cardToken'
        }, {
          sortable: false,
          optionId: 'SECURE_STATUS',
          visible: false,
          isVisible: false,
          name: 'Secure Status',
          field: 'secureStatus'
        }, {
          sortable: false,
          optionId: 'ORDER_NUMBER',
          visible: true,
          isVisible: false,
          name: 'Order',
          field: 'orderNumber'
        }, {
          sortable: false,
          optionId: 'AUTHORIZATION_CODE',
          visible: true,
          isVisible: false,
          name: 'Authorization Code',
          field: 'authorizationCode'
        }, {
          sortable: true,
          optionId: 'SERVER_NAME',
          visible: true,
          isVisible: false,
          name: 'Server',
          field: 'serverName'
        }]
      }];
      return [columnConfigurations.filter(function (rvp) {
        return rvp.resourceViewPerspective === resourceViewPerspective;
      })];
    });
    server.put('/internet-payments/users/configurable-columns', function (schema, request) {
      var requestBody = JSON.parse(request.requestBody);
      return {
        columnConfiguration: {
          createdAt: '2023-08-23T09:25:22.099Z',
          updatedAt: '2023-08-23T09:25:22.099Z',
          guid: 'c74b3a9b-7cc8-49fb-8934-5ee27f2cc71c',
          viewName: requestBody.viewName,
          resourceViewPerspective: requestBody.resourceViewPerspective,
          lbmColumnOptions: requestBody.columnOptions,
          product: 'internet-payments',
          locationId: requestBody.locationId,
          isFavorite: true
        }
      };
    });
    server.put('/internet-payments/users/configurable-columns/favorite/:uuid', function (schema, request) {
      var requestBody = JSON.parse(request.requestBody);
      return {
        columnConfiguration: {
          createdAt: '2023-08-23T09:25:22.099Z',
          updatedAt: '2023-08-23T09:25:22.099Z',
          guid: 'c74b3a9b-7cc8-49fb-8934-5ee27f2cc71c',
          viewName: 'Template',
          resourceViewPerspective: 'TRANSACTION-MASTER-DEFAULT',
          lbmColumnOptions: [{
            optionId: 'DATE',
            visible: true
          }, {
            optionId: 'ORDER_STATUS',
            visible: true
          }, {
            optionId: 'TRANSACTION_ID',
            visible: true
          }, {
            optionId: 'CREDIT_CARD',
            visible: true
          }, {
            optionId: 'CUSTOMER_NAME',
            visible: true
          }, {
            optionId: 'CUSTOMER_EMAIL',
            visible: true
          }, {
            optionId: 'CUSTOMER_IP',
            visible: false
          }, {
            optionId: 'AMOUNT',
            visible: true
          }, {
            optionId: 'TRANSACTION_TYPE',
            visible: true
          }, {
            optionId: 'RISK_STATUS',
            visible: true
          }, {
            optionId: 'RISK_ID',
            visible: false
          }, {
            optionId: 'ACQUIRER_RESPONSE',
            visible: true
          }, {
            optionId: 'CARD_TOKEN',
            visible: false
          }, {
            optionId: 'MERCHANT_REFERENCE',
            visible: true
          }, {
            optionId: 'RISK_SCORE',
            visible: true
          }, {
            optionId: 'SECURE_STATUS',
            visible: false
          }],
          product: 'internet-payments',
          locationId: 1698,
          userId: 5,
          isFavorite: requestBody.isFavorite
        }
      };
    });
  }
});