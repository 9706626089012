define("lh4/enums/ew-pos-report-service", ["exports", "lh4/enums/products", "lh4/enums/report-type-slug"], function (_exports, _products, _reportTypeSlug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EW_POS_REPORTS = ["".concat(_products.default.EW, "-").concat(_reportTypeSlug.default.TICKET_TRANSACTION_STATUS)];
  var _default = EW_POS_REPORTS;
  _exports.default = _default;
});