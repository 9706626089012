define("lh4/models/echo-pro/dynamic-price", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    scheduleTitle: {
      description: 'Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 60,
        description: 'Group Name'
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    scheduleTitle: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    timeOption: (0, _attr.default)('string'),
    revenueCenterGuids: (0, _attr.default)(),
    intradayPeriodGroupGuids: (0, _attr.default)(),
    overrideDates: (0, _attr.default)(),
    schedules: (0, _relationships.hasMany)('echo-pro/availability-price/schedule')
  });

  _exports.default = _default;
});