define("lh4/templates/components/internet-payments/ui/dropdown/dropdown-grouped", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+de9EGPc",
    "block": "{\"symbols\":[\"@defaultText\",\"@label\",\"&default\",\"@onChange\",\"@selected\",\"@className\"],\"statements\":[[4,\"ui-dropdown\",null,[[\"class\",\"selected\",\"onChange\"],[[28,\"concat\",[\"grouped \",[28,\"if\",[[23,6,[]],[23,6,[]]],null]],null],[23,5,[]],[23,4,[]]]],{\"statements\":[[0,\"\\t\"],[7,\"label\",true],[11,\"class\",[29,[\"label\",[28,\"if\",[[23,1,[]],\" active-text\"],null]]]],[8],[1,[23,2,[]],false],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"default text\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"chevron mid icon down\"],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\\t\\t\"],[14,3,[[28,\"hash\",null,[[\"ListContainer\"],[[28,\"component\",[\"internet-payments/ui/dropdown/shared/dropdown-list-container\"],null]]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/dropdown/dropdown-grouped.hbs"
    }
  });

  _exports.default = _default;
});