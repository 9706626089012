define("lh4/mirage/routes/corporate/location-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageCorporateLocationOverviewServer;

  function setMirageCorporateLocationOverviewServer(server) {
    server.get('/corporations/:corpId/location-overview', function () {
      return [{
        corpId: 1,
        hasStatusOverrides: false,
        id: 1,
        locationId: 1,
        isLocationManaged: false,
        isUpToDate: true,
        locationSettings: [{
          name: 'allow_active_toggle_overrides',
          value: '0'
        }, {
          name: 'allow_price_overrides',
          value: '1'
        }],
        name: 'Test Location',
        priceGroupName: 'Test group 123'
      }, {
        corpId: 1,
        hasStatusOverrides: false,
        id: 2,
        locationId: 2,
        isLocationManaged: false,
        isUpToDate: true,
        locationSettings: [{
          name: 'allow_active_toggle_overrides',
          value: '0'
        }, {
          name: 'allow_price_overrides',
          value: '1'
        }],
        name: 'Test Location 2',
        priceGroupName: 'Test group 123'
      }, {
        corpId: 1,
        hasStatusOverrides: false,
        id: 3,
        locationId: 3,
        isLocationManaged: false,
        isUpToDate: false,
        locationSettings: [{
          name: 'allow_active_toggle_overrides',
          value: '0'
        }, {
          name: 'allow_price_overrides',
          value: '1'
        }],
        name: 'Test Location 3',
        priceGroupName: 'Test group 123'
      }];
    });
  }
});