define("lh4/components/epro/timeclock/labor-settings-form", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    location: Ember.inject.service(),
    ajax: Ember.inject.service(),
    permissions: Ember.inject.service(),
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid', 'labor-settings'],
    startOfWeekOptions: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    payPeriodOptions: ['Weekly', 'Biweekly', 'Monthly'],
    breakOptions: ['Always Paid', 'Never Paid'],
    autoClockOutOptions: ['Yes', 'No'],
    overtimeRules: [{
      value: 'Weekly',
      name: 'Weekly'
    }, {
      value: 'California',
      name: 'California - Rate in effect'
    }],
    showErrors: true,
    errorMessage: null,
    isSaving: false,
    workforceCancellationMessage: null,
    isWorkforceCancelled: Ember.computed('location', function isWorkforceCancelled() {
      return this.isWorkforceSubscriptionCancelled();
    }),
    isWorkforceSubscriptionCancelled: function isWorkforceSubscriptionCancelled() {
      var workForceService = this.location.model.services.find(function (s) {
        return s.name === 'social-schedules';
      });

      if (workForceService) {
        var _workForceService$loc = workForceService.locationService,
            isActive = _workForceService$loc.isActive,
            configuration = _workForceService$loc.configuration;
        var subscriptionStates = configuration.subscriptionStates,
            cancelDate = configuration.cancelDate;

        if (subscriptionStates !== null && subscriptionStates !== void 0 && subscriptionStates.length) {
          var status = subscriptionStates[subscriptionStates.length - 1].status;

          if (isActive && status === 'Cancelling') {
            var date = (0, _moment.default)(cancelDate).format('Do MMM YYYY');
            this.set('workforceCancellationMessage', "Workforce subscription will be cancelled on ".concat(date));
            return true;
          }
        }
      }

      return false;
    },
    actions: {
      overtimeRuleSelected: function overtimeRuleSelected(value) {
        this.changeset.set('overtimeCalculation', value);

        if (value === 'California') {
          this.changeset.setProperties({
            firstDailyOvertimeThreshold: '8',
            secondDailyOvertimeThreshold: '12',
            firstWeeklyOvertimeThreshold: '40',
            secondWeeklyOvertimeThreshold: '',
            firstOvertimeMultiplier: '1.5',
            secondOvertimeMultiplier: '2.0'
          });
        } else {
          this.changeset.setProperties({
            firstDailyOvertimeThreshold: '0',
            secondDailyOvertimeThreshold: '',
            firstWeeklyOvertimeThreshold: '40',
            secondWeeklyOvertimeThreshold: '',
            firstOvertimeMultiplier: '1.5',
            secondOvertimeMultiplier: ''
          });
        }
      },
      unsubscribe: function unsubscribe(category, reason, otherText) {
        var _this = this;

        if (this.isSaving || !this.permissions.isOwner) {
          return;
        }

        if (window.confirm('Are you sure you want to cancel subscription?')) {
          this.set('isSaving', true);
          var data = JSON.stringify({
            reason: {
              category: category,
              reason: reason.includes('Other') ? "".concat(reason, ": ").concat(otherText) : reason
            }
          });
          this.ajax.post("/api/v1/s4-workforce/app/cancel/".concat(this.location.model.id), {
            data: data
          }).then(function () {
            _this.permissions.refresh().then(function () {
              return _this.set('isWorkforceCancelled', _this.isWorkforceSubscriptionCancelled());
            });
          }).catch(function () {
            _this.set('errorMessage', 'There was an unexpected error canceling subscription');
          }).finally(function () {
            _this.set('isSaving', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});