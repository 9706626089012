define("lh4/templates/components/dashboard-widgets/ip-notifications/types/suspect-transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vbLd77gP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"ip-notifications-type-suspect-transactions\"],[10,\"class\",\"row suspect-transaction\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"header-container\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[8],[7,\"i\",true],[10,\"class\",\"exclamation circle icon\"],[8],[9],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"value\"],[8],[7,\"b\",true],[8],[0,\"Suspect transactions\"],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"value ip-notification-suspect-transactions-value\"],[8],[0,\"\\n\\t\\tThere are \"],[1,[24,[\"data\",\"count\"]],false],[0,\" declined risk transactions today.\\n\\t\"],[9],[0,\"\\n\\t\"],[5,\"link-to\",[[12,\"class\",\"ip-notification-link\"]],[[\"@route\",\"@query\"],[[23,0,[\"linkProperties\",\"path\"]],[28,\"hash\",null,[[\"start\",\"end\",\"riskStatus\"],[[23,0,[\"linkProperties\",\"dateQueryParams\",\"start\"]],[23,0,[\"linkProperties\",\"dateQueryParams\",\"end\"]],[23,0,[\"linkProperties\",\"riskStatusQueryParams\"]]]]]]],{\"statements\":[[0,\"\\n\\t\\tView All\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/ip-notifications/types/suspect-transactions.hbs"
    }
  });

  _exports.default = _default;
});