define("lh4/constants/launch-control/employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EMPLOYEES_MODULE_NAME = _exports.EMPLOYEES_STEP_NAMES = void 0;
  var EMPLOYEES_STEP_NAMES = Object.freeze({
    JOBS: 'jobs',
    EMPLOYEES: 'employees'
  });
  _exports.EMPLOYEES_STEP_NAMES = EMPLOYEES_STEP_NAMES;
  var EMPLOYEES_MODULE_NAME = 'mod-employees-v1';
  _exports.EMPLOYEES_MODULE_NAME = EMPLOYEES_MODULE_NAME;
  var _default = EMPLOYEES_STEP_NAMES;
  _exports.default = _default;
});