define("lh4/abilities/feature-flag", ["exports", "ember-can", "lh4/enums/launch-darkly-feature-flags", "lh4/mixins/abilities/ability-mixin"], function (_exports, _emberCan, _launchDarklyFeatureFlags, _abilityMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service(),
    canViewIpApiKeys: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_DEVELOPER_API_KEYS_VISIBILITY];
    }),
    canViewIpDeveloperSettings: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.get('canViewIpApiKeys') || this.get('canViewIpWebhooks');
    }),
    canViewIpWebhooks: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_DEVELOPER_WEBHOOKS_VISIBILITY];
    }),
    canViewIpWebhookCreate: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.get('canViewIpWebhooks') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_DEVELOPER_WEBHOOK_CREATE_VISIBILITY];
    }),
    canViewIpWebhookDetails: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.get('canViewIpWebhooks') && (this.get('canViewIpWebhookDetailCards') || this.get('canViewIpWebhookEvents') || this.get('canViewIpWebhookSettings'));
    }),
    canViewIpWebhookDetailCards: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_DEVELOPER_WEBHOOK_DETAIL_CARDS_VISIBILITY];
    }),
    canViewIpWebhookEvents: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_DEVELOPER_WEBHOOK_EVENTS_VISIBILITY];
    }),
    canViewIpWebhookEventDetails: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.get('canViewIpWebhookEvents') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_DEVELOPER_WEBHOOK_EVENT_DETAILS_VISIBILITY];
    }),
    canViewIpWebhookSettings: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_DEVELOPER_WEBHOOK_SETTINGS_VISIBILITY];
    }),
    canViewWebhooksPayoutProcessingEvents: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.WEBHOOKS_PAYOUT_PROCESSING_EVENTS_VISIBILITY];
    }),
    canViewIpTransactionDetailsV2: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_TRANSACTION_DETAILS_V2];
    }),
    canViewIpTransactionStatusChargeback: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_TRANSACTIONS_STATUS_CHARGEBACK];
    }),
    canViewIpTransactionsEventStatusDropdown: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_TRANSACTIONS_EVENT_STATUS_DROPDOWN];
    }),
    canViewIpTransactionsInsightsRisk: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_TRANSACTIONS_INSIGHTS_RISK_VISIBILITY];
    }),
    canViewIpTransactionsAcquirerResponseDropdown: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_TRANSACTIONS_ACQUIRER_RESPONSE_DROPDOWN];
    }),
    canViewTransactionsServerDropdown: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_FNB_SERVER_NAME_FILTER];
    }),
    canViewMenuColorPicker: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.EPRO_COLOR_PICKER];
    }),
    canViewIpWebhookEventTypeFiltering: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_WEBHOOK_EVENTS_FILTERING_BY_EVENT_TYPE];
    }),
    canViewIpWebhookResendAllButton: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_WEBHOOK_EVENTS_RESEND_ALL_VISIBILITY];
    }),
    canViewTransactionsStatusFailed: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.TRANSACTIONS_STATUS_FAILED];
    }),
    canViewTransactionsTableEventStatus: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.TRANSACTIONS_TABLE_EVENT_STATUS];
    }),
    canViewTransactionsFilterTrayRedesign: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.TRANSACTIONS_FILTER_TRAY_REDESIGN];
    }),
    canViewTransactionFilterTemplates: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_TRANSACTION_FILTER_TEMPLATES];
    }),
    canViewTransactionDataAdditions: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_TRANSACTION_DATA_ADDITIONS];
    }),
    canViewGiftCardEventStatuses: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_GIFT_CARD_EVENT_STATUSES];
    }),
    canViewIpWebhookEventsResentUpdates: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_WEBHOOK_EVENTS_RESENT_UPDATES];
    }),
    canViewTransactionsAdvancedSearch: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.VIEW_TRANSACTION_ADVANCED_SEARCH];
    }),
    canSeeTransactionsLimitedAccessMessage: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SHOW_TRANSACTIONS_LIMITED_ACCESS_MESSAGE];
    }),
    canViewTransactionAdjustments: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_TRANSACTION_ADJUSTMENTS];
    })
  });

  _exports.default = _default;
});