define("lh4/templates/authenticated/internet-payments/developers/webhooks/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PpoNAw0u",
    "block": "{\"symbols\":[],\"statements\":[[5,\"internet-payments/ui/page-top-nav\",[],[[\"@titleName\",\"@handleBackClick\"],[[29,[\"Webhook: \",[23,0,[\"model\",\"webhook\",\"name\"]]]],[28,\"action\",[[23,0,[]],\"close\"],null]]]],[0,\"\\n\"],[4,\"if\",[[28,\"can\",[\"view ip webhook detail cards in feature-flag\"],null]],null,{\"statements\":[[0,\"\\t\"],[5,\"internet-payments/developers/webhooks/details/webhook-cards\",[],[[\"@webhook\"],[[23,0,[\"model\",\"webhook\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"or\",[[28,\"can\",[\"view ip webhook events in feature-flag\"],null],[28,\"can\",[\"view ip webhook settings in feature-flag\"],null]],null]],null,{\"statements\":[[0,\"\\t\"],[5,\"ui/tab-elements\",[[12,\"class\",\"standard\"]],[[\"@routes\"],[[23,0,[\"routeData\"]]]]],[0,\"\\n\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/developers/webhooks/details.hbs"
    }
  });

  _exports.default = _default;
});