define("lh4/mirage/factories/launch-control/module", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    createdAt: '2024-04-19T12:31:40.348Z',
    updatedAt: '2024-04-19T14:08:57.687Z',
    moduleId: function moduleId() {
      return _faker.default.datatype.uuid();
    },
    workflowId: '',
    moduleName: 'mod-installation-details-v1',
    completedAt: null,
    stepData: {},
    isSkipped: false
  });

  _exports.default = _default;
});