define("lh4/components/echo-pro/timeclock/edit-shift", ["exports", "lh4/enums/duration", "moment"], function (_exports, _duration, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    clockOut: '',
    closeShift: '',
    save: '',
    timezone: Ember.computed('shift.locationId', function getter() {
      return this.store.peekRecord('location', this.shift.locationId).timezone || _moment.default.tz.guess();
    }),
    showReportingWarning: Ember.computed('shift.totalComputed', 'shift.standardWorkTimeTotalSeconds', 'shift.standardOvertimeTotalSeconds', 'shift.doubleOvertimeTotalSeconds', function getter() {
      var clockedIn = (0, _moment.default)(this.shift.clockedInAt).set({
        seconds: 0,
        milliseconds: 0
      });
      var clockedOut = (0, _moment.default)(this.shift.clockedOutAt).set({
        seconds: 0,
        milliseconds: 0
      }); // showReportingWarning may conflict with a validator (app/validators/shift-dates.js)
      // that checks that clockIn is before clockOut.
      // If clockIn is NOT before clockOut, then let's have showReportingWarning return false
      // and let the validator handle this specific case

      if (!clockedIn.isBefore(clockedOut)) {
        return false;
      }

      var breakDuration = this.shift.breakTotalSeconds;

      var total = _moment.default.duration();

      if (this.shift.clockedOutAt) {
        total = _moment.default.duration(clockedOut.diff(clockedIn));

        if (!this.shift.breakIsPaid) {
          total = total.subtract(breakDuration, 'seconds');
        }
      }

      var _this$shift = this.shift,
          standardWorkTimeTotalSeconds = _this$shift.standardWorkTimeTotalSeconds,
          standardOvertimeTotalSeconds = _this$shift.standardOvertimeTotalSeconds,
          doubleOvertimeTotalSeconds = _this$shift.doubleOvertimeTotalSeconds;
      var sumWorkTime = standardWorkTimeTotalSeconds + standardOvertimeTotalSeconds + doubleOvertimeTotalSeconds;
      var secondsDiff = sumWorkTime - total.asSeconds(); // This is a little confusing, but because we sometimes
      // truncate seconds this diff needs to have a minute's
      // worth of error built in, hence the [0, 60) range.

      return !(secondsDiff >= 0 && secondsDiff < _duration.default.MINUTE.IN_SECONDS);
    }),
    clockedInOutObserver: Ember.observer('shift.clockedInAt', 'shift.clockedOutAt', function callback() {
      var _this$shift2 = this.shift,
          standardWorkTimeTotalSeconds = _this$shift2.standardWorkTimeTotalSeconds,
          standardOvertimeTotalSeconds = _this$shift2.standardOvertimeTotalSeconds,
          doubleOvertimeTotalSeconds = _this$shift2.doubleOvertimeTotalSeconds;

      if (standardWorkTimeTotalSeconds) {
        this.set('shift.standardWorkTimeTotalSeconds', 0);
      }

      if (standardOvertimeTotalSeconds) {
        this.set('shift.standardOvertimeTotalSeconds', 0);
      }

      if (doubleOvertimeTotalSeconds) {
        this.set('shift.doubleOvertimeTotalSeconds', 0);
      }
    }),
    activeJobs: Ember.computed.filterBy('shift.employeeGuid.jobs', 'isActive', true),
    disableIsPaid: Ember.computed('shift.breakHours', 'shift.breakMinutes', function getter() {
      var hours = this.get('shift.breakHours');
      var minutes = this.get('shift.breakMinutes');
      return hours <= 0 && minutes <= 0;
    }),
    actions: {
      shiftDateSelect: function shiftDateSelect(key, value) {
        var currentDate = this.get("shift.".concat(key, "At")); // because ember-pikaday component returns selected value in browser timezone
        // but we want to do everything in location timezone
        // so constructing new date by picking up just date part of value.
        // If location timezone is null we just compare is it different between returned value and guessed timezone

        var valueDateString = (0, _moment.default)(value).format('YYYY-MM-DD');

        if (value.toString().match(/GMT\+/g) && (0, _moment.default)(value).toString().match(/GMT-/g)) {
          valueDateString = (0, _moment.default)(value).add(1, 'day').format('YYYY-MM-DD');
        }

        var newDate = (0, _moment.default)(_moment.default.tz("".concat(valueDateString, " 12:00 AM"), 'YYYY-MM-DD h:mm A', this.timezone));

        if (currentDate) {
          newDate.set({
            hour: (0, _moment.default)(currentDate).format('HH'),
            minutes: (0, _moment.default)(currentDate).format('mm'),
            seconds: 0
          });
        }

        this.set("shift.".concat(key, "At"), newDate.toISOString());
      },
      selectJob: function selectJob(jobId) {
        var job = this.get('shift.employeeGuid.jobs').findBy('id', jobId);
        this.get('shift').setProperties({
          jobGuid: job,
          overtimeHourlyPayRate: job.get('overtimePayRate'),
          normalHourlyPayRate: job.get('payRate')
        });
      },
      openConfirmationDialog: function openConfirmationDialog() {
        this.sendAction('onDelete');
      }
    }
  });

  _exports.default = _default;
});