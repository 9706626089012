define("lh4/routes/authenticated/st-jude/opt-in", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    requiredPermissions: 'manage st-jude',
    model: function model() {
      return this.modelFor('authenticated.st-jude');
    },
    afterModel: function afterModel(_ref) {
      var isActive = _ref.isActive;

      if (isActive) {
        return this.transitionTo('authenticated.st-jude');
      }

      return false;
    }
  });

  _exports.default = _default;
});