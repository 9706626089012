define("lh4/models/echo-pro/tender", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/utils/has-many-ids"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _hasManyIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    referenceText: {
      description: 'Reference text',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    allowsChange: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isLocked: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isCustomerRequired: (0, _attr.default)('boolean'),
    isTipAllowed: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    opensDrawer: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    promptForTipOnDevice: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    referenceCode: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    referenceText: (0, _attr.default)('string'),
    isCashRoundingEnabled: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    jobs: (0, _relationships.hasMany)('echo-pro/job'),
    jobIds: Ember.computed('jobs', (0, _hasManyIds.default)('jobs', 'echo-pro/job'))
  });

  _exports.default = _default;
});