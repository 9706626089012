define("lh4/templates/authenticated/epro/payments/discounts/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hzpZUqMP",
    "block": "{\"symbols\":[\"tray\"],\"statements\":[[4,\"ui/tray/sliding-tray\",null,[[\"onClose\",\"errorMessage\",\"shouldClose\",\"onBeforeClose\",\"title\"],[[28,\"action\",[[23,0,[]],\"close\"],null],[24,[\"errorMessage\"]],[24,[\"shouldCloseTray\"]],[24,[\"onBeforeClose\"]],\"Edit Discount\"]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `tray.content` to be a contextual component but found a string. Did you mean `(component tray.content)`? ('lh4/templates/authenticated/epro/payments/discounts/edit.hbs' @ L8:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"epro/payments/discount-form\",null,[[\"discount\",\"orderTypes\",\"items\",\"showErrors\"],[[24,[\"changeset\"]],[24,[\"model\",\"orderTypes\"]],[24,[\"model\",\"items\"]],[24,[\"showErrors\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"actions\"]],\"expected `tray.actions` to be a contextual component but found a string. Did you mean `(component tray.actions)`? ('lh4/templates/authenticated/epro/payments/discounts/edit.hbs' @ L16:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui button primary\",[28,\"if\",[[24,[\"isSaving\"]],\" loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"update\"]],[8],[0,\"\\n\\t\\t\\tSave\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui primary basic button left-action\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n\\t\\t\\tDiscard\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/epro/payments/discounts/edit.hbs"
    }
  });

  _exports.default = _default;
});