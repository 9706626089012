define("lh4/helpers/display-units", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(id, units, before) {
    var _document$getElementB;

    // grab the input fields parent
    var ele = (_document$getElementB = document.getElementById(id)) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.children[0];

    if (before && ele) {
      ele.setAttribute('data-before-content', units);
      return;
    }

    if (ele) {
      ele.setAttribute('data-after-content', units);
      var inputElement = ele.getElementsByTagName('input')[0];
      var inputValue = inputElement.value;

      if (inputValue >= 1 && inputValue <= 999) {
        // Creates temporary element for field text value to calculate its width
        var span = document.createElement('span');
        span.className = 'temp-element';
        span.style.font = getComputedStyle(inputElement).font;
        span.textContent = inputValue;
        document.body.appendChild(span);
        var inputTextWidth = span.offsetWidth;
        document.body.removeChild(span);
        var initialLeftValue = 20;
        var finalLeftValue = initialLeftValue + inputTextWidth;
        ele.style.setProperty('--left', "".concat(finalLeftValue, "px"));
      }
    }
  };

  _exports.default = _default;
});