define("lh4/components/epro/menu/availability-price/dynamic-price-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['dynamic-price-form', 'ui', 'grid', 'column', 'row', 'sixteen', 'wide'],
    showErrors: false,
    isDepartment: false,
    minimumPrice: '0.00',
    defaultPrice: '0.00',
    errorMessage: null,
    selectedDynamicPriceSchedules: Ember.computed('changeset', 'selectedDynamicPriceGuid', function getter() {
      var _this = this;

      var dynamicPrice = (this.dynamicPrices || []).find(function (p) {
        return p.guid === _this.selectedDynamicPriceGuid;
      });

      if (!dynamicPrice) {
        return [];
      } // filter the existing changests by the selected price group


      var changesets = (this.changeset.dynamicPricingChangesets || []).filter(function (c) {
        return c.get('scheduleTitleGuid') === dynamicPrice.guid;
      }); // get the list of revenue center names for the selected price group

      var getRevenueCenterName = function getRevenueCenterName(guid) {
        var revCenter = (_this.revenueCenterOptions || []).find(function (_ref) {
          var value = _ref.value;
          return value === guid;
        });
        return revCenter ? revCenter.name : 'Unknown';
      };

      var revenueCenterNames = (dynamicPrice.revenueCenterGuids || []).length === 0 ? 'All Revenue Centers' : dynamicPrice.revenueCenterGuids.map(getRevenueCenterName).join(', '); // return the list of schedules for the selected price group

      if (dynamicPrice.timeOption === 'Day Parts') {
        return this.getSelectedDynamicPriceSchedulesByDayPart(dynamicPrice, changesets, revenueCenterNames);
      }

      return this.getSelectedDynamicPriceSchedulesByCustomSchedule(dynamicPrice, changesets, revenueCenterNames);
    }),
    getSelectedDynamicPriceSchedulesByCustomSchedule: function getSelectedDynamicPriceSchedulesByCustomSchedule(dynamicPrice, existingChangesets, revenueCenterNames) {
      var _this2 = this;

      // use the existing schedules array as the schedules
      return (dynamicPrice.schedules || []).map(function (schedule) {
        var scheduleTitleGuid = dynamicPrice.guid;
        var scheduleNumber = schedule.scheduleNumber;
        var changeset = existingChangesets.find(function (c) {
          return c.get('scheduleNumber') === scheduleNumber;
        });

        if (!changeset) {
          var model = _this2.store.createRecord('echo-pro/availability-price/configure-pricing', {
            scheduleTitleGuid: scheduleTitleGuid,
            scheduleNumber: scheduleNumber,
            useIntradayPeriods: false
          });

          changeset = _this2.buildChildChangeset(model);
        }

        return {
          schedule: schedule,
          changeset: changeset,
          revenueCenterNames: revenueCenterNames
        };
      });
    },
    getSelectedDynamicPriceSchedulesByDayPart: function getSelectedDynamicPriceSchedulesByDayPart(dynamicPrice, existingChangesets, revenueCenterNames) {
      var _this3 = this;

      // need to build schedules from the list of intradayPeriodGroupGuids
      var createAllDayPartsSchedule = function createAllDayPartsSchedule(scheduleNumber) {
        return _this3.store.createRecord('echo-pro/availability-price/schedule', {
          scheduleTitleGuid: dynamicPrice.scheduleTitleGuid,
          scheduleNumber: scheduleNumber,
          scheduleName: 'All Day Parts'
        });
      };

      var createSpecificDayPartSchedule = function createSpecificDayPartSchedule(scheduleNumber, guid) {
        var dayPart = _this3.dayPartOptions.intradayPeriodGroups.find(function (ipg) {
          return ipg.guid === guid;
        });

        return _this3.store.createRecord('echo-pro/availability-price/schedule', {
          scheduleTitleGuid: dynamicPrice.scheduleTitleGuid,
          scheduleNumber: scheduleNumber,
          scheduleName: dayPart ? dayPart.intradayPeriodGroupName : 'Unknown' // The schedule for a day part can be complex, ex. M-W 8am-11am, Th 7am-8am, F 6am-8am
          // so not sure how to display that here yet. For now we will just display the day part name

        });
      };

      var intradayPeriodGroupGuids = (dynamicPrice.intradayPeriodGroupGuids || []).length === 0 ? [null] : dynamicPrice.intradayPeriodGroupGuids;
      return intradayPeriodGroupGuids.map(function (intradayPeriodGroupGuid, i) {
        var scheduleTitleGuid = dynamicPrice.guid;
        var scheduleNumber = i + 1;
        var schedule = intradayPeriodGroupGuid === null ? createAllDayPartsSchedule(scheduleNumber) : createSpecificDayPartSchedule(scheduleNumber, intradayPeriodGroupGuid);
        var changeset = existingChangesets.find(function (c) {
          return c.get('useIntradayPeriods') === true && c.get('intradayPeriodGroupGuid') === intradayPeriodGroupGuid;
        });

        if (!changeset) {
          var model = _this3.store.createRecord('echo-pro/availability-price/configure-pricing', {
            scheduleTitleGuid: scheduleTitleGuid,
            scheduleNumber: scheduleNumber,
            useIntradayPeriods: true,
            intradayPeriodGroupGuid: intradayPeriodGroupGuid
          });

          changeset = _this3.buildChildChangeset(model);
        }

        return {
          schedule: schedule,
          changeset: changeset,
          revenueCenterNames: revenueCenterNames
        };
      });
    },
    checkHasZeroPrice: function checkHasZeroPrice(childChangeset) {
      var priceType = childChangeset.get('priceType');
      var priceValue = parseFloat(childChangeset.get('priceValue'));
      var minimumItemPrice = parseFloat(this.minimumPrice);
      var itemPrice = parseFloat(this.defaultPrice);

      if (Number.isNaN(priceValue) || this.isDepartment && !minimumItemPrice || !this.isDepartment && !itemPrice) {
        return false;
      }

      if (priceType === 'Discount' && priceValue === 100) {
        return true;
      }

      if (priceType === 'Dollars' && priceValue === 0) {
        return true;
      }

      if (priceType === 'Flat Discount') {
        if (this.isDepartment && priceValue >= minimumItemPrice) {
          return true;
        }

        if (!this.isDepartment && priceValue >= itemPrice) {
          return true;
        }
      }

      return false;
    },
    warningMessage: Ember.computed('changeset.dynamicPricingChangesets.@each.hasZeroPrice', function getter() {
      if (!this.changeset.dynamicPricingChangesets) {
        return null;
      }

      if (this.changeset.dynamicPricingChangesets.some(function (c) {
        return c.get('hasZeroPrice') === true;
      })) {
        if (this.isDepartment) {
          return "One or more item prices when affected by Dynamic Pricing rules will result to ".concat(this.locale.currencySymbol, "0");
        }

        return "The item price when affected by Dynamic Pricing rules will result to ".concat(this.locale.currencySymbol, "0");
      }

      return null;
    }),
    actions: {
      handleDynamicPriceChange: function handleDynamicPriceChange(childChangeset) {
        var _this4 = this;

        // now that this has been changed, ensure it is included in the main changeset
        if (!this.changeset.dynamicPricingChangesets.find(function (existing) {
          return existing.data.compare(childChangeset);
        })) {
          this.changeset.dynamicPricingChangesets.pushObject(childChangeset);
        }

        this.set('errorMessage', null);
        childChangeset.validate('priceValue').then(function (data) {
          // add error message if priceValue fails validation
          if (childChangeset.get('isInvalid')) {
            _this4.set('errorMessage', data.validation);
          } // add warning message if final price will be below zero


          childChangeset.set('hasZeroPrice', _this4.checkHasZeroPrice(childChangeset));
        });
      }
    }
  });

  _exports.default = _default;
});