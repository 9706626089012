define("lh4/components/internet-payments/transactions-controls", ["exports", "lh4/enums/transactions-search-filters", "lh4/enums/transactions-filters", "lh4/helpers/usd-format"], function (_exports, _transactionsSearchFilters, _transactionsFilters, _usdFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var generateOptions = function generateOptions(base, checkboxGroup) {
    var _this = this;

    return base.map(function (o) {
      return _objectSpread(_objectSpread({}, o), {}, {
        isChecked: _this.filterParamSettings[checkboxGroup][o.id] || false
      });
    });
  };

  var generatePreviewList = function generatePreviewList(optionSet, baseSet) {
    var maxSingles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
    var supportAll = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

    if (!optionSet || optionSet.length === 0) {
      return [];
    }

    if (optionSet.length <= maxSingles) {
      return baseSet.filter(function (b) {
        return optionSet.includes(b.id);
      }).map(function (o) {
        return o.name;
      });
    }

    if (supportAll === true && baseSet.length === optionSet.length) {
      return ['All'];
    }

    return ["".concat(optionSet.length, " Selected")];
  };

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    can: Ember.inject.service(),
    searchCriteria: _transactionsSearchFilters.IP_TRANSACTIONS_SEARCH_FILTERS,
    typeOptions: Ember.computed('filterParamSettings', function getter() {
      var typeOptions = _toConsumableArray(_transactionsFilters.TYPE_OPTIONS_BASE);

      if (this.can.can('view gift card event statuses in feature-flag')) {
        typeOptions.push({
          id: 'INFO',
          name: 'Non-Sale/Info',
          isChecked: false
        });
      }

      return generateOptions.call(this, typeOptions, 'type');
    }),
    riskOptions: Ember.computed('filterParamSettings', function getter() {
      return generateOptions.call(this, _transactionsFilters.RISK_OPTIONS_BASE, 'riskStatus');
    }),
    statusOptions: Ember.computed('filterParamSettings', function getter() {
      var statusOptions = _toConsumableArray(_transactionsFilters.STATUS_OPTIONS_BASE);

      if (this.can.can('view transactions status failed in feature-flag')) {
        statusOptions.push({
          id: 'FAILED',
          name: 'Failed',
          isChecked: false
        });
      }

      if (this.can.can('view ip transaction status chargeback in feature-flag')) {
        statusOptions.push({
          id: 'CHARGEBACK',
          name: 'Chargeback',
          isChecked: false
        });
      }

      return generateOptions.call(this, statusOptions, 'status');
    }),

    get eventStatusOptionsPreviews() {
      var eventStatusOptions = _toConsumableArray(this.eventStatusOptionsBase);

      if (this.can.can('view gift card event statuses in feature-flag')) {
        eventStatusOptions.concat([{
          id: 'BALANCE CHECK',
          name: 'Balance Check',
          isChecked: false
        }, {
          id: 'LOADED',
          name: 'Loaded',
          isChecked: false
        }, {
          id: 'DEACTIVATED',
          name: 'Deactivated',
          isChecked: false
        }]);
      }

      return generatePreviewList(this.eventStatusQueryParams, eventStatusOptions, 0).join(', ');
    },

    get typeOptionsPreviews() {
      return generatePreviewList(this.typeQueryParams, this.typeOptions).join(', ');
    },

    get riskOptionsPreviews() {
      return generatePreviewList(this.riskQueryParams, _transactionsFilters.RISK_OPTIONS_BASE).join(', ');
    },

    get riskRuleOptionsPreviews() {
      return generatePreviewList(this.riskRuleQueryParams, this.checkedRiskRuleOptions, 0).join(', ');
    },

    get acquirerResponseOptionsPreviews() {
      return generatePreviewList(this.acquirerResponseQueryParams, this.checkedAcquirerResponseOptions, 0).join(', ');
    },

    get statusOptionsPreviews() {
      return generatePreviewList(this.statusQueryParams, this.statusOptions).join(', ');
    },

    get searchBy() {
      var _this$matchPartial;

      return ((_this$matchPartial = this.matchPartial) === null || _this$matchPartial === void 0 ? void 0 : _this$matchPartial.length) > 0 ? this.matchPartial[0] : null;
    },

    get amountOptionsPreview() {
      var min = this.minQueryParam;
      var max = this.maxQueryParam;
      var displayMin = min || min === 0 ? (0, _usdFormat.usdFormat)([min]) : null;
      var displayMax = max || max === 0 ? (0, _usdFormat.usdFormat)([max]) : null;

      if (!displayMin && !displayMax) {
        return '';
      } // only min exists


      if (displayMin && !displayMax) {
        return "From ".concat(displayMin);
      } // only max exists


      if (displayMax && !displayMin) {
        return "To ".concat(displayMax);
      }

      return "".concat(displayMin, " - ").concat(displayMax);
    },

    actions: {
      onFilter: function onFilter() {
        this.onFilter();
      },
      onNewTransaction: function onNewTransaction() {
        this.onNewTransaction();
      },
      onExport: function onExport(fileType) {
        this.downloadTransactions(fileType);
      }
    }
  });

  _exports.default = _default;
});