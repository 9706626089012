define("lh4/templates/authenticated/epro/timeclock/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SUazQlry",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"epro/timeclock/labor-settings-form\",null,[[\"changeset\",\"businessChangeset\",\"errorMessage\",\"successMessage\"],[[24,[\"changeset\"]],[24,[\"businessChangeset\"]],[24,[\"errorMessage\"]],[24,[\"successMessage\"]]]]],false],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",[29,[\"ui button primary padded\",[28,\"if\",[[24,[\"isSaving\"]],\" loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"update\"]],[8],[0,\"\\n\\tSave\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/epro/timeclock/settings.hbs"
    }
  });

  _exports.default = _default;
});