define("lh4/routes/authenticated/epro/menu/items/bulk-edit", ["exports", "lh4/routes/general-edit-route", "ember-changeset", "lh4/utils/validations"], function (_exports, _generalEditRoute, _emberChangeset, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    modelName: 'item',
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('bulk-edit in echo-pro/menu') || this.controllerFor('authenticated.epro.menu.items').get('selectionCount') < 2) {
        this.transitionTo('authenticated.epro.menu.items');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        item: this.store.createRecord('echo-pro/menu/item-bulk', {
          locationId: this.location.model.id
        }),
        departments: this.modelFor('authenticated.epro.menu.items').departments,
        modifierSets: this.modelFor('authenticated.epro.menu.items').modifierSets,
        taxes: this.modelFor('authenticated.epro.menu.items').taxes,
        revenueClasses: this.modelFor('authenticated.epro.menu.items').revenueClasses,
        printers: this.modelFor('authenticated.epro.menu.items').printers,
        itemTags: this.modelFor('authenticated.epro.menu.items').itemTags,
        stylesheets: this.isEproColorPickerFeatureFlagEnabled() ? this.store.findAll('echo-pro/stylesheet') : null
      });
    },
    setupController: function setupController(theController, model) {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [theController, model].concat(args));

      var controller = theController;
      controller.setProperties({
        queryParams: this.paramsFor('authenticated.epro.menu.items'),
        buildChildChangeset: function buildChildChangeset(childModel) {
          return _this.buildChildChangeset(childModel);
        }
      });
    },
    buildChildChangeset: function buildChildChangeset(model) {
      var _buildChangesetDepend = (0, _validations.buildChangesetDependentValidations)(model),
          validateFn = _buildChangesetDepend.validateFn,
          validationMap = _buildChangesetDepend.validationMap;

      return new _emberChangeset.default(model, validateFn, validationMap, {
        skipValidate: true
      });
    }
  });

  _exports.default = _default;
});