define("lh4/helpers/filter-lc-cards-by-permissions", ["exports", "lh4/constants/launch-control/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterLCCardsByPermissions = filterLCCardsByPermissions;
  _exports.default = void 0;

  /**
   * Filters LC cards based on the permissions defined in abilities/launch-control.js
   * @param {Can} field - can instance via this.can
   * @param {Array} field - an array of launch control cards defined in LaunchControlRoute
   * @returns {Array} - filtered launch control cards
   */
  function filterLCCardsByPermissions(can, cards) {
    var filteredCards = cards; // check SkyTab BI permissions

    if (can.cannot('view skytab bi in launch-control')) {
      filteredCards = filteredCards.filter(function (c) {
        return c.moduleName !== _constants.SKYTAB_BI_MODULE.name;
      });
    } // check other permisions...


    return filteredCards;
  }

  var _default = Ember.Helper.helper(filterLCCardsByPermissions);

  _exports.default = _default;
});