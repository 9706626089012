define("lh4/templates/components/device-management/device-management-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ee6JcaJO",
    "block": "{\"symbols\":[\"@changeset\",\"@showErrors\",\"@disabled\",\"@revenueCenterOptions\"],\"statements\":[[7,\"h2\",true],[10,\"class\",\"ui header toggle-header\"],[8],[0,\"\\n\\tDevice Details\\n\"],[9],[0,\"\\n\"],[5,\"ui/form-fields/input-field\",[],[[\"@changeset\",\"@label\",\"@property\",\"@showErrors\",\"@readonly\",\"@disabled\"],[[23,1,[]],\"Station Number\",\"number\",[23,2,[]],true,true]]],[0,\"\\n\"],[5,\"ui/form-fields/input-field\",[],[[\"@changeset\",\"@label\",\"@property\",\"@showErrors\",\"@disabled\"],[[23,1,[]],\"Device Name\",\"name\",[23,2,[]],[23,3,[]]]]],[0,\"\\n\"],[5,\"ui/form-fields/select-field\",[],[[\"@changeset\",\"@label\",\"@property\",\"@showErrors\",\"@options\",\"@nullValueOptionName\",\"@disabled\"],[[23,1,[]],\"Revenue Center\",\"revenueCenterGuid\",[23,2,[]],[23,4,[]],\"Unassigned\",[23,3,[]]]]],[0,\"\\n\\n\"],[5,\"ui/form-fields/required-fields\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/device-management/device-management-form.hbs"
    }
  });

  _exports.default = _default;
});