define("lh4/models/echo-pro/availability-price/schedule", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    scheduleName: (0, _emberCpValidations.validator)('presence', {
      description: 'Price Type',
      presence: true
    })
  });

  var _default = _model.default.extend(Validations, {
    scheduleTitleGuid: (0, _attr.default)('string'),
    scheduleName: (0, _attr.default)('string'),
    scheduleNumber: (0, _attr.default)('number'),
    beginTimeMilliseconds: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    endTimeMilliseconds: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    isOnMonday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnTuesday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnWednesday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnThursday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnFriday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnSaturday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnSunday: (0, _attr.default)('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});