define("lh4/components/transactions/transactions-controls", ["exports", "lh4/enums/transactions-search-filters", "lh4/enums/transactions-filters", "lh4/components/internet-payments/transactions-controls"], function (_exports, _transactionsSearchFilters, _transactionsFilters, _transactionsControls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var generateOptions = function generateOptions(base, checkboxGroup) {
    var _this = this;

    return base.map(function (o) {
      return _objectSpread(_objectSpread({}, o), {}, {
        isChecked: _this.filterParamSettings[checkboxGroup][o.id] || false
      });
    });
  };

  var generatePreviewList = function generatePreviewList(optionSet, baseSet) {
    var maxSingles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
    var supportAll = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    var workingOptionSet = optionSet; // If the option set includes both '' and 'null', we want to remove the 'null' option

    if (optionSet !== null && optionSet !== void 0 && optionSet.includes('') && optionSet !== null && optionSet !== void 0 && optionSet.includes('null')) {
      workingOptionSet = optionSet.filter(function (o) {
        return o !== 'null';
      });
    }

    if (!workingOptionSet || workingOptionSet.length === 0) {
      return [];
    }

    if (workingOptionSet.length <= maxSingles) {
      return baseSet.filter(function (b) {
        return workingOptionSet.includes(b.id);
      }).map(function (o) {
        return o.name;
      });
    }

    if (supportAll === true && baseSet.length === workingOptionSet.length) {
      return ['All'];
    }

    return ["".concat(workingOptionSet.length, " Selected")];
  };

  var _default = _transactionsControls.default.extend({
    searchCriteria: _transactionsSearchFilters.FNB_TRANSACTIONS_SEARCH_FILTERS,
    cardTypesOptions: Ember.computed('filterParamSettings', function getter() {
      return generateOptions.call(this, _transactionsFilters.CARD_TYPES_OPTIONS_BASE, 'cardTypes');
    }),

    get serverNameOptionsPreviews() {
      return generatePreviewList(this.serverNameQueryParams, this.checkedServerNameOptions, 0).join(', ');
    },

    get dayPartsOptionsPreviews() {
      return generatePreviewList(this.dayPartsQueryParams, this.checkedDayPartsOptions, 0).join(', ');
    },

    get cardTypesOptionsPreviews() {
      return generatePreviewList(this.cardTypesParams, _transactionsFilters.CARD_TYPES_OPTIONS_BASE, 0).join(', ');
    },

    get revenueCentersOptionsPreviews() {
      return generatePreviewList(this.revenueCentersParams, this.revenueCentersOptions, 0).join(', ');
    }

  });

  _exports.default = _default;
});