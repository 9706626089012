define("lh4/mirage/routes/internet-payments/configurable-columns", ["exports", "lh4/mirage/routes/fixtures/internet-payments/configurable-columns"], function (_exports, _configurableColumns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageTransactionsServer;

  function setMirageTransactionsServer(server) {
    server.get('/transactions', function () {
      return _configurableColumns.default;
    });
  }
});