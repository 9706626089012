define("lh4/templates/components/ui/navigation/vertical-navigation-categories/launch-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hGxWsMQ3",
    "block": "{\"symbols\":[\"@notificationList\",\"@navOpenHover\",\"@navCloseHover\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"sidebar-content has-sub-nav\"],[3,\"on\",[\"mouseenter\",[23,2,[]]]],[3,\"on\",[\"mouseleave\",[23,3,[]]]],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"row\",\"authenticated.launch-control\"]],{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"icon\"],[8],[1,[22,\"ui/icons/sidebar/launch-control\"],false],[9],[0,\"\\n\\t\\t\"],[1,[28,\"ui/navigation/nav-item\",null,[[\"name\",\"notificationList\"],[\"Launch Control\",[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/navigation/vertical-navigation-categories/launch-control.hbs"
    }
  });

  _exports.default = _default;
});