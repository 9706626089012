define("lh4/enums/processing-report-type-slug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PROCESSING_REPORT_TYPE_SLUG = {
    SETTLEMENT: 'settlement'
  };
  var _default = PROCESSING_REPORT_TYPE_SLUG;
  _exports.default = _default;
});