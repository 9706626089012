define("lh4/templates/components/epro/menu/pricing-table-bulk-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FdRYFYzL",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"location-settings-tray ui fluid container\"],[8],[0,\"\\n\\t\"],[7,\"h2\",true],[10,\"class\",\"ui header toggle-header\"],[8],[0,\"\\n\\t\\tDetails\\n\\t\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"validationMessages\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui negative message\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"strong\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui form padded stackable grid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui sixteen wide column\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"ui/form-fields/select-field\",null,[[\"label\",\"changeset\",\"allowMultiple\",\"placeholder\",\"options\",\"property\",\"fullWidth\"],[\"Price Group\",[24,[\"changeset\"]],true,\"All Groups\",[24,[\"corporateGroupOptions\"]],\"corporateGroupIds\",true]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui eight wide column\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"ui/form-fields/select-field\",null,[[\"changeset\",\"label\",\"options\",\"property\",\"showErrors\",\"fullWidth\"],[[24,[\"changeset\"]],\"Price Change\",[24,[\"priceChangeOptions\"]],\"priceChangeOptionId\",[24,[\"showErrors\"]],false]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui eight wide column\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"ui/form-fields/input-field\",null,[[\"changeset\",\"type\",\"property\",\"showErrors\",\"fullWidth\"],[[24,[\"changeset\"]],\"number\",\"priceChangeValue\",[24,[\"showErrors\"]],false]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/epro/menu/pricing-table-bulk-form.hbs"
    }
  });

  _exports.default = _default;
});