define("lh4/controllers/authenticated/epro/menu/dynamic-pricing/create", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    trackUpdateCompleted: function trackUpdateCompleted() {
      this.metrics.trackEvent('MixpanelCustom', {
        Event: 'Menu Update Completed',
        'Entry Type': 'New',
        'Entry ID': this.changeset.get('id'),
        'Number of Updates': Object.keys(this.changeset.change).length,
        'Entries Updated': Object.keys(this.changeset.change),
        'Menu Component': 'Dynamic Pricing'
      });
    },
    actions: {
      create: function create() {
        var _this = this;

        if (this.isSaving) {
          return;
        }

        this.set('showErrors', true);
        this.set('isSaving', true);
        this.clearServerError();
        this.changeset.validate().then(function () {
          if (_this.changeset.isInvalid || _this.areChildrenInvalid) {
            return Ember.RSVP.reject(_this.changeset.errors);
          }

          _this.trackUpdateCompleted();

          if (_this.changeset.isPristine) {
            _this.clearTemporaryData();

            _this.closeModal();

            return Ember.RSVP.resolve();
          }

          return _this.changeset.save().then(function () {
            _this.clearTemporaryData();

            _this.closeModal();
          }).catch(function (_ref) {
            var errors = _ref.errors;

            if (!(errors && errors[0])) {
              return;
            } // TODO normalize how we handle these error messages: LH-8461


            var createError = "Something went wrong, ".concat(_this.instanceName, " could not be created.");
            errors.forEach(function (error) {
              var errorMessage = error.details && error.details[0] || error.message || createError;

              _this.set('serverError', errorMessage);

              _this.set('serverErrorTimeoutId', Ember.run.later(_this, function () {
                _this.set('serverError', null);
              }, _this.serverErrorTimeoutDelay));
            });
          });
        }).catch(function (errors) {
          // We currently don't have a field component for checkboxes
          // Because of this there is no standard way of displaying
          // error messages for boolean fields
          // https://jira.shift4payments.com/browse/LH-8853
          if (errors.length) {
            var booleanError = errors.find(function (error) {
              return typeof error.value === 'boolean';
            });

            if (booleanError) {
              _this.set('booleanError', booleanError.validation);

              _this.set('serverErrorTimeoutId', Ember.run.later(_this, function () {
                _this.set('booleanError', null);
              }, _this.serverErrorTimeoutDelay));
            } else {
              // Used to catch custom validation error that validates models one to another
              // For unique name
              var error = errors[0];

              if (error && error.validation) {
                _this.set('customError', error.validation);
              }
            }
          }
        }).finally(function () {
          return _this.set('isSaving', false);
        });
      },
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      }
    }
  });

  _exports.default = _default;
});