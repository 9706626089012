define("lh4/templates/components/epro/menu/dynamic-pricing-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TF57Nn6f",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[10,\"class\",\"ui header toggle-header\"],[8],[0,\"\\n\\tPrice Group Details\\n\\t\"],[1,[28,\"ui/form-fields/checkbox-field\",null,[[\"isToggle\",\"isHeader\",\"label\",\"property\",\"changeset\",\"showErrors\"],[true,true,\"Active\",\"isActive\",[24,[\"changeset\"]],[24,[\"showErrors\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"ui/form-fields/input-field\",null,[[\"label\",\"property\",\"changeset\",\"showErrors\"],[\"Group Name\",\"scheduleTitle\",[24,[\"changeset\"]],[24,[\"showErrors\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"ui/form-fields/select-field\",null,[[\"allowMultiple\",\"label\",\"placeholder\",\"property\",\"changeset\",\"options\",\"showErrors\",\"includeInactiveStyle\"],[true,\"Revenue Centers\",\"All Revenue Centers\",\"revenueCenterGuids\",[24,[\"changeset\"]],[24,[\"revenueCenterOptions\"]],[24,[\"showErrors\"]],true]]],false],[0,\"\\n\\n\"],[0,\"\\n\"],[1,[22,\"ui/form-fields/required-fields\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/epro/menu/dynamic-pricing-form.hbs"
    }
  });

  _exports.default = _default;
});