define("lh4/models/echo-pro/pay-rate", ["exports", "ember-data", "lh4/mixins/model-cloneable", "ember-cp-validations", "ember-data/relationships"], function (_exports, _emberData, _modelCloneable, _emberCpValidations, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    jobGuid: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    payRate: {
      description: 'Pay Rate',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gte: 0,
        allowString: true
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (model.get('location').model.features.newTimeClockManagement) {
          if (Number(value) < Number(model.get('jobValue'))) {
            return "Pay Rate must be higher than job value of ".concat(model.get('jobValue'));
          }

          return true;
        }

        return true;
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        positive: true,
        message: 'Pay Rate cannot be a negative number or empty',
        allowNone: false,
        disabled: Ember.computed(function getter() {
          return !this.model.get('location').model.features.newTimeClockManagement;
        })
      })]
    }
  });

  var _default = _emberData.default.Model.extend(_modelCloneable.default, Validations, {
    location: Ember.inject.service(),
    locationId: _emberData.default.attr('number'),
    employeeGuid: _emberData.default.attr('string'),
    jobGuid: _emberData.default.attr('string'),
    payRate: _emberData.default.attr('string'),
    isVoided: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    employee: (0, _relationships.belongsTo)('echo-pro/employee'),
    name: Ember.computed('jobGuid', function getter() {
      return this.store.peekRecord('echo-pro/job', this.jobGuid).name;
    }),
    jobValue: Ember.computed('payRate', function getter() {
      return this.store.peekRecord('echo-pro/job', this.jobGuid).payRate;
    })
  });

  _exports.default = _default;
});