define("lh4/templates/components/internet-payments/ui/generic-file-upload/file-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wRPEAkrt",
    "block": "{\"symbols\":[\"@fileName\",\"@fileSize\",\"@onDelete\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segment center aligned file-section\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"flex-container\"],[8],[0,\"\\n\\t\\t\"],[7,\"a\",true],[10,\"class\",\"url\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"handleLinkClick\"],null]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[23,1,[]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"file-size\"],[8],[0,\"\\n\\t\\t\\t(\"],[1,[28,\"nice-file-size\",[[23,2,[]]],null],false],[0,\")\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"span\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,3,[]]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n\\t\\t\"],[5,\"ui/icons/internet-payments/delete\",[],[[],[]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/generic-file-upload/file-section.hbs"
    }
  });

  _exports.default = _default;
});