define("lh4/controllers/authenticated/epro/menu/departments/edit", ["exports", "lh4/controllers/general-form-controller", "lh4/mixins/controllers/authenticated/epro/menu/stylesheet-saving"], function (_exports, _generalFormController, _stylesheetSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend(_stylesheetSaving.default, {
    metrics: Ember.inject.service(),
    locale: Ember.inject.service(),
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    trackUpdateCompleted: function trackUpdateCompleted() {
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Completed',
        'Entry Type': 'Edit',
        'Entry ID': this.changeset.get('id'),
        'Number of Updates': Object.keys(this.changeset.change).length,
        'Entries Updated': Object.keys(this.changeset.change),
        'Menu Component': 'Department'
      });
    },
    unloadChildRecords: function unloadChildRecords() {
      this.model.department.dynamicPricing.toArray().forEach(function (map) {
        map.unloadRecord();
      });
    },
    validateAndSaveChangeset: function validateAndSaveChangeset(changesets, key) {
      var _this = this;

      return changesets.map(function (record) {
        return record.validate().then(function () {
          if (!record.isValid) {
            return Ember.RSVP.reject(record.get('errors'));
          }

          record.set('department', _this.model.department); // record.save was throwing because models don't have a save endpoint

          record.execute();
          record.rollback();

          _this.model.department.get(key).pushObject(record.data);

          return true;
        }).catch(function () {
          _this.set('areChildrenInvalid', true);

          return false;
        });
      });
    },
    savePricing: function savePricing() {
      if (this.changeset.dynamicPricingChangesets.some(function (c) {
        return c.get('hasZeroPrice') === true;
      })) {
        var confirmed = window.confirm("One or more item prices when affected by Dynamic Pricing rules will result to ".concat(this.locale.currencySymbol, "0. Would you like to proceed?"));

        if (!confirmed) {
          this.set('isSaving', false);
          return Promise.reject(new Error('Please confirm'));
        }
      }

      var dynamicPricingChangesets = this.validateAndSaveChangeset(this.changeset.dynamicPricingChangesets, 'dynamicPricing');
      return Ember.RSVP.all(dynamicPricingChangesets);
    },
    actions: {
      update: function update() {
        var _this2 = this;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError();
        var departmentId = this.changeset.get('id');
        this.createStposUiStylesheetObjectLink({
          locationId: this.changeset.get('locationId'),
          departmentGuid: departmentId,
          isActive: true
        });
        this.savePricing().then(function () {
          _this2.changeset.validate().then(function () {
            if (_this2.changeset.get('isInvalid') || _this2.areChildrenInvalid) {
              return Ember.RSVP.reject(_this2.changeset.get('errors'));
            }

            _this2.trackUpdateCompleted();

            if (_this2.isModelAndChangesetPristine()) {
              _this2.closeTray();

              return Ember.RSVP.resolve();
            }

            return _this2.changeset.save().then(function () {
              _this2.unloadChildRecords();

              _this2.closeTray();
            }).catch(function (_ref) {
              var errors = _ref.errors;

              if (!(errors && errors[0])) {
                return;
              } // TODO normalize how we handle these error messages: LH-8461


              var createError = "Something went wrong, ".concat(_this2.instanceName, " could not be created.");
              errors.forEach(function (error) {
                var errorMessage = error.details && error.details[0] || error.message || createError;

                _this2.set('serverError', errorMessage);

                _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                  _this2.set('serverError', null);
                }, _this2.serverErrorTimeoutDelay));
              });
            });
          }).catch(function (errors) {
            // We currently don't have a field component for checkboxes
            // Because of this there is no standard way of displaying
            // error messages for boolean fields
            // https://jira.shift4payments.com/browse/LH-8853
            if (errors.length) {
              var booleanError = errors.find(function (error) {
                return typeof error.value === 'boolean';
              });

              if (booleanError) {
                _this2.set('booleanError', booleanError.validation);

                _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                  _this2.set('booleanError', null);
                }, _this2.serverErrorTimeoutDelay));
              }
            }
          }).finally(function () {
            return _this2.set('isSaving', false);
          });
        });
      },
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      }
    }
  });

  _exports.default = _default;
});