define("lh4/templates/components/ui/navigation/vertical-navigation-controls/sign-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KxeO7DvO",
    "block": "{\"symbols\":[\"@logout\"],\"statements\":[[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"sidebar-content sign-out\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[23,1,[]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"icon\"],[8],[1,[22,\"ui/icons/internet-payments/sign-out\"],false],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[8],[0,\"Sign Out\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/navigation/vertical-navigation-controls/sign-out.hbs"
    }
  });

  _exports.default = _default;
});