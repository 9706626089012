define("lh4/templates/components/ui/icons/internet-payments/insights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CWhcg6BD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"currentColor\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"d\",\"M0 12V10H6V12H0ZM6.75 8.15L4.65 6.05L6.05 4.65L8.15 6.75L6.75 8.15ZM10 6V0H12V6H10ZM15.25 8.15L13.85 6.75L15.95 4.65L17.35 6.05L15.25 8.15ZM16 12V10H22V12H16ZM11 14C10.1667 14 9.45833 13.7083 8.875 13.125C8.29167 12.5417 8 11.8333 8 11C8 10.1667 8.29167 9.45833 8.875 8.875C9.45833 8.29167 10.1667 8 11 8C11.8333 8 12.5417 8.29167 13.125 8.875C13.7083 9.45833 14 10.1667 14 11C14 11.8333 13.7083 12.5417 13.125 13.125C12.5417 13.7083 11.8333 14 11 14ZM15.95 17.35L13.85 15.25L15.25 13.85L17.35 15.95L15.95 17.35ZM6.05 17.35L4.65 15.95L6.75 13.85L8.15 15.25L6.05 17.35ZM10 22V16H12V22H10Z\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/internet-payments/insights.hbs"
    }
  });

  _exports.default = _default;
});