define("lh4/components/epro/menu/item-form", ["exports", "lh4/mixins/components/ui/color-picker/color-picker-mapping"], function (_exports, _colorPickerMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_colorPickerMapping.default, {
    store: Ember.inject.service(),
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    locale: Ember.inject.service(),
    can: Ember.inject.service(),
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    priceScheduleErrorMessage: null,
    priceScheduleOptions: [],
    selectedPriceScheduleGroupGuid: null,
    selectedDynamicPriceGuid: Ember.computed('firstDynamicPriceGuid', {
      get: function get() {
        return this.get('currentDynamicPriceGuid') || this.get('firstDynamicPriceGuid');
      },
      set: function set(key, value) {
        return this.set('currentDynamicPriceGuid', value);
      }
    }),
    departmentOptions: Ember.computed.map('departments', function (_ref) {
      var name = _ref.name,
          value = _ref.guid,
          isActive = _ref.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    revenueClassOptions: Ember.computed.map('revenueClasses', function (_ref2) {
      var name = _ref2.name,
          value = _ref2.guid,
          isActive = _ref2.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    taxOptions: Ember.computed.map('taxes', function (_ref3) {
      var name = _ref3.name,
          value = _ref3.guid,
          isActive = _ref3.active;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    itemTagOptions: Ember.computed.map('itemTags', function (_ref4) {
      var name = _ref4.name,
          value = _ref4.guid,
          isActive = _ref4.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    courseOptions: Ember.computed('courses', function getter() {
      return [{
        name: 'No Course',
        value: null
      }].concat(_toConsumableArray(this.courses.map(function (_ref5) {
        var name = _ref5.name,
            value = _ref5.guid,
            isActive = _ref5.isActive;
        return {
          name: name,
          value: value,
          isActive: isActive
        };
      })));
    }),
    printerOptions: Ember.computed.map('printers', function (_ref6) {
      var name = _ref6.name,
          value = _ref6.guid,
          isActive = _ref6.active;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    priceScheduleGroupOptions: Ember.computed.map('priceScheduleGroups', function (_ref7) {
      var name = _ref7.name,
          value = _ref7.guid,
          isActive = _ref7.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    advancedOptions: [{
      id: 'shouldNotPrintOnCustomerReceipt',
      name: 'Do not print item to customer receipt'
    }, {
      id: 'isOpenItem',
      name: 'Open items (Show keypad to enter price)'
    }, {
      id: 'showSpecialRequest',
      name: 'Special request'
    }, {
      id: 'canBuyWithGiftCard',
      name: 'Item purchasable with gift cards'
    }],
    priceScheduleHeaders: [{
      field: 'name',
      name: 'Name',
      width: 'four'
    }, {
      field: 'ranges',
      name: 'Time Ranges',
      width: 'six'
    }, {
      field: 'price',
      name: 'Price',
      width: 'six'
    }],
    cropperOptions: {
      aspectRatio: 4 / 3,
      zoomable: false,
      scalable: false,
      viewMode: 1
    },
    validatePriceValue: function validatePriceValue(record) {
      var _this = this;

      this.set('priceScheduleErrorMessage', null);
      this.$('.price-schedule-dropdown').removeClass('disabled');
      record.validate('priceValue').then(function (data) {
        if (record.get('isInvalid')) {
          _this.$('.price-schedule-dropdown').addClass('disabled');

          _this.set('priceScheduleErrorMessage', data.validation);
        }
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setColorsFromStylesheet();
    },
    actions: {
      toggleWeighted: function toggleWeighted() {
        this.item.set('isWeighted', !this.item.get('isWeighted'));
        this.item.set('tareWeight', undefined);
      },
      handleDepartmentChange: function handleDepartmentChange(departmentGuid) {
        var selectedDepartment = this.departments.find(function (department) {
          return department.id === departmentGuid;
        });
        this.item.setProperties({
          department: selectedDepartment,
          departmentGuid: departmentGuid
        });
      },
      handleAdvancedChange: function handleAdvancedChange(selected) {
        this.item.set('advanced', selected);

        if (this.showErrors) {
          this.item.validate('shouldNotPrintOnCustomerReceipt');
          this.item.validate('isOpenItem');
        }
      },
      handleQuantityOnHandChange: function handleQuantityOnHandChange(value) {
        this.item.set('qtyOnHand', value);

        if (this.showErrors) {
          this.item.validate('minQty');
        }
      },
      toggleTrackItems: function toggleTrackItems() {
        this.item.set('isDisplayedOnItemTracking', !this.item.get('isDisplayedOnItemTracking'));

        if (this.showErrors) {
          this.item.validate('qtyOnHand');
        }
      },
      toggleLowStockIndicator: function toggleLowStockIndicator() {
        this.item.set('showQtyAlert', !this.item.get('showQtyAlert'));

        if (this.showErrors) {
          this.item.validate('minQty');
          this.item.validate('qtyOnHand');
        }
      },
      handlePriceScheduleGroupChange: function handlePriceScheduleGroupChange(data) {
        this.selectedPriceGroupChanged(data);
      },
      handleDynamicPriceGuidChange: function handleDynamicPriceGuidChange(guid) {
        this.set('selectedDynamicPriceGuid', guid);
      },
      setPriceType: function setPriceType(record, priceType) {
        record.set('priceType', priceType);
        this.validatePriceValue(record);
      },
      uploadImage: function uploadImage(_ref8) {
        var fullBlob = _ref8.fullBlob,
            thumbBlob = _ref8.thumbBlob;
        this.setImageBlobs({
          fullBlob: fullBlob,
          thumbBlob: thumbBlob
        });
      },
      removeImage: function removeImage(imageId) {
        this.deleteImage(imageId);
      },
      handlePriceValueChange: function handlePriceValueChange(record, priceValue) {
        record.set('priceValue', priceValue);
        this.validatePriceValue(record);
      }
    }
  });

  _exports.default = _default;
});