define("lh4/abilities/echo-pro/dynamic-pricing", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberCan, _abilityMixin, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FEATURE_FLAG_VALUES = Object.freeze({
    DYNAMIC_PRICING: 'DP',
    // new functionality
    SCHEDULED_PRICING: 'PS' // old functionality

  });

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    canViewDynamicPricing: (0, _abilityMixin.computedAbility)('launchDarkly.stposFeatureFlags', function getter() {
      var value = this.launchDarkly.stposFeatureFlags[_launchDarklyFeatureFlags.default.EW_LH_PRICE_SCHEDULES_TO_DYNAMIC_PRICING];
      return value === FEATURE_FLAG_VALUES.DYNAMIC_PRICING;
    })
  });

  _exports.default = _default;
});