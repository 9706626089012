define("lh4/templates/authenticated/epro/manage-employees/tip-sharing/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7iy5Yg8D",
    "block": "{\"symbols\":[\"tray\",\"@model\"],\"statements\":[[5,\"ui/tray/sliding-tray\",[],[[\"@onClose\",\"@title\",\"@errorMessage\",\"@shouldClose\",\"@onBeforeClose\"],[[28,\"action\",[[23,0,[]],\"close\"],null],\"Edit Tip Sharing Rule\",[23,0,[\"errorMessage\"]],[23,0,[\"shouldCloseTray\"]],[23,0,[\"onBeforeClose\"]]]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"epro/manage-employees/tip-sharing-form\",[],[[\"@showErrors\",\"@changeset\",\"@buildChildChangeset\",\"@jobs\",\"@revenueClasses\"],[[23,0,[\"showErrors\"]],[23,0,[\"changeset\"]],[28,\"action\",[[23,0,[]],\"buildChildChangeset\"],null],[23,2,[\"jobs\"]],[23,2,[\"revenueClasses\"]]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[6,[23,1,[\"actions\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui primary button\",[28,\"if\",[[24,[\"isSaving\"]],\" loading\"],null]]]],[12,\"disabled\",[28,\"not\",[[24,[\"canSave\"]]],null]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"update\"]],[8],[0,\"\\n\\t\\t\\tSave\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui primary basic button left-action\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n\\t\\t\\tDiscard\\n\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/epro/manage-employees/tip-sharing/edit.hbs"
    }
  });

  _exports.default = _default;
});