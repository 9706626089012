define("lh4/mirage/factories/customer-engagement-setting", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    aliasEmail: function aliasEmail() {
      return _faker.default.internet.email();
    },
    aliasName: function aliasName() {
      return "".concat(_faker.default.name.firstName(), " ").concat(_faker.default.name.lastName());
    },
    facebookUrl: 'https://www.facebook.com',
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    isFacebookVisible: true,
    isOloVisible: true,
    isOnlineReservationsVisible: true,
    isTwitterVisible: true,
    locationId: [1],
    oloUrl: 'http://fakeOloUrl.com',
    onlineReservationUrl: 'http://fakeReservationUrl.com',
    twitterUrl: 'https://twitter.com'
  });

  _exports.default = _default;
});