define("lh4/components/ui/form-fields/date-field", ["exports", "moment", "lh4/components/ui/form-fields/field"], function (_exports, _moment, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    dataTest: 'form-field-date-field',
    media: Ember.inject.service(),
    // calentim attributes
    arrowOn: 'left',
    maxDate: null,
    minDate: null,
    allowNull: false,
    saveAsISOString: false,
    datePickerClass: Ember.computed('fullWidth', function getter() {
      if (!this.label) {
        return 'sixteen wide column';
      }

      return "".concat(this.fullWidth ? 'twelve' : 'eight', " wide column");
    }),
    updateChangeset: function updateChangeset(momentValue) {
      // because running integration tests willDestroyElement() method is not always called
      // we might get some scenarios where outside click handler is still bind to 'this'
      // and tries to update changeset. To be safe the following check is needed
      // @see https://git.shift4payments.com/lighthouse/frontend/-/merge_requests/1509#note_263038
      if (this.changeset.set) {
        if (this.allowNull && momentValue === null) {
          this.changeset.set(this.property, null);
        } else if (this.areMomentsDifferent(momentValue)) {
          var momentValueISO = this.saveAsISOString ? momentValue.toISOString() : momentValue;
          this.changeset.set(this.property, momentValueISO);
        }
      }
    },
    areMomentsDifferent: function areMomentsDifferent(momentValue) {
      var changesetValue = this.changeset.get(this.property);
      var changesetMoment = this.saveAsISOString ? (0, _moment.default)(changesetValue, _moment.default.ISO_8601) : changesetValue;
      return !momentValue.isSame(changesetMoment, 'day');
    },
    actions: {
      onDesktopBlur: function onDesktopBlur(event, picker) {
        var momentValue = picker.elem.value ? (0, _moment.default)(picker.elem.value) : null;
        this.updateChangeset(momentValue);

        if (!this.isDestroyed) {
          this.send('blurField');
        }
      },
      onMobileChange: function onMobileChange(date) {
        this.updateChangeset(date);
      },
      onDesktopChange: function onDesktopChange(instance, date) {
        this.updateChangeset(date);
        this.send('blurField');
      }
    }
  });

  _exports.default = _default;
});