define("lh4/serializers/echo-pro/employee", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    location: Ember.inject.service(),
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var serializedEmployeePhones = [];
      json.employeePhones.forEach(function (phone) {
        if (phone.number !== null) {
          serializedEmployeePhones.push(phone);
        }
      });
      json.employeePhones = serializedEmployeePhones;
      var serializedEmployeeDates = [];
      json.employeeDates.forEach(function (empDate) {
        if (empDate.date !== null) {
          serializedEmployeeDates.push(empDate);
        }
      });
      json.employeeDates = serializedEmployeeDates;

      if (this.location.model.features.newTimeClockManagement) {
        var jobGuids = [];
        json.payRates.forEach(function (payRate) {
          if (payRate.number !== null) {
            jobGuids.push(payRate.jobGuid);
          }
        });
        json.jobs = jobGuids;
      } else {
        delete json.payRates;
      }

      return json;
    },
    attrs: {
      jobs: {
        embedded: 'always',
        serialize: 'id'
      },
      payRates: {
        embedded: 'always'
      },
      permissions: {
        embedded: 'always',
        serialize: 'id'
      },
      employeeDates: {
        embedded: 'always'
      },
      employeePhones: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});