define("lh4/templates/authenticated/reporting/subscriptions/create-subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uWuaheGH",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"ui/modal/fullscreen-modal\",null,[[\"title\",\"onClose\",\"errorMessage\"],[\"New Subscription\",[28,\"action\",[[23,0,[]],\"close\"],null],[24,[\"errorMessage\"]]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `modal.content` to be a contextual component but found a string. Did you mean `(component modal.content)`? ('lh4/templates/authenticated/reporting/subscriptions/create-subscription.hbs' @ L7:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"shared-forms/subscription\",null,[[\"locations\",\"products\",\"reportTypes\",\"showErrors\",\"subscription\",\"buildChildChangeset\"],[[24,[\"allowedLocations\"]],[24,[\"filteredProducts\"]],[24,[\"model\",\"reportTypes\"]],[24,[\"showErrors\"]],[24,[\"changeset\"]],[28,\"action\",[[23,0,[]],\"buildChildChangeset\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"actions\"]],\"expected `modal.actions` to be a contextual component but found a string. Did you mean `(component modal.actions)`? ('lh4/templates/authenticated/reporting/subscriptions/create-subscription.hbs' @ L17:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui primary button \",[28,\"if\",[[24,[\"isSaving\"]],\" loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"create\"]],[8],[0,\"\\n\\t\\t\\tSubscribe\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui primary basic button left-action\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n\\t\\t\\tDiscard\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/reporting/subscriptions/create-subscription.hbs"
    }
  });

  _exports.default = _default;
});