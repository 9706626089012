define("lh4/templates/components/virtual-gift-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kACZFICl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"giftCardIframeError\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui inverted red segment center aligned error-message\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"ui icon exclamation triangle\"],[8],[9],[0,\"\\n\\t\\t\"],[1,[22,\"giftCardIframeError\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"s4-shop-iframe-container\"],[8],[0,\"\\n\\t\\t\"],[7,\"script\",true],[11,\"onload\",[28,\"action\",[[23,0,[]],\"initializeIframe\"],null]],[10,\"src\",\"https://cdn01.s4shops.com/portal/s4s-portal.bundle.js\"],[10,\"async\",\"\"],[10,\"type\",\"text/javascript\"],[8],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"id\",\"portal-target\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[22,\"ui/loading-page\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/virtual-gift-cards.hbs"
    }
  });

  _exports.default = _default;
});