define("lh4/templates/authenticated/internet-payments/disputes/won", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bbZ7J7iu",
    "block": "{\"symbols\":[],\"statements\":[[5,\"internet-payments/disputes/dispute-list\",[],[[\"@disputes\",\"@totalCount\",\"@itemsPerPage\",\"@offset\",\"@onFilterChange\",\"@onRowClick\"],[[23,0,[\"model\",\"data\"]],[23,0,[\"model\",\"count\"]],[23,0,[\"model\",\"limit\"]],[23,0,[\"offset\"]],[28,\"action\",[[23,0,[]],\"onFilterChangeInnerController\"],null],[28,\"action\",[[23,0,[]],\"onRowClick\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/disputes/won.hbs"
    }
  });

  _exports.default = _default;
});