define("lh4/routes/authenticated/epro/menu/dynamic-pricing/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    modelName: 'dynamicPrice',
    childChangesets: {
      schedules: 'scheduleChangesets'
    },
    model: function model(_ref) {
      var scheduleTitleGuid = _ref.scheduleTitleGuid;
      return Ember.RSVP.hash({
        revenueCenterOptions: this.store.query('echo-pro/revenue-center', {
          location: this.location.model.id
        }).then(function (revenueCenters) {
          return revenueCenters.map(function (_ref2) {
            var guid = _ref2.guid,
                revenueCenterName = _ref2.revenueCenterName;
            return {
              value: guid,
              name: revenueCenterName
            };
          });
        }),
        dynamicPrice: this.store.findRecord('echo-pro/dynamic-price', scheduleTitleGuid)
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Started',
        'Entry Type': 'Edit',
        'Entry ID': model.dynamicPrice.guid,
        'Menu Component': 'Dynamic Pricing'
      });
    }
  });

  _exports.default = _default;
});