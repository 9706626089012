define("lh4/templates/components/ui/navigation/nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tDzOrsRi",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"name\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"hasNotification\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"i\",true],[10,\"class\",\"notification right icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/navigation/nav-item.hbs"
    }
  });

  _exports.default = _default;
});