define("lh4/templates/components/ui/icons/internet-payments/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b+J1avig",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"currentColor\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M19.1235 12.9359C19.1595 12.6359 19.1835 12.3239 19.1835 11.9999C19.1835 11.6759 19.1595 11.3639 19.1115 11.0639L21.1395 9.4799C21.3195 9.3359 21.3675 9.0719 21.2595 8.8679L19.3395 5.5439C19.2195 5.3279 18.9675 5.2559 18.7515 5.3279L16.3635 6.2879C15.8595 5.9039 15.3315 5.5919 14.7435 5.3519L14.3835 2.8079C14.3475 2.5679 14.1435 2.3999 13.9035 2.3999H10.0635C9.82351 2.3999 9.63151 2.5679 9.59551 2.8079L9.23551 5.3519C8.64751 5.5919 8.10751 5.9159 7.61551 6.2879L5.2275 5.3279C5.0115 5.2439 4.75951 5.3279 4.63951 5.5439L2.71951 8.8679C2.59951 9.0839 2.64751 9.3359 2.83951 9.4799L4.86751 11.0639C4.81951 11.3639 4.7835 11.6879 4.7835 11.9999C4.7835 12.3119 4.8075 12.6359 4.8555 12.9359L2.82751 14.5199C2.64751 14.6639 2.59951 14.9279 2.70751 15.1319L4.62751 18.4559C4.74751 18.6719 4.99951 18.7439 5.21551 18.6719L7.60351 17.7119C8.10751 18.0959 8.63551 18.4079 9.22351 18.6479L9.58351 21.1919C9.63151 21.4319 9.82351 21.5999 10.0635 21.5999H13.9035C14.1435 21.5999 14.3475 21.4319 14.3715 21.1919L14.7315 18.6479C15.3195 18.4079 15.8595 18.0839 16.3515 17.7119L18.7395 18.6719C18.9555 18.7559 19.2075 18.6719 19.3275 18.4559L21.2475 15.1319C21.3675 14.9159 21.3195 14.6639 21.1275 14.5199L19.1235 12.9359V12.9359ZM11.9835 15.5999C10.0035 15.5999 8.3835 13.9799 8.3835 11.9999C8.3835 10.0199 10.0035 8.3999 11.9835 8.3999C13.9635 8.3999 15.5835 10.0199 15.5835 11.9999C15.5835 13.9799 13.9635 15.5999 11.9835 15.5999Z\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/internet-payments/settings.hbs"
    }
  });

  _exports.default = _default;
});