define("lh4/routes/authenticated/epro/menu/modifiers", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    requiredPermissions: 'manage echo-pro/menu',
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: {
      name: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var location = this.location.model.id;
      var queryParams = {
        location: location,
        limit: 'all',
        order: 'name',
        sort: 'asc'
      };
      return Ember.RSVP.hash({
        modifiers: this.store.query('echo-pro/modifier', _objectSpread(_objectSpread({}, params), {}, {
          location: location
        })),
        modifierSets: this.store.query('echo-pro/modifier-set', _objectSpread(_objectSpread({}, queryParams), {}, {
          fields: 'name,guid,isActive'
        })),
        printers: this.store.query('echo-pro/printer', _objectSpread(_objectSpread({}, queryParams), {}, {
          fields: 'name,guid,active'
        })),
        modifierGuids: this.ajax.request('/api/v1/echo-pro/modifiers', {
          data: {
            fields: 'guid',
            limit: 'all',
            location: location,
            name: params.name
          }
        }).then(function (_ref) {
          var modifiers = _ref.modifiers;
          return modifiers.map(function (_ref2) {
            var guid = _ref2.guid;
            return guid;
          });
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        headers: [{
          field: 'name',
          name: this.intl.t('controllers:authenticated:epro:menu:modifiers:table:header:name'),
          width: 'six',
          sortable: true
        }, {
          field: 'kitchenName',
          name: this.intl.t('controllers:authenticated:epro:menu:modifiers:table:header:print-name'),
          width: 'six',
          sortable: true
        }, {
          field: 'active',
          name: this.intl.t('controllers:authenticated:epro:menu:modifiers:table:header:status'),
          width: 'four',
          sortable: true,
          type: 'active'
        }]
      });
    }
  });

  _exports.default = _default;
});