define("lh4/controllers/authenticated/locations/location/revenue-centers/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CONFIRM_DELETE_MESSAGE = 'Deleting a Revenue Center may impact historical reports. Would you like to proceed?';

  var _default = _generalFormController.default.extend({
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    isModelAndChangesetPristine: function isModelAndChangesetPristine() {
      return this.changeset.get('isPristine');
    },
    actions: {
      delete: function _delete() {
        var _this = this;

        if (this.isSaving || this.isDeleting) {
          return;
        }

        if (!window.confirm(CONFIRM_DELETE_MESSAGE)) {
          return;
        }

        this.set('isDeleting', true);
        this.model.revenueCenter.destroyRecord().then(function () {
          _this.setProperties({
            isDeleting: false,
            shouldCloseTray: true
          });
        }).catch(function (error) {
          _this.setProperties({
            isDeleting: false,
            error: error.message
          });
        });
      }
    }
  });

  _exports.default = _default;
});