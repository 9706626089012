define("lh4/routes/authenticated/epro/menu/items", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, {
    requiredPermissions: 'manage echo-pro/menu',
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    queryParams: {
      searchName: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      departmentGuid: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var location = this.location.model.id;
      var queryParams = {
        location: location,
        limit: 'all',
        order: 'name',
        sort: 'asc'
      };
      return Ember.RSVP.hash({
        items: this.store.query('echo-pro/item', _objectSpread(_objectSpread({}, params), {}, {
          location: location
        })),
        itemTags: this.store.query('echo-pro/item-tag', {
          location: location
        }),
        departments: this.store.query('echo-pro/department', _objectSpread(_objectSpread({}, queryParams), {}, {
          fields: 'name,guid,isActive'
        })),
        modifierSets: this.store.query('echo-pro/modifier-set', _objectSpread(_objectSpread({}, queryParams), {}, {
          fields: 'name,guid,isActive'
        })),
        taxes: this.store.query('echo-pro/tax', _objectSpread(_objectSpread({}, queryParams), {}, {
          fields: 'name,guid,active'
        })),
        courses: this.store.query('echo-pro/course', _objectSpread(_objectSpread({}, queryParams), {}, {
          fields: 'name,guid,isActive'
        })),
        printers: this.store.query('echo-pro/printer', _objectSpread(_objectSpread({}, queryParams), {}, {
          fields: 'name,guid,active'
        })),
        priceScheduleGroups: this.store.query('echo-pro/price-schedule-group', _objectSpread({}, queryParams)),
        revenueClasses: this.store.query('echo-pro/revenue-class', _objectSpread(_objectSpread({}, queryParams), {}, {
          fields: 'name,guid,isActive'
        })),
        itemGuids: this.ajax.request('/api/v1/echo-pro/items', {
          data: {
            fields: 'guid',
            limit: 'all',
            location: location,
            searchName: params.searchName,
            departmentGuid: params.departmentGuid
          }
        }).then(function (_ref) {
          var items = _ref.items;
          return items.map(function (_ref2) {
            var guid = _ref2.guid;
            return guid;
          });
        })
      });
    }
  });

  _exports.default = _default;
});