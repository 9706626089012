define("lh4/utils/date-moment", ["exports", "lh4/enums/date-range-title", "lh4/enums/duration", "lh4/utils/day-to-week-range", "moment", "lh4/utils/object"], function (_exports, _dateRangeTitle, _duration, _dayToWeekRange7, _moment, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.momentLocal = momentLocal;
  _exports.minutesSinceMidnight = minutesSinceMidnight;
  _exports.getStartOfBusinessDay = getStartOfBusinessDay;
  _exports.getEndOfBusinessDay = getEndOfBusinessDay;
  _exports.getWeekToDateRange = getWeekToDateRange;
  _exports.getSeconds = getSeconds;
  _exports.generateDateRanges = generateDateRanges;
  _exports.getDefaultDateRanges = getDefaultDateRanges;
  _exports.setDateToCurrentUTCTime = _exports.isDateEqualToCurrentDate = _exports.getNormalizedDateEnd = _exports.getNormalizedDateStart = _exports.Period = _exports.getReportingDateRangeOptionsByRangesEnabled = _exports.getReportingDateRangeOptionsWithoutYears = _exports.getReportingDateRangeOptionsDefault = void 0;

  /**
   * Local moment
   * Ignores default timezone - forces local user browser's timezone at all times.
   *
   * @param {Date|string} value - moment-compatible value
   * @returns {moment} local moment
   */
  function momentLocal(value) {
    return (0, _moment.default)(value).tz(_moment.default.tz.guess());
  }
  /**
   * Get minutes since midnight of passed in datetime
   *
   * @param {string|date|moment} datetime - datetime representation in different forms
   * @param {number} [offset] - if js Date is passed, timezone offset minutes is required
   * @returns {number} minutes since midnight
   */


  function minutesSinceMidnight(datetime, offset) {
    var time;

    if (datetime instanceof Date) {
      if (!offset) {
        throw new Error('If datetime is instance of Date, offset is required otherwise output will be in local TZ');
      }

      time = (0, _moment.default)(datetime).utcOffset(offset);
    } else {
      // parseZone makes moment keep original tz of datetime
      time = (0, _moment.default)(datetime).parseZone();
    }

    return time.hours() * _duration.default.HOUR.IN_MINUTES + time.minutes();
  }
  /**
   * Output start of business day based on location EOD and passed in moments TZ
   *
   * @param {string|date|moment} datetime - datetime representation in different forms
   * @param {string} [eod='04:00'] - location EOD
   * @returns {moment} start of business day of passed in datetime
   */


  function getStartOfBusinessDay(datetime) {
    var eod = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var time = (0, _moment.default)(datetime).clone().parseZone();

    var eodMoment = _moment.default.utc(eod, 'HH:mmA');

    var timeMinutesSinceMidnight = minutesSinceMidnight(time);
    var eodMinutesSinceMidnight = minutesSinceMidnight(eodMoment);
    time = time.startOf('day').add(eodMinutesSinceMidnight, 'minutes');

    if (timeMinutesSinceMidnight < eodMinutesSinceMidnight) {
      time = time.subtract(1, 'day');
    }

    return time;
  }
  /**
   * Output end of business day based on location EOD and passed in moment's TZ
   *
   * @param {string|date|moment} datetime - datetime representation in different forms
   * @param {string} [eod='04:00'] - location EOD
   * @returns {moment} end of business day of passed in datetime
   */


  function getEndOfBusinessDay(datetime) {
    var eod = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var time = (0, _moment.default)(datetime).clone().parseZone();

    var eodMoment = _moment.default.utc(eod, 'HH:mmA');

    var timeMinutesSinceMidnight = minutesSinceMidnight(time);
    var eodMinutesSinceMidnight = minutesSinceMidnight(eodMoment);
    time = time.endOf('day').add(eodMinutesSinceMidnight, 'minutes');

    if (timeMinutesSinceMidnight < eodMinutesSinceMidnight) {
      time = time.subtract(1, 'day');
    }

    return time;
  }
  /**
   * Get minutes since midnight from End Of Day
   *
   * @param {string} eod - end of business day, ie. 04:00PM
   * @returns {number} - minutes since midnight till End Of Day
   */


  function getEODMinutesSinceMidnight(eod) {
    var eodMoment = _moment.default.utc(eod, 'HH:mmA');

    return minutesSinceMidnight(eodMoment);
  }
  /**
   * Get Week To Date range adjusted to week start and End Of Day
   *
   * @param {string} eod - end of business day, ie. 04:00PM
   * @param {number} weekStart - start of week day, 0 - sunday, 1 - monday, etc
   * @returns {Array<Object>} - date ranges
   */


  function getWeekToDateRange(eod, weekStart) {
    var eodMinutesSinceMidnight = getEODMinutesSinceMidnight(eod);

    var _dayToWeekRange = (0, _dayToWeekRange7.default)((0, _moment.default)(), weekStart),
        from = _dayToWeekRange.from;

    return {
      title: _dateRangeTitle.DATE_RANGE_TITLE.WEEK.TO_DATE,
      startDate: from.add(eodMinutesSinceMidnight, 'minutes'),
      endDate: getEndOfBusinessDay((0, _moment.default)(), eod)
    };
  }
  /**
   * Get default datetime-range-picker ranges adjusted to end of business day
   *
   * @param {Object} options - user preferences
   * @param {string} options.eod - end of business day, ie. 04:00PM
   * @param {number} options.weekStart - start of week day, 0 - sunday, 1 - monday, etc
   * @returns {Array<Object>} - date ranges
   */


  var getReportingDateRangeOptionsDefault = function getReportingDateRangeOptionsDefault(_ref) {
    var eod = _ref.eod,
        weekStart = _ref.weekStart;
    var eodMinutesSinceMidnight = getEODMinutesSinceMidnight(eod);

    var _dayToWeekRange2 = (0, _dayToWeekRange7.default)((0, _moment.default)().subtract(1, 'weeks'), weekStart),
        startOfLastWeek = _dayToWeekRange2.from,
        endOfLastWeek = _dayToWeekRange2.to;

    var endDate = getEndOfBusinessDay((0, _moment.default)(), eod);
    return [{
      title: _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.CURRENT,
      startDate: getStartOfBusinessDay((0, _moment.default)(), eod),
      endDate: endDate
    }, {
      title: _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.LAST,
      startDate: getStartOfBusinessDay((0, _moment.default)(), eod).subtract(1, 'days'),
      endDate: getEndOfBusinessDay((0, _moment.default)(), eod).subtract(1, 'days')
    }, getWeekToDateRange(eod, weekStart), {
      title: _dateRangeTitle.DATE_RANGE_TITLE.WEEK.LAST,
      startDate: startOfLastWeek.add(eodMinutesSinceMidnight, 'minutes'),
      endDate: endOfLastWeek.add(eodMinutesSinceMidnight, 'minutes')
    }, {
      title: _dateRangeTitle.DATE_RANGE_TITLE.MONTH.TO_DATE,
      startDate: (0, _moment.default)().startOf('month').add(eodMinutesSinceMidnight, 'minutes'),
      endDate: endDate
    }, {
      title: _dateRangeTitle.DATE_RANGE_TITLE.MONTH.LAST,
      startDate: (0, _moment.default)().subtract(1, 'months').startOf('month').add(eodMinutesSinceMidnight, 'minutes'),
      endDate: (0, _moment.default)().subtract(1, 'months').endOf('month').add(eodMinutesSinceMidnight, 'minutes')
    }, {
      title: _dateRangeTitle.DATE_RANGE_TITLE.YEAR.TO_DATE,
      startDate: (0, _moment.default)().startOf('year').add(eodMinutesSinceMidnight, 'minutes'),
      endDate: endDate
    }, {
      title: _dateRangeTitle.DATE_RANGE_TITLE.YEAR.LAST,
      startDate: (0, _moment.default)().subtract(1, 'years').startOf('year').add(eodMinutesSinceMidnight, 'minutes'),
      endDate: (0, _moment.default)().subtract(1, 'years').endOf('year').add(eodMinutesSinceMidnight, 'minutes')
    }];
  };
  /**
   * Get datetime-range-picker ranges adjusted to end of business day without years options
   *
   * @param {Object} options - user preferences
   * @param {string} options.eod - end of business day, ie. 04:00PM
   * @param {number} options.weekStart - start of week day, 0 - sunday, 1 - monday, etc
   * @returns {Array<Object>} - date ranges
   */


  _exports.getReportingDateRangeOptionsDefault = getReportingDateRangeOptionsDefault;

  var getReportingDateRangeOptionsWithoutYears = function getReportingDateRangeOptionsWithoutYears(options) {
    var excludeTitles = Object.values(_dateRangeTitle.DATE_RANGE_TITLE.YEAR);
    return getReportingDateRangeOptionsDefault(options).filter(function (_ref2) {
      var title = _ref2.title;
      return !excludeTitles.includes(title);
    });
  };
  /**
   * Get datetime-range-picker ranges by range type
   *
   * @param {string} rangeType - range type
   * @param {Object} options - user preferences
   * @param {string} options.eod - end of business day, ie. 04:00PM
   * @param {number} options.weekStart - start of week day, 0 - sunday, 1 - monday, etc
   * @returns {Array<Object>} - date ranges
   */


  _exports.getReportingDateRangeOptionsWithoutYears = getReportingDateRangeOptionsWithoutYears;

  var getReportingDateRangeOptionsByRangesEnabled = function getReportingDateRangeOptionsByRangesEnabled(rangesEnabled, options) {
    if (rangesEnabled && rangesEnabled.length) {
      var rangeTitles = rangesEnabled.map(function (range) {
        return (0, _object.default)(range, _dateRangeTitle.DATE_RANGE_TITLE);
      });
      return getReportingDateRangeOptionsDefault(options).filter(function (_ref3) {
        var title = _ref3.title;
        return rangeTitles.includes(title);
      });
    }

    return [];
  };
  /**
   * Get time in seconds
   *
   * @param {string|date|moment} date - moment/Date/string
   * @returns {number} - returns time in seconds
   */


  _exports.getReportingDateRangeOptionsByRangesEnabled = getReportingDateRangeOptionsByRangesEnabled;

  function getSeconds(date) {
    if (date) {
      if (typeof date === 'string') {
        return (0, _moment.default)(date).unix();
      }

      if (_moment.default.isMoment(date)) {
        return date.unix();
      }

      if (date instanceof Date) {
        return Math.floor(date.getTime() / 1000);
      }
    }

    throw new Error('Provided invalid parameter');
  }
  /**
   * @typedef {Object} DateRange
   * @prop {string} title - title of date range
   * @prop {moment} startDate - start date
   * @prop {moment} endDate - end date
   */

  /**
   * @typedef {Object} DateRangeGeneratorOptions
   * @prop {string} [eod] - end of day time
   * @prop {number} [weekStart] - start of week: 0 - Sunday, 1 - Monday, etc
   */

  /**
   * @typedef {Function(options: DateRangeGeneratorOptions): DateRange} DateRangeGenerator
   */


  var Period = Object.freeze({
    TODAY: function TODAY() {
      var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref4.eod;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.TODAY
      };

      if (eod) {
        range.startDate = getStartOfBusinessDay((0, _moment.default)(), eod);
        range.endDate = getEndOfBusinessDay((0, _moment.default)(), eod);
      } else {
        range.startDate = (0, _moment.default)().startOf('day');
        range.endDate = (0, _moment.default)().endOf('day');
      }

      return range;
    },
    YESTERDAY: function YESTERDAY() {
      var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref5.eod;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.YESTERDAY
      };

      if (eod) {
        range.startDate = getStartOfBusinessDay((0, _moment.default)(), eod).subtract(1, 'day');
        range.endDate = getEndOfBusinessDay((0, _moment.default)(), eod).subtract(1, 'day');
      } else {
        range.startDate = (0, _moment.default)().startOf('day').subtract(1, 'day');
        range.endDate = (0, _moment.default)().endOf('day').subtract(1, 'day');
      }

      return range;
    },
    CURRENT_BUSINESS_DAY: function CURRENT_BUSINESS_DAY() {
      var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref6.eod;

      return {
        title: _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.CURRENT,
        startDate: getStartOfBusinessDay((0, _moment.default)(), eod),
        endDate: getEndOfBusinessDay((0, _moment.default)(), eod)
      };
    },
    LAST_BUSINESS_DAY: function LAST_BUSINESS_DAY() {
      var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref7.eod;

      return {
        title: _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.LAST,
        startDate: getStartOfBusinessDay((0, _moment.default)(), eod).subtract(1, 'day'),
        endDate: getEndOfBusinessDay((0, _moment.default)(), eod).subtract(1, 'day')
      };
    },
    WEEK_TO_DATE: function WEEK_TO_DATE() {
      var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref8.eod,
          weekStart = _ref8.weekStart;

      var _dayToWeekRange3 = (0, _dayToWeekRange7.default)((0, _moment.default)(), weekStart),
          from = _dayToWeekRange3.from;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.WEEK.TO_DATE
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = from.add(eodMinutes, 'minutes');
        range.endDate = getEndOfBusinessDay((0, _moment.default)(), eod);
      } else {
        range.startDate = from;
        range.endDate = (0, _moment.default)().endOf('day');
      }

      return range;
    },
    THIS_WEEK: function THIS_WEEK() {
      var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref9.eod,
          weekStart = _ref9.weekStart;

      var _dayToWeekRange4 = (0, _dayToWeekRange7.default)((0, _moment.default)(), weekStart),
          from = _dayToWeekRange4.from,
          to = _dayToWeekRange4.to;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.WEEK.THIS
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = from.add(eodMinutes, 'minutes');
        range.endDate = to.add(eodMinutes, 'minutes');
      } else {
        range.startDate = from;
        range.endDate = to;
      }

      return range;
    },
    LAST_WEEK: function LAST_WEEK() {
      var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref10.eod,
          weekStart = _ref10.weekStart;

      var _dayToWeekRange5 = (0, _dayToWeekRange7.default)((0, _moment.default)().subtract(1, 'week'), weekStart),
          from = _dayToWeekRange5.from,
          to = _dayToWeekRange5.to;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.WEEK.LAST
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = from.add(eodMinutes, 'minutes');
        range.endDate = to.add(eodMinutes, 'minutes');
      } else {
        range.startDate = from;
        range.endDate = to;
      }

      return range;
    },
    TWO_WEEKS_TO_DATE: function TWO_WEEKS_TO_DATE() {
      var _ref11 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref11.eod,
          weekStart = _ref11.weekStart;

      var _dayToWeekRange6 = (0, _dayToWeekRange7.default)((0, _moment.default)().subtract(1, 'week'), weekStart),
          from = _dayToWeekRange6.from;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.WEEKS.TWO_TO_DATE
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = from.add(eodMinutes, 'minutes');
        range.endDate = getEndOfBusinessDay((0, _moment.default)(), eod);
      } else {
        range.startDate = from;
        range.endDate = (0, _moment.default)().endOf('day');
      }

      return range;
    },
    MONTH_TO_DATE: function MONTH_TO_DATE() {
      var _ref12 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref12.eod;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.MONTH.TO_DATE
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = (0, _moment.default)().startOf('month').add(eodMinutes, 'minutes');
        range.endDate = getEndOfBusinessDay((0, _moment.default)(), eod);
      } else {
        range.startDate = (0, _moment.default)().startOf('month');
        range.endDate = (0, _moment.default)().endOf('day');
      }

      return range;
    },
    THIS_MONTH: function THIS_MONTH() {
      var _ref13 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref13.eod;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.MONTH.THIS
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = (0, _moment.default)().startOf('month').add(eodMinutes, 'minutes');
        range.endDate = (0, _moment.default)().endOf('month').add(eodMinutes, 'minutes');
      } else {
        range.startDate = (0, _moment.default)().startOf('month');
        range.endDate = (0, _moment.default)().endOf('month');
      }

      return range;
    },
    LAST_MONTH: function LAST_MONTH() {
      var _ref14 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref14.eod;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.MONTH.LAST
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = (0, _moment.default)().subtract(1, 'month').startOf('month').add(eodMinutes, 'minutes');
        range.endDate = (0, _moment.default)().subtract(1, 'month').endOf('month').add(eodMinutes, 'minutes');
      } else {
        range.startDate = (0, _moment.default)().subtract(1, 'month').startOf('month');
        range.endDate = (0, _moment.default)().subtract(1, 'month').endOf('month');
      }

      return range;
    },
    SEVEN_DAYS: function SEVEN_DAYS() {
      var _ref15 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref15.eod;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.SEVEN_DAYS.LAST
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = (0, _moment.default)().subtract(7, 'day').add(eodMinutes, 'minutes');
        range.endDate = (0, _moment.default)().add(eodMinutes, 'minutes');
      } else {
        range.startDate = (0, _moment.default)().subtract(7, 'day').startOf('day');
        range.endDate = (0, _moment.default)().endOf('day');
      }

      return range;
    },
    THIRTY_DAYS: function THIRTY_DAYS() {
      var _ref16 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref16.eod;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.THIRTY_DAYS.LAST
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = (0, _moment.default)().subtract(30, 'day').add(eodMinutes, 'minutes');
        range.endDate = (0, _moment.default)().add(eodMinutes, 'minutes');
      } else {
        range.startDate = (0, _moment.default)().subtract(30, 'day').startOf('day');
        range.endDate = (0, _moment.default)().endOf('day');
      }

      return range;
    },
    NINETY_DAYS: function NINETY_DAYS() {
      var _ref17 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref17.eod;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.NINETY_DAYS.LAST
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = (0, _moment.default)().subtract(90, 'day').add(eodMinutes, 'minutes');
        range.endDate = (0, _moment.default)().add(eodMinutes, 'minutes');
      } else {
        range.startDate = (0, _moment.default)().subtract(90, 'day').startOf('day');
        range.endDate = (0, _moment.default)().endOf('day');
      }

      return range;
    },
    YEAR_TO_DATE: function YEAR_TO_DATE() {
      var _ref18 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref18.eod;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.YEAR.TO_DATE
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = (0, _moment.default)().startOf('year').add(eodMinutes, 'minutes');
        range.endDate = getEndOfBusinessDay((0, _moment.default)(), eod);
      } else {
        range.startDate = (0, _moment.default)().startOf('year');
        range.endDate = (0, _moment.default)().endOf('day');
      }

      return range;
    },
    THIS_YEAR: function THIS_YEAR() {
      var _ref19 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref19.eod;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.YEAR.THIS
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = (0, _moment.default)().startOf('year').add(eodMinutes, 'minutes');
        range.endDate = (0, _moment.default)().endOf('year').add(eodMinutes, 'minutes');
      } else {
        range.startDate = (0, _moment.default)().startOf('year');
        range.endDate = (0, _moment.default)().endOf('year');
      }

      return range;
    },
    LAST_YEAR: function LAST_YEAR() {
      var _ref20 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          eod = _ref20.eod;

      var range = {
        title: _dateRangeTitle.DATE_RANGE_TITLE.YEAR.LAST
      };

      if (eod) {
        var eodMinutes = getEODMinutesSinceMidnight(eod);
        range.startDate = (0, _moment.default)().subtract(1, 'year').startOf('year').add(eodMinutes, 'minutes');
        range.endDate = (0, _moment.default)().subtract(1, 'year').endOf('year').add(eodMinutes, 'minutes');
      } else {
        range.startDate = (0, _moment.default)().subtract(1, 'year').startOf('year');
        range.endDate = (0, _moment.default)().subtract(1, 'year').endOf('year');
      }

      return range;
    }
  });
  /**
   * Generate date ranges for the specified periods
   *
   * @param {Array<string>} periods - date range periods to be generated
   * @param {DateRangeGeneratorOptions} [options] - options to generators
   * @returns {Array<DateRange>} date ranges
   */

  _exports.Period = Period;

  function generateDateRanges(periods, options) {
    return periods.map(function (period) {
      return period(options);
    });
  }
  /**
   * Get default date ranges
   *
   * @returns {Array<DateRange>} date ranges
   */


  function getDefaultDateRanges() {
    return generateDateRanges([Period.TODAY, Period.YESTERDAY, Period.THIS_WEEK, Period.THIS_MONTH, Period.LAST_MONTH, Period.NINETY_DAYS, Period.THIS_YEAR]);
  }

  var dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
  /**
   * Get date string normalized as start date in UTC
   *
   * @param {string} date - date to normalize
   * @param {boolean} [hasToParseTimezone = true] - true - parse timezone, false - skip timezone parsing
   * @param {boolean} [hasToFormat = true] - true - return as string, false - return as moment instance
   * @returns {string | moment.Moment} - date formatted as ISO string and seconds/milliseconds normalized
   */

  var getNormalizedDateStart = function getNormalizedDateStart(date) {
    var hasToParseTimezone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var hasToFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var dateNormalized = hasToParseTimezone ? (0, _moment.default)(date).parseZone() : (0, _moment.default)(date);
    var start = dateNormalized.set('seconds', 0).set('milliseconds', 0);
    return hasToFormat ? start.format(dateFormat) : start;
  };
  /**
   * Get date string normalized as end date in UTC
   *
   * @param {string} date - date to normalize
   * @param {boolean} [hasToParseTimezone = true] - true - parse timezone, false - skip timezone parsing
   * @param {boolean} [hasToFormat = true] - true - return as string, false - return as moment instance
   * @returns {string | moment.Moment} - date formatted as ISO string and seconds/milliseconds normalized
   */


  _exports.getNormalizedDateStart = getNormalizedDateStart;

  var getNormalizedDateEnd = function getNormalizedDateEnd(date) {
    var hasToParseTimezone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var hasToFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var dateNormalized = hasToParseTimezone ? (0, _moment.default)(date).parseZone() : (0, _moment.default)(date);
    var minutes = dateNormalized.minutes();
    var seconds = dateNormalized.seconds() === 0 ? 0 : 59;
    var end = dateNormalized.set('seconds', seconds).set('milliseconds', seconds === 59 ? 999 : 0);

    if (minutes === 0) {
      dateNormalized.set('seconds', 0).set('milliseconds', 0);
    }

    if (minutes === 59) {
      dateNormalized.set('seconds', 59).set('milliseconds', 999);
    }

    return hasToFormat ? end.format(dateFormat) : end;
  };
  /**
   * Check if passed date is equal to current date
   *
   * @param {moment} date
   * @returns {boolean}
   */


  _exports.getNormalizedDateEnd = getNormalizedDateEnd;

  var isDateEqualToCurrentDate = function isDateEqualToCurrentDate(date) {
    var currentDate = _moment.default.utc();

    return date.isSame(currentDate, 'day');
  };
  /**
   * Set current time to passed date
   *
   * @param {moment} date
   * @returns {moment}
   */


  _exports.isDateEqualToCurrentDate = isDateEqualToCurrentDate;

  var setDateToCurrentUTCTime = function setDateToCurrentUTCTime(date) {
    var currentDate = _moment.default.utc();

    date.set({
      hour: currentDate.hour(),
      minute: currentDate.minute(),
      second: currentDate.second()
    });
    return date;
  };

  _exports.setDateToCurrentUTCTime = setDateToCurrentUTCTime;
});