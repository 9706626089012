define("lh4/mirage/routes/business-settings", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageBusinessSettings;

  /* eslint require-jsdoc: "off" */
  function setMirageBusinessSettings(serverObject) {
    var server = serverObject;
    server.namespace = '/api/v2';
    server.get('/business-settings/:locationId', function () {
      return {
        gatewayEodTime: '11:00 PM',
        depositFundingTime: '48 Hour Funding (48)',
        skyTabPosEodTime: '04:00 AM',
        timezone: 'America/New_York',
        weekStart: '1'
      };
    });
    server.post('/business-settings', function (schema, request) {
      var body = JSON.parse(request.requestBody);

      if (body.gatewayEodTime && body.weekStart && body.locationId) {
        return new _emberCliMirage.Response(200, {}, {
          status: 'ok'
        });
      }

      return new _emberCliMirage.Response(400, {}, {
        errors: ['Invalid data']
      });
    });
    server.get('/business-settings/:locationId/gateway-eod-time', function () {
      return {
        gatewayEodTime: '11:00 PM'
      };
    });
    server.post('/business-settings/gateway-eod-time', function (schema, request) {
      var body = JSON.parse(request.requestBody);

      if (body.locations) {
        return new _emberCliMirage.Response(200, {}, {
          101: '11:00 PM',
          102: '12:00 PM'
        });
      }

      return new _emberCliMirage.Response(400, {}, {
        errors: ['Invalid data']
      });
    });
    server.namespace = '/api/v1';
  }
});