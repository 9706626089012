define("lh4/controllers/authenticated/epro/menu/items", ["exports", "lh4/controllers/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend({
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    queryParams: ['departmentGuid', 'searchName', 'limit', 'order', 'offset'],
    headers: [{
      field: 'name',
      name: 'Name',
      width: 'five',
      sortable: true
    }, {
      field: 'departments.name',
      name: 'Department',
      width: 'four',
      sortable: true
    }, {
      field: 'qtyOnHand',
      name: 'In stock',
      width: 'two',
      sortable: true,
      type: 'number'
    }, {
      field: 'price',
      name: 'Price',
      width: 'two',
      sortable: true,
      type: 'number'
    }, {
      field: 'isActive',
      name: 'Status',
      width: 'three',
      type: 'active',
      sortable: true
    }],
    includeList: [],
    selectionCount: 0,
    init: function init() {
      this._super.apply(this, arguments);

      this.locationChanged();
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      var _this$location, _this$location$model;

      this._super(); // reset all bulk selections when location is changed


      if ((_this$location = this.location) !== null && _this$location !== void 0 && (_this$location$model = _this$location.model) !== null && _this$location$model !== void 0 && _this$location$model.id && this.location.model.id !== this.bulkSelectLocationId) {
        this.setProperties({
          includeList: [],
          selectionCount: 0,
          bulkSelectLocationId: this.location.model.id
        });
      }
    }),
    isMultipleItemsSelected: Ember.computed('selectionCount', function getter() {
      return this.selectionCount > 1;
    }),
    departmentOptions: Ember.computed('model.departments', 'departmentGuid', function getter() {
      var _this = this;

      var isAllSelected = typeof this.departmentGuid === 'undefined' || this.departmentGuid === null;
      return (this.model.departments || []).map(function (department) {
        var _this$departmentGuid;

        return {
          id: department.guid,
          name: department.name,
          isChecked: isAllSelected || ((_this$departmentGuid = _this.departmentGuid) === null || _this$departmentGuid === void 0 ? void 0 : _this$departmentGuid.includes(department.guid)),
          isActive: department.isActive
        };
      });
    }),
    actions: {
      handleTermUpdated: function handleTermUpdated(searchName) {
        this.setProperties({
          searchName: searchName,
          offset: 0
        });
      },
      handleDepartmentChange: function handleDepartmentChange(selectedIds) {
        var isAllSelected = selectedIds.length === this.departmentOptions.length;
        this.setProperties({
          offset: 0,
          departmentGuid: !isAllSelected ? selectedIds : undefined
        });
      },
      handleBulkSelectionChanged: function handleBulkSelectionChanged(includeList, selectionCount) {
        this.setProperties({
          includeList: includeList,
          selectionCount: selectionCount
        });
      },
      handleBulkEditClicked: function handleBulkEditClicked() {
        if (this.isMultipleItemsSelected && this.can.can('bulk-edit in echo-pro/menu')) {
          this.transitionToRoute('authenticated.epro.menu.items.bulk-edit');
        }
      }
    }
  });

  _exports.default = _default;
});