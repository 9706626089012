define("lh4/templates/components/internet-payments/color-dot-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P0nHQfmD",
    "block": "{\"symbols\":[\"@className\",\"@reverse\"],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"color-dot-label\",[28,\"if\",[[23,2,[]],\" reverse\"],null],[28,\"if\",[[23,1,[]],[28,\"concat\",[\" \",[23,1,[]]],null]],null]]]],[8],[0,\"\\n\\t\"],[7,\"span\",true],[11,\"class\",[29,[\"dot \",[28,\"lowercase\",[[23,0,[\"args\",\"value\"]]],null]]]],[8],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"color-dot-value\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"args\",\"label\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"capitalize-first\",[[23,0,[\"args\",\"label\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"capitalize-first\",[[23,0,[\"args\",\"value\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[23,0,[\"args\",\"secondaryLabel\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"color-dot-second-label\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"capitalize-first\",[[23,0,[\"args\",\"secondaryLabel\"]]],null],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/color-dot-label.hbs"
    }
  });

  _exports.default = _default;
});