define("lh4/templates/authenticated/st-jude/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Eg/K0ti+",
    "block": "{\"symbols\":[],\"statements\":[[0,\"https://jira.shift4payments.com/browse/LH-11232\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/st-jude/index.hbs"
    }
  });

  _exports.default = _default;
});