define("lh4/routes/authenticated/location-settings", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, {
    titleToken: 'Location',
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    queryParams: {
      searchName: {
        refreshModel: true
      },
      statusFilter: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      if (!this.location.model.isCorpHq) {
        this.transitionTo('authenticated.dashboard2');
      }
    },
    model: function model(params) {
      var routeName = this.routeName;
      var currCorpId = this.location.model.corpId;
      var settingsController = this.controllerFor(routeName);
      var model = settingsController.model;
      var locationOverview = model && model.locationOverview;

      if (!locationOverview || settingsController !== null && settingsController !== void 0 && settingsController.get('shouldUpdate')) {
        locationOverview = this.ajax.request("/api/v1/corporations/".concat(this.location.model.corpId, "/location-overview"), {
          type: 'GET',
          data: _objectSpread({}, params)
        }).then(function (locations) {
          var filteredLocationOverviews = [];
          locations.forEach(function (loc) {
            filteredLocationOverviews.push({
              name: loc.name,
              id: loc.id,
              isUpToDate: loc.isUpToDate,
              isLocationManaged: loc.isLocationManaged,
              priceGroupName: loc.priceGroupName
            });
          });
          settingsController === null || settingsController === void 0 ? void 0 : settingsController.set('shouldUpdate', false);
          return {
            filteredLocationOverviews: filteredLocationOverviews
          };
        });
      }

      return Ember.RSVP.hash({
        locations: this.modelFor('authenticated').locations.filter(function (_ref) {
          var corpId = _ref.corpId,
              isCorpHq = _ref.isCorpHq;
          return +corpId === +currCorpId && !isCorpHq;
        }),
        locationOverview: locationOverview
      });
    }
  });

  _exports.default = _default;
});