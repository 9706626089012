define("lh4/routes/authenticated/skytab-website", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, {
    titleToken: '',
    // Intentionally Left Blank
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    // This service proxies the same endpoint necessary to initialize the skytab website iframe - LH-22687
    giftCard: Ember.inject.service('gift-card'),
    skytabWebsite: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('manage skytab-website') && this.can.cannot('view launch control in launch-control')) {
        this.transitionTo('authenticated.dashboard2');
      }
    },
    model: function model() {
      var locationId = this.location.model.id;
      return Ember.RSVP.hash({
        locationId: locationId,
        skytabWebsiteIframe: this.giftCard.getGiftCardIframeData(locationId),
        skytabWebsiteInstalled: this.skytabWebsite.tryInstallThenCheckStatus(locationId)
      });
    }
  });

  _exports.default = _default;
});