define("lh4/routes/authenticated/epro/menu/departments/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    intradayPeriod: Ember.inject.service('intraday-period'),
    modelName: 'department',
    model: function model() {
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        department: this.store.createRecord('echo-pro/department', {
          locationId: this.location.model.id
        }),
        items: this.modelFor('authenticated.epro.menu.departments').items,
        courses: this.modelFor('authenticated.epro.menu.departments').courses,
        stylesheets: this.isEproColorPickerFeatureFlagEnabled() ? this.store.findAll('echo-pro/stylesheet') : null,
        dayPartOptions: this.intradayPeriod.getDayPartsData(this.controller, location),
        revenueCenterOptions: this.store.query('echo-pro/revenue-center', {
          location: location
        }).then(function (revenueCenters) {
          return revenueCenters.map(function (_ref) {
            var guid = _ref.guid,
                revenueCenterName = _ref.revenueCenterName;
            return {
              value: guid,
              name: revenueCenterName
            };
          });
        }),
        timeOptions: ['All Day'],
        dynamicPrices: this.store.query('echo-pro/dynamic-price', {
          location: this.location.model.id,
          limit: 100,
          order: 'isActive',
          sort: 'desc'
        })
      });
    },
    setupController: function setupController(theController, model) {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [theController, model].concat(args));

      var controller = theController;
      controller.changeset.dynamicPricingChangesets = model.department.dynamicPricing.map(this.buildChildChangeset);
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Started',
        'Entry Type': 'New',
        'Entry ID': model.department.guid,
        'Menu Component': 'Department'
      });
      var activeDynamicPrice = model.dynamicPrices.find(function (x) {
        return x.isActive;
      });
      controller.setProperties({
        buildChildChangeset: function buildChildChangeset(childModel) {
          return _this.buildChildChangeset(childModel);
        },
        firstDynamicPriceGuid: activeDynamicPrice === null || activeDynamicPrice === void 0 ? void 0 : activeDynamicPrice.guid
      });
    }
  });

  _exports.default = _default;
});