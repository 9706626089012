define("lh4/components/echo-pro/employee-schedule/header", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    saveAsTemplate: '',
    applyTemplate: '',
    listTemplates: '',
    printSchedule: '',
    emailSchedule: '',
    createShift: '',
    clearAll: '',
    timezone: _moment.default.tz.guess(),
    actions: {
      onWeekSelection: function onWeekSelection(_ref) {
        var from = _ref.from,
            to = _ref.to;
        this.get('onSelection')(from, to);
      }
    }
  });

  _exports.default = _default;
});