define("lh4/enums/transactions-filters", ["exports", "lh4/enums/ip-risk-statuses"], function (_exports, _ipRiskStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EVENT_STATUS_OPTIONS_BASE = _exports.STATUS_OPTIONS_BASE = _exports.RISK_OPTIONS_BASE = _exports.TYPE_OPTIONS_BASE = _exports.CARD_TYPES_OPTIONS_BASE = void 0;
  var TYPE_OPTIONS_BASE = Object.freeze([{
    id: 'AUTH',
    name: 'Auth',
    isChecked: false
  }, {
    id: 'SALE',
    name: 'Sale',
    isChecked: false
  }, {
    id: 'REFUND',
    name: 'Refund',
    isChecked: false
  }, {
    id: 'VOID',
    name: 'Void',
    isChecked: false
  }]);
  _exports.TYPE_OPTIONS_BASE = TYPE_OPTIONS_BASE;
  var RISK_OPTIONS_BASE = Object.freeze([{
    id: _ipRiskStatuses.default.APPROVED,
    name: 'Approved',
    isChecked: false
  }, {
    id: _ipRiskStatuses.default.DECLINED,
    name: 'Declined',
    isChecked: false
  }, {
    id: _ipRiskStatuses.default.REVIEW,
    name: 'Review',
    isChecked: false
  }, {
    id: _ipRiskStatuses.default.ESCALATION,
    name: 'Escalation',
    isChecked: false
  }]);
  _exports.RISK_OPTIONS_BASE = RISK_OPTIONS_BASE;
  var STATUS_OPTIONS_BASE = Object.freeze([{
    id: 'SETTLED',
    name: 'Settled',
    isChecked: false
  }, {
    id: 'UNSETTLED',
    name: 'Unsettled',
    isChecked: false
  }, {
    id: 'DECLINED',
    name: 'Declined',
    isChecked: false
  }]);
  _exports.STATUS_OPTIONS_BASE = STATUS_OPTIONS_BASE;
  var CARD_TYPES_OPTIONS_BASE = Object.freeze([{
    id: 'Credit Card',
    name: 'Credit Card',
    isChecked: false
  }, {
    id: 'Debit Card',
    name: 'Debit Card',
    isChecked: false
  }, {
    id: 'Prepaid Debit Card',
    name: 'Prepaid Debit Card',
    isChecked: false
  }, {
    id: 'Gift Card',
    name: 'Gift Card',
    isChecked: false
  }, {
    id: 'Unknown',
    name: 'Unknown',
    isChecked: false
  }]);
  _exports.CARD_TYPES_OPTIONS_BASE = CARD_TYPES_OPTIONS_BASE;
  var EVENT_STATUS_OPTIONS_BASE = Object.freeze([{
    id: 'RISK_APPROVED',
    name: 'Risk Approved',
    isChecked: false
  }, {
    id: 'RISK_DECLINED',
    name: 'Risk Declined',
    isChecked: false
  }, {
    id: 'RISK_REVIEW',
    name: 'Risk Review',
    isChecked: false
  }, {
    id: 'RISK_ESCALATION',
    name: 'Risk Escalation',
    isChecked: false
  }, {
    id: 'CARD_VERIFY_APPROVED',
    name: 'Card Verify Approved',
    isChecked: false
  }, {
    id: 'CARD_VERIFY_DECLINED',
    name: 'Card Verify Declined',
    isChecked: false
  }, {
    id: 'AUTH_APPROVED',
    name: 'Auth Approved',
    isChecked: false
  }, {
    id: 'AUTH_DECLINED',
    name: 'Auth Declined',
    isChecked: false
  }, {
    id: 'CAPTURED',
    name: 'Captured',
    isChecked: false
  }, {
    id: 'VOIDED',
    name: 'Voided',
    isChecked: false
  }, {
    id: 'SENT_FOR_SETTLEMENT',
    name: 'Sent for Settlement',
    isChecked: false
  }, {
    id: 'SETTLED',
    name: 'Settled',
    isChecked: false
  }, {
    id: 'SETTLEMENT_FAILED',
    name: 'Settlement Failed',
    isChecked: false
  }, {
    id: 'AUTH_EXPIRED',
    name: 'Auth Expired',
    isChecked: false
  }, {
    id: 'REFUND_REQUESTED',
    name: 'Refund Requested',
    isChecked: false
  }, {
    id: 'REFUNDED',
    name: 'Refunded',
    isChecked: false
  }, {
    id: 'REFUND_FAILED',
    name: 'Refund Failed',
    isChecked: false
  }, {
    id: 'NOTIFICATION_OF_DISPUTE',
    name: 'Notification of Dispute',
    isChecked: false
  }, {
    id: 'RETRIEVAL_REQUEST',
    name: 'Retrieval Request',
    isChecked: false
  }]);
  _exports.EVENT_STATUS_OPTIONS_BASE = EVENT_STATUS_OPTIONS_BASE;
});