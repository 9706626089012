define("lh4/models/solo/item", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "lh4/utils/is-positive-decimal-or-zero", "lh4/utils/is-positive-percentage"], function (_exports, _model, _attr, _emberCpValidations, _isPositiveDecimalOrZero, _isPositivePercentage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TAX_TYPES = void 0;
  var TAX_TYPES = {
    FLAT: 'FLAT',
    PERCENT: 'PERCENT'
  };
  _exports.TAX_TYPES = TAX_TYPES;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Item Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    basePrice: {
      description: 'Base Price',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        positive: true,
        allowString: true,
        allowBlank: false,
        gt: 0,
        gte: 0
      }), (0, _emberCpValidations.validator)(function (value) {
        return (0, _isPositiveDecimalOrZero.default)(value) || 'Value must be a positive number with no more than two decimal places.';
      })]
    },
    taxValue: {
      description: 'Tax Value',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        positive: true,
        allowString: true,
        allowBlank: false,
        gte: 0
      }), (0, _emberCpValidations.validator)(function (value) {
        return (0, _isPositivePercentage.default)(value, 3);
      }, {
        disabled: Ember.computed.equal('model.taxType', 'FLAT')
      }), (0, _emberCpValidations.validator)(function (value) {
        return (0, _isPositiveDecimalOrZero.default)(value) || 'Value must be positive and can only contain two decimal numbers.';
      }, {
        disabled: Ember.computed.equal('model.taxType', 'PERCENT')
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    basePrice: (0, _attr.default)('number'),
    taxType: (0, _attr.default)('string', {
      defaultValue: TAX_TYPES.FLAT
    }),
    taxValue: (0, _attr.default)('number'),
    fullPrice: (0, _attr.default)('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});