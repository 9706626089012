define("lh4/controllers/authenticated/reporting", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    branding: _environment.default.branding,
    brandNames: _environment.default.BrandNames,
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    routeData: Ember.computed('location.model.merchantId', 'launchDarkly.featureFlags', function routeData() {
      var availableRoutes = [{
        name: 'authenticated.reporting.reports',
        title: 'Reports'
      }];

      if (this.can.can('manage virtual-gift-card in general')) {
        availableRoutes.push({
          name: 'authenticated.reporting.gift-card-reporting',
          title: 'Shift4Gift Reporting'
        });
      }

      if (this.can.can('create own subscriptions in general')) {
        availableRoutes.push({
          name: 'authenticated.reporting.subscriptions',
          title: 'Subscriptions'
        });
      }

      if (this.can.can('manage fiscal-reporting')) {
        availableRoutes.push({
          name: 'authenticated.reporting.fiscal-reporting',
          title: 'Electronic Journal'
        });
      }

      return availableRoutes;
    }),
    disabledBackElements: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.get('launchDarkly.featureFlags');
    }
  });

  _exports.default = _default;
});