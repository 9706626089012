define("lh4/routes/authenticated/epro/payments/tenders/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    modelName: 'tender',
    model: function model(_ref) {
      var tenderId = _ref.tenderId;
      return Ember.RSVP.hash({
        tender: this.store.findRecord('echo-pro/tender', tenderId),
        jobs: this.modelFor('authenticated.epro.payments.tenders').jobs,
        businessSettings: this.store.queryRecord('echo-pro/setting', {
          location: this.location.model.id,
          type: ['accounting', 'business']
        })
      });
    }
  });

  _exports.default = _default;
});