define("lh4/components/ui/s4/cards/generic-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @isBanner}}
  	<Ui::S4::Cards::Banner::Banner
  		@class={{@class}}
  		@title={{@title}}
  		@stepCount={{@stepCount}}
  		@imageSrc={{@imageSrc}}
  		@subtitle={{@subtitle}}
  		@totalProgressIndicator={{@totalProgressIndicator}}
  		@currentProgressIndicator={{@currentProgressIndicator}}
  		@required={{@required}}
  		@bannerSubtitle={{@bannerSubtitle}}
  		@isSelected={{@isSelected}}
  		@goLiveDate={{@goLiveDate}}
  		@data-test-card={{@data-test-card}}
  		@showBannerFrame={{@showBannerFrame}}
  		@bannerFrameTitle={{@bannerFrameTitle}}
  		@bannerFrameSubtitle={{@bannerFrameSubtitle}}
  		@progressPercentage={{@progressPercentage}}
  		@isBanner={{@isBanner}}
  	/>
  {{else if @isNotification}}
  	<Ui::S4::Cards::Notification::Notification
  		@class={{@class}}
  		@title={{@title}}
  		@stepCount={{@stepCount}}
  		@subtitle={{@subtitle}}
  		@required={{@required}}
  		@isSelected={{@isSelected}}
  		@data-test-card={{@data-test-card}}
  	/>
  {{else}}
  	<Ui::S4::Cards::Card::Card
  		@class={{@class}}
  		@title={{@title}}
  		@stepCount={{@stepCount}}
  		@imageSrc={{@imageSrc}}
  		@progressSrc={{@progressSrc}}
  		@subtitle={{@subtitle}}
  		@totalProgressIndicator={{@totalProgressIndicator}}
  		@currentProgressIndicator={{@currentProgressIndicator}}
  		@required={{@required}}
  		@isSelected={{@isSelected}}
  		@goLiveDate={{@goLiveDate}}
  		@progress={{@progress}}
  		@data-test-card={{@data-test-card}}
  		@moduleRoute={{@moduleRoute}}
  		@isEnabled={{@isEnabled}}
  	/>
  {{/if}}
  */
  {
    id: "KvOWKOeo",
    block: "{\"symbols\":[\"@class\",\"@title\",\"@stepCount\",\"@imageSrc\",\"@progressSrc\",\"@subtitle\",\"@totalProgressIndicator\",\"@currentProgressIndicator\",\"@required\",\"@isSelected\",\"@goLiveDate\",\"@progress\",\"@data-test-card\",\"@moduleRoute\",\"@isEnabled\",\"@isNotification\",\"@bannerSubtitle\",\"@showBannerFrame\",\"@bannerFrameTitle\",\"@bannerFrameSubtitle\",\"@progressPercentage\",\"@isBanner\"],\"statements\":[[4,\"if\",[[23,22,[]]],null,{\"statements\":[[0,\"\\t\"],[5,\"ui/s4/cards/banner/banner\",[],[[\"@class\",\"@title\",\"@stepCount\",\"@imageSrc\",\"@subtitle\",\"@totalProgressIndicator\",\"@currentProgressIndicator\",\"@required\",\"@bannerSubtitle\",\"@isSelected\",\"@goLiveDate\",\"@data-test-card\",\"@showBannerFrame\",\"@bannerFrameTitle\",\"@bannerFrameSubtitle\",\"@progressPercentage\",\"@isBanner\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,17,[]],[23,10,[]],[23,11,[]],[23,13,[]],[23,18,[]],[23,19,[]],[23,20,[]],[23,21,[]],[23,22,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,16,[]]],null,{\"statements\":[[0,\"\\t\"],[5,\"ui/s4/cards/notification/notification\",[],[[\"@class\",\"@title\",\"@stepCount\",\"@subtitle\",\"@required\",\"@isSelected\",\"@data-test-card\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,6,[]],[23,9,[]],[23,10,[]],[23,13,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[5,\"ui/s4/cards/card/card\",[],[[\"@class\",\"@title\",\"@stepCount\",\"@imageSrc\",\"@progressSrc\",\"@subtitle\",\"@totalProgressIndicator\",\"@currentProgressIndicator\",\"@required\",\"@isSelected\",\"@goLiveDate\",\"@progress\",\"@data-test-card\",\"@moduleRoute\",\"@isEnabled\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]],[23,14,[]],[23,15,[]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/cards/generic-card.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});