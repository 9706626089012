define("lh4/models/customer-engagement/campaign", ["exports", "ember-data", "moment", "ember-cp-validations", "lh4/mixins/model-cloneable"], function (_exports, _emberData, _moment, _emberCpValidations, _modelCloneable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CAMPAIGN_AUDIENCE_TYPES = _exports.CAMPAIGN_SCHEDULE_TYPES = _exports.CAMPAIGN_TYPES = _exports.default = void 0;
  var SETTINGS_DEFAULT_VALUES_MAP = Object.freeze({
    name: null,
    locationId: null,
    aliasName: null,
    aliasEmail: null,
    subject: null,
    useAliasEmail: true,
    customerVisitCount: 2,
    customerLastVisitWithinDays: 90,
    customerNotVisitedWithinDays: 28,
    preferableTime: '10:00AM',
    surveyFrequencyDays: 0
  });
  var CAMPAIGN_TYPES = Object.freeze({
    SURVEY: 'Survey',
    PROMO: 'Promo',
    WELCOME: 'Welcome',
    INVITE: 'Invite'
  });
  _exports.CAMPAIGN_TYPES = CAMPAIGN_TYPES;
  var CAMPAIGN_SCHEDULE_TYPES = Object.freeze({
    RULE: 'RULE',
    TIME: 'TIME',
    MANUAL: 'MANUAL'
  });
  _exports.CAMPAIGN_SCHEDULE_TYPES = CAMPAIGN_SCHEDULE_TYPES;
  var CAMPAIGN_AUDIENCE_TYPES = Object.freeze({
    ALL: 'ALL',
    GROUPS: 'GROUPS'
  }); // eslint-disable-next-line require-jsdoc

  _exports.CAMPAIGN_AUDIENCE_TYPES = CAMPAIGN_AUDIENCE_TYPES;

  function computedTypeIsNotEqual(type) {
    if (Array.isArray(type)) {
      return Ember.computed('model.type', function getter() {
        return !type.includes(this.get('model.type'));
      });
    }

    return Ember.computed('model.type', function getter() {
      return this.get('model.type') !== type;
    });
  }

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Campaign Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 100
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    },
    locationId: {
      description: 'Location',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Location is not selected'
      })]
    },
    subject: {
      description: 'Subject',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 100
      }), (0, _emberCpValidations.validator)('presence', true)]
    },
    aliasName: {
      description: 'Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 100
      }), (0, _emberCpValidations.validator)('presence', true)]
    },
    aliasEmail: {
      description: 'Email',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.alias('model.useNoReplyEmail')
      }), (0, _emberCpValidations.validator)('format', {
        disabled: Ember.computed.alias('model.useNoReplyEmail'),
        type: 'email',
        message: 'You have entered invalid e-mail address. Please check and adjust it'
      })]
    },
    type: {
      description: 'Type',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: Object.values(CAMPAIGN_TYPES)
      })]
    },
    scheduleType: {
      description: 'Schedule Type',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: Object.values(CAMPAIGN_SCHEDULE_TYPES)
      })]
    },
    audience: {
      description: 'Audience Type',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: Object.values(CAMPAIGN_AUDIENCE_TYPES)
      })]
    },
    status: {
      description: 'Status',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['Scheduled', 'Draft', 'Inactive', 'Complete']
      })]
    },
    scheduleTime: {
      description: 'Post date',
      validators: [(0, _emberCpValidations.validator)(function (value) {
        return !value || (0, _moment.default)(value).isAfter((0, _moment.default)()) || 'Post date can not be before current time';
      }, {
        disabled: computedTypeIsNotEqual([CAMPAIGN_TYPES.PROMO, CAMPAIGN_TYPES.SURVEY])
      })]
    },
    surveyFrequencyDays: {
      description: 'Survey Sending Rule',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Sending Rule should be selected',
        disabled: computedTypeIsNotEqual(CAMPAIGN_TYPES.SURVEY)
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, _modelCloneable.default, {
    guid: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    aliasName: _emberData.default.attr('string'),
    aliasEmail: _emberData.default.attr('string'),
    useAliasEmail: _emberData.default.attr('boolean', {
      defaultValue: SETTINGS_DEFAULT_VALUES_MAP.useAliasEmail
    }),
    subject: _emberData.default.attr('string'),
    headerText: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    bodyText: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    imageUrl: _emberData.default.attr('string'),
    facebookUrl: _emberData.default.attr('string'),
    twitterUrl: _emberData.default.attr('string'),
    backgroundColor: _emberData.default.attr('string'),
    status: _emberData.default.attr('string', {
      defaultValue: 'Draft'
    }),
    type: _emberData.default.attr('string'),
    scheduleType: _emberData.default.attr('string'),
    audience: _emberData.default.attr('string', {
      defaultValue: CAMPAIGN_AUDIENCE_TYPES.ALL
    }),
    locationId: _emberData.default.attr('number'),
    scheduleTime: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return (0, _moment.default)().add(30, 'minutes').toDate();
      }
    }),
    scheduleRepeat: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    scheduleRepeatTz: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return _moment.default.tz.guess();
      }
    }),
    scheduleRepeatStart: _emberData.default.attr('date'),
    scheduleRepeatEnd: _emberData.default.attr('date'),
    // placeholder until customer groups are implement in frontend
    // if this was undefined it would delete groups on edit
    customerGroups: _emberData.default.attr(),
    manualCustomers: _emberData.default.hasMany('customer-engagement/customer'),
    customerVisitCount: _emberData.default.attr('number', {
      defaultValue: SETTINGS_DEFAULT_VALUES_MAP.customerVisitCount
    }),
    customerLastVisitWithinDays: _emberData.default.attr('number', {
      defaultValue: SETTINGS_DEFAULT_VALUES_MAP.customerLastVisitWithinDays
    }),
    customerNotVisitedWithinDays: _emberData.default.attr('number', {
      defaultValue: SETTINGS_DEFAULT_VALUES_MAP.customerNotVisitedWithinDays
    }),
    preferableTime: _emberData.default.attr('time', {
      defaultValue: SETTINGS_DEFAULT_VALUES_MAP.preferableTime,
      sourceFormat: 'HH:mm:ss'
    }),
    surveyFrequencyDays: _emberData.default.attr('number', {
      defaultValue: SETTINGS_DEFAULT_VALUES_MAP.surveyFrequencyDays
    }),
    totalClicks: _emberData.default.attr('number'),
    totalOpens: _emberData.default.attr('number'),
    totalSent: _emberData.default.attr('number'),
    totalUnsubscribes: _emberData.default.attr('number'),
    lastSent: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    useNoReplyEmail: Ember.computed.not('useAliasEmail'),
    isEditable: Ember.computed('status', 'totalSent', function getter() {
      return this.get('status') !== 'Complete' && !this.get('totalSent');
    }),
    hasChangedSettings: function hasChangedSettings() {
      var _this = this;

      return Object.keys(SETTINGS_DEFAULT_VALUES_MAP).some(function (key) {
        return _this.get(key) !== SETTINGS_DEFAULT_VALUES_MAP[key];
      });
    },
    resetSettings: function resetSettings() {
      var _this2 = this;

      Object.keys(SETTINGS_DEFAULT_VALUES_MAP).forEach(function (key) {
        _this2.set(key, SETTINGS_DEFAULT_VALUES_MAP[key]);
      });
    }
  });

  _exports.default = _default;
});