define("lh4/components/epro/timeclock-management/shift-form", ["exports", "lh4/enums/duration", "lh4/utils/date-moment", "moment"], function (_exports, _duration, _dateMoment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showErrors: true,
    headers: [{
      field: 'onClock',
      name: 'On Clock',
      width: 'five'
    }, {
      field: 'onBreak',
      name: 'On Break',
      width: 'five'
    }, {
      field: 'total',
      name: 'Total Hours',
      width: 'six'
    }],
    maxDate: Ember.computed(function () {
      return (0, _moment.default)();
    }),
    defaultStartTime: Ember.computed('preferences', function compute() {
      if (!this.preferences) {
        return null;
      }

      var eod = this.preferences.eod;

      var _Period$TODAY = _dateMoment.Period.TODAY({
        eod: eod
      }),
          startDate = _Period$TODAY.startDate;

      return startDate;
    }),
    classNames: ['shift-form'],
    employees: [],
    employeeOptions: Ember.computed('employees', function compute() {
      var filteredEmployees = this.employees.filter(function (_ref) {
        var isLaborTrackingEnabled = _ref.isLaborTrackingEnabled;
        return isLaborTrackingEnabled;
      });
      return filteredEmployees.map(function (_ref2) {
        var name = _ref2.name,
            guid = _ref2.guid,
            isActive = _ref2.isActive;
        return {
          name: name,
          value: guid,
          isActive: isActive
        };
      });
    }),
    originalEmployee: null,
    originalJob: null,
    allJobs: Ember.computed('changeset.employee', function compute() {
      var _selectedEmployee$get, _this$originalEmploye, _this$originalJob;

      var selectedEmployee = this.changeset.get('employee');
      var allJobs = (selectedEmployee === null || selectedEmployee === void 0 ? void 0 : (_selectedEmployee$get = selectedEmployee.get('jobs')) === null || _selectedEmployee$get === void 0 ? void 0 : _selectedEmployee$get.toArray()) || [];
      var isOriginalEmployee = ((_this$originalEmploye = this.originalEmployee) === null || _this$originalEmploye === void 0 ? void 0 : _this$originalEmploye.get('guid')) === (selectedEmployee === null || selectedEmployee === void 0 ? void 0 : selectedEmployee.get('guid'));
      var originalJob = (_this$originalJob = this.originalJob) === null || _this$originalJob === void 0 ? void 0 : _this$originalJob.content;

      if (isOriginalEmployee && originalJob && !allJobs.some(function (j) {
        return j.guid === originalJob.guid;
      })) {
        allJobs.push(originalJob);
      }

      return allJobs.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    }),
    jobOptions: Ember.computed('allJobs', function compute() {
      return this.allJobs.map(function (_ref3) {
        var name = _ref3.name,
            guid = _ref3.guid,
            isActive = _ref3.isActive;
        return {
          name: name,
          value: guid,
          isActive: isActive
        };
      });
    }),
    breaks: Ember.computed('changeset.segmentChangesets.[]', function compute() {
      return this.changeset.segmentChangesets.filter(function (segment) {
        return segment.get('isBreak');
      });
    }),
    totalSeconds: Ember.computed('changeset.clockedInAt', 'changeset.clockedOutAt', function compute() {
      var clockedInAt = this.changeset.get('clockedInAt');

      if (!clockedInAt) {
        return null;
      }

      var clockedOutAt = this.changeset.get('clockedOutAt') || (0, _moment.default)();
      return (0, _dateMoment.getSeconds)(clockedOutAt) - (0, _dateMoment.getSeconds)(clockedInAt);
    }),
    onBreakSeconds: Ember.computed('changeset.segmentChangesets.[]', 'changeset.segmentChangesets.@each.start', 'changeset.segmentChangesets.@each.end', function compute() {
      return this.breaks.reduce(function (total, val) {
        var start = val.get('start');

        if (!start) {
          return total;
        }

        var end = val.get('end') || (0, _moment.default)();
        var breakStart = (0, _dateMoment.getSeconds)((0, _moment.default)(start));
        var breakEnd = (0, _dateMoment.getSeconds)((0, _moment.default)(end));
        return total + (breakEnd - breakStart);
      }, 0);
    }),
    tableRow: Ember.computed('totalSeconds', 'onBreakSeconds', function compute() {
      var isValid = this.totalSeconds !== null;
      return [{
        isValid: isValid,
        onClock: this.formatTotalHours(this.totalSeconds - this.onBreakSeconds),
        onBreak: this.formatTotalHours(this.onBreakSeconds),
        total: this.formatTotalHours(this.totalSeconds)
      }];
    }),
    formatTotalHours: function formatTotalHours(seconds) {
      if (seconds > 0 && seconds < _duration.default.MINUTE.IN_SECONDS) {
        return '< 1 min';
      }

      var hours = Math.floor(Math.abs(seconds / _duration.default.HOUR.IN_SECONDS));
      var minutes = Math.floor(Math.abs(seconds % _duration.default.HOUR.IN_SECONDS / _duration.default.MINUTE.IN_SECONDS));
      var prefix = seconds < 0 && (hours || minutes) ? '-' : '';
      return "".concat(prefix).concat(hours ? "".concat(hours, " hr ") : '').concat(minutes ? "".concat(minutes, " min") : '');
    },
    validateShiftDateTimeInputs: function validateShiftDateTimeInputs() {
      this.changeset.validate('clockedInAt');
      this.changeset.validate('clockedOutAt');
      this.changeset.validate('segmentChangesets');
    },
    actions: {
      onTimeChange: function onTimeChange(record, property, date) {
        record.set(property, date ? date.toISOString() : null);
        this.validateShiftDateTimeInputs();
      },
      addBreak: function addBreak() {
        var segment = this.changeset.data.addSegment({
          isBreak: true,
          isPaid: this.settings.get('accounting.breaksPaid') === 'Always Paid',
          timeClockShift: this.changeset
        });
        var segmentChangeset = this.buildChildChangeset(segment);
        this.changeset.segmentChangesets.pushObject(segmentChangeset);
        this.validateShiftDateTimeInputs();
      },
      deleteBreak: function deleteBreak(record) {
        this.changeset.segmentChangesets.removeObject(record);
        this.validateShiftDateTimeInputs();
      },
      onChange: function onChange(value, property) {
        switch (property) {
          case 'jobGuid':
            {
              var _selectedEmployee$get2;

              var selectedJob = this.allJobs.find(function (job) {
                return job.guid === value;
              });
              var selectedEmployee = this.changeset.get('employee');
              this.changeset.set('jobGuid', value);
              this.changeset.set('job', selectedJob);
              this.changeset.set('payRate', ((_selectedEmployee$get2 = selectedEmployee.get('payRates').find(function (payRateObj) {
                return payRateObj.locationId === selectedEmployee.get('locationId') && payRateObj.jobGuid === value && payRateObj.employeeGuid === selectedEmployee.get('guid');
              })) === null || _selectedEmployee$get2 === void 0 ? void 0 : _selectedEmployee$get2.payRate) || selectedJob.payRate);
              this.changeset.set('normalHourlyPayRate', selectedJob.payRate);
              break;
            }

          case 'employeeGuid':
            {
              this.changeset.set('employeeGuid', value);
              this.changeset.set('employee', this.employees.find(function (employee) {
                return employee.guid === value;
              }));
              this.changeset.set('jobGuid', null);
              this.changeset.set('job', null);
              break;
            }

          default:
            break;
        }
      }
    }
  });

  _exports.default = _default;
});