define("lh4/templates/components/ui/reports/revenue-center-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d0n33StZ",
    "block": "{\"symbols\":[\"revenueCenterOption\",\"@revenueCenterSelected\",\"@onRevenueCenterChange\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"report-grid-item\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"Select a Revenue Center\"],[9],[0,\"\\n\\t\\t\"],[5,\"ui-dropdown\",[[12,\"class\",\"fluid search selection report-select\"]],[[\"@forceSelection\",\"@fullTextSearch\",\"@selected\",\"@onChange\"],[false,\"exact\",[23,2,[]],[23,3,[]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"default text\"],[8],[0,\"Select Revenue Center\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"revenueCenterOptions\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"item\"],[11,\"data-value\",[29,[[23,1,[\"value\"]]]]],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[23,1,[\"name\"]],false],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/reports/revenue-center-select.hbs"
    }
  });

  _exports.default = _default;
});