define("lh4/templates/authenticated/device-management/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b4OTtRuL",
    "block": "{\"symbols\":[\"tray\",\"@errorMessage\",\"@model\"],\"statements\":[[5,\"ui/tray/sliding-tray\",[],[[\"@onClose\",\"@errorMessage\",\"@shouldClose\",\"@onBeforeClose\",\"@title\"],[[28,\"action\",[[23,0,[]],\"close\"],null],[23,2,[]],[23,0,[\"shouldCloseTray\"]],[23,0,[\"onBeforeClose\"]],\"Edit Device\"]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"device-management/device-management-form\",[],[[\"@changeset\",\"@showErrors\",\"@revenueCenterOptions\",\"@disabled\"],[[23,0,[\"changeset\"]],[23,0,[\"showErrors\"]],[23,3,[\"revenueCenterOptions\"]],[23,0,[\"editingDisabled\"]]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[6,[23,1,[\"actions\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui primary button\",[28,\"if\",[[24,[\"isSaving\"]],\" loading\"],null],\" \",[28,\"if\",[[24,[\"editingDisabled\"]],\" disabled\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"update\"]],[8],[0,\"\\n\\t\\t\\tSave\\n\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/device-management/edit.hbs"
    }
  });

  _exports.default = _default;
});