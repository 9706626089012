define("lh4/components/epro/menu/dynamic-pricing-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid', 'dynamic-pricing-form'],
    showErrors: false
  });

  _exports.default = _default;
});