define("lh4/controllers/authenticated/settings/users/edit-user", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessagesHelper: Ember.inject.service(),
    modelName: 'model.user',
    loading: false,
    actions: {
      save: function save(permissionSets, normalizedPermissionSets) {
        var _this = this;

        this.set('loading', true);

        if (!this.modelHasChanges(permissionSets) && !this.changeset.isDirty) {
          // Wrapped in ENV condition to prevent failing tests
          if (_environment.default.environment !== 'test') {
            Ember.run.later(function () {
              _this.transitionToRoute('authenticated.settings.users');

              _this.set('loading', false);
            }, 2000);
          }

          return false;
        }

        return this.changeset.validate().then(function () {
          if (!_this.changeset.isValid) {
            _this.set('loading', false);

            return Ember.RSVP.reject(_this.changeset.errors);
          }

          return _this.changeset;
        }).then(function (changeset) {
          _this.set('changeset.permissionSets', permissionSets);

          changeset.save({
            adapterOptions: {
              normalizedPermissionSets: normalizedPermissionSets
            }
          }).then(function () {
            _this.flashMessagesHelper.pushMessage('User profile saved', 'success'); // Wrapped in ENV condition to prevent failing tests


            if (_environment.default.environment !== 'test') {
              Ember.run.later(function () {
                _this.transitionToRoute('authenticated.settings.users');
              }, 2000);
            }
          }).catch(function (_ref) {
            var errors = _ref.errors;

            if (errors && errors[0]) {
              var userEditError = 'Something went wrong, user could not be edited.';
              errors.forEach(function (error) {
                var err = error.details && error.details[0] || error.message || userEditError;

                _this.flashMessagesHelper.pushMessage(err);
              });
            }

            return _this.model.user.rollback();
          }).finally(function () {
            return _this.set('loading', false);
          });
        }).catch(function (validations) {
          if (validations.length) {
            validations.forEach(function (v) {
              _this.flashMessagesHelper.pushMessage(v.validation, 'error');
            });
          }
        });
      },
      modalDeny: function modalDeny() {
        this.transitionToRoute('authenticated.settings.users');
      }
    },
    // Condition to check if record attributes, locations array, or permissions array has changed.
    modelHasChanges: function modelHasChanges(permissionSets) {
      var userPermissionSets = this.changeset.get('permissionSets');

      if (userPermissionSets.length !== permissionSets.length) {
        return true;
      }

      return userPermissionSets.some(function (permSetA) {
        var permSetB = permissionSets.find(function (permSet) {
          return permSet.locationId === permSetA.locationId;
        });

        if (!permSetB || permSetA.permissions.length !== permSetB.permissions.length) {
          return true;
        }

        return permSetA.permissions.some(function (permId) {
          return !permSetB.permissions.includes(permId);
        }) || permSetB.permissions.some(function (permId) {
          return !permSetA.permissions.includes(permId);
        });
      });
    }
  });

  _exports.default = _default;
});