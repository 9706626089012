define("lh4/components/epro/menu/item-bulk-form", ["exports", "lh4/mixins/components/ui/color-picker/color-picker-mapping"], function (_exports, _colorPickerMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_colorPickerMapping.default, {
    store: Ember.inject.service(),
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    errorMessage: null,
    departmentOptions: Ember.computed.map('departments', function (_ref) {
      var name = _ref.name,
          value = _ref.guid,
          isActive = _ref.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    revenueClassOptions: Ember.computed.map('revenueClasses', function (_ref2) {
      var name = _ref2.name,
          value = _ref2.guid,
          isActive = _ref2.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    itemTagOptions: Ember.computed.map('itemTags', function (_ref3) {
      var name = _ref3.name,
          value = _ref3.guid,
          isActive = _ref3.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    taxOptions: Ember.computed.map('taxes', function (_ref4) {
      var name = _ref4.name,
          value = _ref4.guid,
          isActive = _ref4.active;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    printerOptions: Ember.computed.map('printers', function (_ref5) {
      var name = _ref5.name,
          value = _ref5.guid,
          isActive = _ref5.active;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setColorsFromStylesheet();
    },
    actions: {
      handleFieldSelectorGroupChange: function handleFieldSelectorGroupChange(field, included) {
        this.onFieldSelectorGroupChange(field, included);
      },
      handleAdvancedChange: function handleAdvancedChange(selected) {
        this.changeset.set('advanced', selected);
        this.changeset.validate('isOpenItem');
      },
      toggleWeighted: function toggleWeighted() {
        this.changeset.set('isWeighted', !this.changeset.get('isWeighted'));
        this.changeset.validate('tareWeight');
      },
      toggleTrackItems: function toggleTrackItems() {
        this.changeset.set('isDisplayedOnItemTracking', !this.changeset.get('isDisplayedOnItemTracking'));
        this.changeset.validate('qtyOnHand');
      },
      toggleLowStockIndicator: function toggleLowStockIndicator() {
        this.changeset.set('showQtyAlert', !this.changeset.get('showQtyAlert'));
        this.changeset.validate('minQty');
      }
    }
  });

  _exports.default = _default;
});