define("lh4/constants/launch-control/constants", ["exports", "lh4/constants/launch-control/employees", "lh4/constants/launch-control/loyalty", "lh4/constants/launch-control/reservations"], function (_exports, _employees, _loyalty, _reservations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BLOCKED_ACCOUNT_STATUSES = _exports.APPROVED_ACCOUNT_STATUSES = _exports.PENDING_ACCOUNT_STATUSES = _exports.CONFIRMATION_MODAL_TEXT = _exports.ONLINE_ORDERING_DELIVERY_RANGE_TYPE = _exports.TITLE_TO_MODULE_MAPPING = _exports.PHASE_3_MODULES = _exports.PHASE_2_MODULES = _exports.PHASE_1_MODULES = _exports.PROGRESS_CIRCUMFERENCE = _exports.MENU_SUPPORTED_FILE_TYPES = _exports.MAX_MENU_FILE_SIZE = _exports.ERRORS = _exports.WORKFLOW_CONFIGURATIONS = _exports.WEBSITE_MODULE = _exports.EMPLOYEES_MODULE = _exports.ADVANCED_ONLINE_ORDERING_MODULE = _exports.FLOOR_PLAN_MODULE = _exports.SKYTAB_BI_MODULE = _exports.ADVANTAGE_PROGRAM_MODULE = _exports.RESERVATIONS_MODULE = _exports.LOYALTY_MODULE = _exports.GIFT_CARDS_MODULE = _exports.INTEGRATIONS_MODULE = _exports.ONLINE_ORDERING_MODULE = _exports.RESTAURANTS_BASICS_MODULE = _exports.MENU_UPLOAD_MODULE = _exports.INSTALLATION_DETAILS_MODULE = _exports.DEFAULT_WORKFLOW = void 0;
  var DEFAULT_WORKFLOW = 'launch-control-v1';
  _exports.DEFAULT_WORKFLOW = DEFAULT_WORKFLOW;
  var INSTALLATION_DETAILS_MODULE = Object.freeze({
    name: 'mod-installation-details-v1',
    stepNames: ['contacts', 'shipping-address', 'setup-date']
  });
  _exports.INSTALLATION_DETAILS_MODULE = INSTALLATION_DETAILS_MODULE;
  var MENU_UPLOAD_MODULE = Object.freeze({
    name: 'mod-menu-v1',
    stepNames: ['upload', 'taxes']
  });
  _exports.MENU_UPLOAD_MODULE = MENU_UPLOAD_MODULE;
  var RESTAURANTS_BASICS_MODULE = Object.freeze({
    name: 'mod-restaurant-basics-v1',
    stepNames: ['location-details', 'logo-upload']
  });
  _exports.RESTAURANTS_BASICS_MODULE = RESTAURANTS_BASICS_MODULE;
  var ONLINE_ORDERING_MODULE = Object.freeze({
    name: 'mod-online-ordering-v1',
    stepNames: ['lets-start', 'order-types', 'order-type-settings', 'delivery-range', 'delivery-area', 'delivery-settings', 'logo-upload']
  });
  _exports.ONLINE_ORDERING_MODULE = ONLINE_ORDERING_MODULE;
  var INTEGRATIONS_MODULE = Object.freeze({
    name: 'mod-integrations-v1',
    stepNames: ['install']
  });
  _exports.INTEGRATIONS_MODULE = INTEGRATIONS_MODULE;
  var GIFT_CARDS_MODULE = Object.freeze({
    name: 'mod-gift-cards-v1',
    stepNames: []
  });
  _exports.GIFT_CARDS_MODULE = GIFT_CARDS_MODULE;
  var LOYALTY_MODULE = Object.freeze({
    name: _loyalty.LOYALTY_MODULE_NAME,
    stepNames: [_loyalty.default.PROGRAMS, _loyalty.default.DISCOUNTS, _loyalty.default.PROMOTIONS]
  });
  _exports.LOYALTY_MODULE = LOYALTY_MODULE;
  var RESERVATIONS_MODULE = Object.freeze({
    name: _reservations.RESERVATIONS_MODULE_NAME,
    stepNames: [_reservations.default.BLOCKS, _reservations.default.SETTINGS]
  });
  _exports.RESERVATIONS_MODULE = RESERVATIONS_MODULE;
  var ADVANTAGE_PROGRAM_MODULE = Object.freeze({
    name: 'mod-advantage-program-v1',
    stepNames: []
  });
  _exports.ADVANTAGE_PROGRAM_MODULE = ADVANTAGE_PROGRAM_MODULE;
  var SKYTAB_BI_MODULE = Object.freeze({
    name: 'mod-skytab-bi-v1',
    stepNames: ['submit']
  });
  _exports.SKYTAB_BI_MODULE = SKYTAB_BI_MODULE;
  var FLOOR_PLAN_MODULE = Object.freeze({
    name: 'mod-table-layout-v1',
    stepNames: ['table-layout']
  });
  _exports.FLOOR_PLAN_MODULE = FLOOR_PLAN_MODULE;
  var ADVANCED_ONLINE_ORDERING_MODULE = Object.freeze({
    name: 'mod-advanced-online-ordering-v1',
    stepNames: ['info']
  });
  _exports.ADVANCED_ONLINE_ORDERING_MODULE = ADVANCED_ONLINE_ORDERING_MODULE;
  var EMPLOYEES_MODULE = Object.freeze({
    name: _employees.EMPLOYEES_MODULE_NAME,
    stepNames: [_employees.EMPLOYEES_STEP_NAMES.JOBS, _employees.EMPLOYEES_STEP_NAMES.EMPLOYEES]
  });
  _exports.EMPLOYEES_MODULE = EMPLOYEES_MODULE;
  var WEBSITE_MODULE = Object.freeze({
    name: 'mod-website-v1',
    stepNames: ['submit']
  });
  _exports.WEBSITE_MODULE = WEBSITE_MODULE;
  var WORKFLOW_CONFIGURATIONS = Object.freeze({
    'launch-control-v1': {
      modules: [INSTALLATION_DETAILS_MODULE, MENU_UPLOAD_MODULE, RESTAURANTS_BASICS_MODULE, ONLINE_ORDERING_MODULE, INTEGRATIONS_MODULE, GIFT_CARDS_MODULE, LOYALTY_MODULE, RESERVATIONS_MODULE, ADVANTAGE_PROGRAM_MODULE, SKYTAB_BI_MODULE, WEBSITE_MODULE, ADVANCED_ONLINE_ORDERING_MODULE, FLOOR_PLAN_MODULE]
    }
  });
  _exports.WORKFLOW_CONFIGURATIONS = WORKFLOW_CONFIGURATIONS;
  var ERRORS = Object.freeze({
    serverError: 'An error occured on our side, please try again later.'
  });
  _exports.ERRORS = ERRORS;
  var MAX_MENU_FILE_SIZE = '10 MB';
  _exports.MAX_MENU_FILE_SIZE = MAX_MENU_FILE_SIZE;
  var MENU_SUPPORTED_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword'];
  _exports.MENU_SUPPORTED_FILE_TYPES = MENU_SUPPORTED_FILE_TYPES;
  var PROGRESS_CIRCUMFERENCE = 439.6;
  _exports.PROGRESS_CIRCUMFERENCE = PROGRESS_CIRCUMFERENCE;
  var PHASE_1_MODULES = [INSTALLATION_DETAILS_MODULE.name, MENU_UPLOAD_MODULE.name, RESTAURANTS_BASICS_MODULE.name];
  _exports.PHASE_1_MODULES = PHASE_1_MODULES;
  var PHASE_2_MODULES = [ONLINE_ORDERING_MODULE.name, INTEGRATIONS_MODULE.name, GIFT_CARDS_MODULE.name, LOYALTY_MODULE.name, SKYTAB_BI_MODULE.name, FLOOR_PLAN_MODULE.name, RESERVATIONS_MODULE.name];
  _exports.PHASE_2_MODULES = PHASE_2_MODULES;
  var PHASE_3_MODULES = [ADVANCED_ONLINE_ORDERING_MODULE.name, WEBSITE_MODULE.name];
  _exports.PHASE_3_MODULES = PHASE_3_MODULES;
  var TITLE_TO_MODULE_MAPPING = {
    'Installation Details': 'mod-installation-details-v1',
    'Upload your menu': 'mod-menu-v1',
    'Restaurant Basics': 'mod-restaurant-basics-v1',
    'Online Ordering': 'mod-online-ordering-v1',
    Integrations: 'mod-integrations-v1',
    'Gift Cards': 'mod-gift-cards-v1',
    Loyalty: _loyalty.LOYALTY_MODULE_NAME,
    Reservations: _reservations.RESERVATIONS_MODULE_NAME,
    'Advantage program': 'mod-advantage-program-v1',
    'Business Intelligence (BI)': 'mod-skytab-bi-v1',
    Website: 'mod-website-v1',
    'Floor Plan': 'mod-table-layout-v1',
    Employees: _employees.EMPLOYEES_MODULE_NAME,
    'Finish Menu Setup': 'mod-advanced-online-ordering-v1'
  };
  _exports.TITLE_TO_MODULE_MAPPING = TITLE_TO_MODULE_MAPPING;
  var ONLINE_ORDERING_DELIVERY_RANGE_TYPE = {
    Distance: 'distance',
    Area: 'area'
  };
  _exports.ONLINE_ORDERING_DELIVERY_RANGE_TYPE = ONLINE_ORDERING_DELIVERY_RANGE_TYPE;
  var CONFIRMATION_MODAL_TEXT = Object.freeze({
    save: 'SAVE',
    dontSave: 'DON\'T SAVE',
    exit: 'EXIT',
    cancel: 'CANCEL',
    saveSetupText: 'Do you want to save changes?',
    exitSetupText: 'Do you want to exit?',
    saveTitle: 'Save Changes',
    exitTitle: 'Exit Setup'
  }); // https://shift4.atlassian.net/wiki/spaces/IS/pages/17373089/Merchant+Account+Statuses

  _exports.CONFIRMATION_MODAL_TEXT = CONFIRMATION_MODAL_TEXT;
  var PENDING_ACCOUNT_STATUSES = Object.freeze([100, 125, 375, 390, 400, 425, 500, 550]);
  _exports.PENDING_ACCOUNT_STATUSES = PENDING_ACCOUNT_STATUSES;
  var APPROVED_ACCOUNT_STATUSES = Object.freeze([330, 600, 650, 675, 700, 730, 785]);
  _exports.APPROVED_ACCOUNT_STATUSES = APPROVED_ACCOUNT_STATUSES;
  var BLOCKED_ACCOUNT_STATUSES = Object.freeze([200, 300, 325]);
  _exports.BLOCKED_ACCOUNT_STATUSES = BLOCKED_ACCOUNT_STATUSES;
});