define("lh4/templates/components/statements-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3chC4j6l",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui dropdown\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"month\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[1,[22,\"month\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"default text\"],[8],[0,\"Select a Time Period\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/statements-picker.hbs"
    }
  });

  _exports.default = _default;
});