define("lh4/components/skytab-website", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    skytabWebsiteIframeError: null,
    skytabWebsiteIframeData: {},
    skytabWebsiteInstalled: false,
    shift4ShopEnv: _environment.default.s4shopEnvironment,
    actions: {
      initializeIframe: function initializeIframe() {
        if (!this.skytabWebsiteIframeData) {
          return;
        }

        var _this$skytabWebsiteIf = this.skytabWebsiteIframeData,
            _this$skytabWebsiteIf2 = _this$skytabWebsiteIf.merchant,
            merchant = _this$skytabWebsiteIf2 === void 0 ? undefined : _this$skytabWebsiteIf2,
            _this$skytabWebsiteIf3 = _this$skytabWebsiteIf.accessToken,
            accessToken = _this$skytabWebsiteIf3 === void 0 ? '' : _this$skytabWebsiteIf3;

        if (window.TSModule && merchant && accessToken) {
          window.TSModule.Initialize(merchant, accessToken, this.shift4ShopEnv, 'skytabwebsite');
        }
      }
    }
  });

  _exports.default = _default;
});