define("lh4/templates/components/internet-payments/ui/checkbox/toggle-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N0CvBNF4",
    "block": "{\"symbols\":[\"@className\",\"@label\",\"@checked\"],\"statements\":[[5,\"ui-checkbox\",[[12,\"class\",[29,[\"internet-payments-toggle toggle checkbox \",[23,1,[]]]]]],[[\"@label\",\"@checked\",\"@onChange\"],[[23,2,[]],[23,3,[]],[28,\"action\",[[23,0,[]],[23,0,[\"onChange\"]]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/checkbox/toggle-switch.hbs"
    }
  });

  _exports.default = _default;
});