define("lh4/adapters/echo-pro/dynamic-price", ["exports", "lh4/adapters/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    urlForQuery: function urlForQuery() {
      return "".concat(this.host, "/").concat(this.namespace, "/echo-pro/availability-price/").concat(this.location.model.id, "/dynamic-pricing");
    },
    urlForFindRecord: function urlForFindRecord(id) {
      return "".concat(this.host, "/").concat(this.namespace, "/echo-pro/availability-price/").concat(this.location.model.id, "/dynamic-pricing/").concat(id);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return "".concat(this.host, "/").concat(this.namespace, "/echo-pro/availability-price/").concat(this.location.model.id, "/dynamic-pricing/").concat(id);
    },
    urlForDeleteRecord: function urlForDeleteRecord(id) {
      return "".concat(this.host, "/").concat(this.namespace, "/echo-pro/availability-price/").concat(this.location.model.id, "/dynamic-pricing/").concat(id);
    },
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(this.host, "/").concat(this.namespace, "/echo-pro/availability-price/").concat(this.location.model.id, "/dynamic-pricing");
    }
  });

  _exports.default = _default;
});