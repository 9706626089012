define("lh4/templates/authenticated/kitchen-display-system/preparable-items/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZJ3lXBb1",
    "block": "{\"symbols\":[\"Modal\",\"@model\"],\"statements\":[[5,\"ui/modal/fullscreen-modal\",[],[[\"@title\",\"@errorMessage\",\"@onClose\"],[\"New Ingredient\",[23,0,[\"errorMessage\"]],[28,\"action\",[[23,0,[]],\"close\"],null]]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"add-on-devices/kds-form\",[],[[\"@changeset\",\"@showErrors\",\"@items\",\"@initialItems\",\"@initialModifiers\",\"@modifiers\",\"@buildChildChangeset\"],[[23,0,[\"changeset\"]],[23,0,[\"showErrors\"]],[23,2,[\"items\"]],[23,0,[\"initialItems\"]],[23,0,[\"initialModifiers\"]],[23,2,[\"modifiers\"]],[28,\"action\",[[23,0,[]],\"onBuildChildChangeset\"],null]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[6,[23,1,[\"actions\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui primary button\",[28,\"if\",[[24,[\"isSaving\"]],\" loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"create\"]],[8],[0,\"\\n\\t\\t\\tSave\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui primary basic button\",[28,\"if\",[[24,[\"isSaving\"]],\" loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"createAndDuplicate\"]],[8],[0,\"\\n\\t\\t\\tSave & Duplicate\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui primary basic button left-action\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n\\t\\t\\tDiscard\\n\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/kitchen-display-system/preparable-items/create.hbs"
    }
  });

  _exports.default = _default;
});