define("lh4/serializers/echo-pro/availability-price/configure-pricing", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    primaryKey: 'id',
    normalize: function normalize(modelClass, resourceHashToNormalize) {
      var resourceHash = resourceHashToNormalize;
      resourceHash.id = "".concat(resourceHash.scheduleTitleGuid, "-schedule").concat(resourceHash.scheduleNumber);

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, resourceHash].concat(args));
    }
  });

  _exports.default = _default;
});